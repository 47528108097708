import { Breakpoint, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Shorthand hook for `useMediaQuery(theme.breakpoints.down(key));` if the `theme` is not required.
 *
 * Defaults the `key` to 'md'.
 *
 * Example usage:
 * ```ts
 * const mediumScreen = useBreakpointDown();
 * ```
 */
export default function useBreakpointDown(key: number | Breakpoint = 'md'): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
}
