import TitleIcon from '@mui/icons-material/Title';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { SuccessMessages } from 'src/constants/messages';
import { useUpdateQuotationReferenceAndDescriptionMutation } from 'src/graphql/generated/hooks';
import { GetQuotationQuery } from 'src/graphql/generated/operations';
import { InputUpdateQuotationReferenceDescription } from 'src/graphql/generated/schema';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';
import GenerateInput from 'src/pages/BackOffice/components/GenerateInput/GenerateInput';

interface Props {
  quotation: GetQuotationQuery['quotation'];
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const QuotationEditReferenceDialog = ({ open, setOpen, quotation }: Props): JSX.Element => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<InputUpdateQuotationReferenceDescription>({ mode: 'onChange' });

  const [updateQuotationReferenceAndDescription, { loading }] = useUpdateQuotationReferenceAndDescriptionMutation({
    update(_, { errors }) {
      if (errors) return toast.error(errors[0].message);
      toast.success(SuccessMessages.QuotationUpdated);
      setOpen(false);
    },
  });

  const handleSave = useCallback(
    ({ reference, description }: InputUpdateQuotationReferenceDescription) => {
      if (!quotation.id) return;
      updateQuotationReferenceAndDescription({
        variables: {
          input: {
            quotationId: quotation.id,
            reference,
            description,
          },
        },
      });
    },
    [quotation.id, updateQuotationReferenceAndDescription],
  );

  return (
    <form id="quotation-edit-reference" onSubmit={handleSubmit(handleSave)}>
      <Dialog fullScreen={mediumScreen} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <CardTitle color="primary" icon={<TitleIcon color="primary" />} title="Referentie en beschrijving" />
        </DialogTitle>

        <DialogContent>
          <GenerateInput
            control={control}
            defaultValue={quotation.reference ?? ''}
            disabled={!quotation.id}
            errorField={errors.reference}
            fieldName="reference"
            helperText="Vul de offerte referentie in"
            inputProps={{ maxLength: '150' }}
            label="Referentie"
            variant="outlined"
          />

          <Controller
            control={control}
            defaultValue={quotation.description ?? ''}
            name="description"
            render={({ field }) => (
              <TextField
                helperText="Maximaal 180 tekens toegestaan"
                inputProps={{ maxLength: '180' }}
                label="Beschrijving"
                multiline
                rows={12}
                sx={{ mt: 2, width: '100%' }}
                {...field}
              />
            )}
            rules={{ maxLength: 180 }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <DefaultButton disabled={loading} id="cancel" sx={{ flexGrow: 1 }} onClick={() => setOpen(false)}>
            Annuleren
          </DefaultButton>
          <LoadingButton
            color="primary"
            disabled={!isValid}
            form="quotation-edit-reference"
            loading={loading}
            sx={{ flexGrow: 1 }}
            type="submit"
            variant="contained"
          >
            Opslaan
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default QuotationEditReferenceDialog;
