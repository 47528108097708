import ClearIcon from '@mui/icons-material/Clear';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { QuotationRoomFieldsFragment } from 'src/graphql/generated/operations';
import useBreakpointDown from 'src/hooks/useBreakpointDown.hook';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
interface Props {
  roomImgDialog: string | undefined;
  setRoomImgDialog: Dispatch<SetStateAction<string | undefined>>;
  rooms?: QuotationRoomFieldsFragment[];
}

const RoomImgDialog = ({ roomImgDialog, setRoomImgDialog, rooms }: Props): JSX.Element => {
  const mediumScreen = useBreakpointDown();

  const room = useMemo(() => rooms?.find((room) => room.id === roomImgDialog), [roomImgDialog, rooms]);

  const handleClose = useCallback(() => setRoomImgDialog(undefined), [setRoomImgDialog]);

  return (
    <Dialog fullScreen={mediumScreen} fullWidth maxWidth="lg" open={roomImgDialog !== undefined} onClose={handleClose}>
      <DialogTitle>
        <CardTitle color="primary" title={`Afbeelding ${room?.name}`}>
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </CardTitle>
      </DialogTitle>
      <DialogContent>
        {room?.imageUrl && (
          <Box alt={room.name} component="img" src={room.imageUrl} sx={{ width: '100%', height: 'auto' }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RoomImgDialog;
