import { Box, SvgIconProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  icon?: React.ReactElement<SvgIconProps>;
  color: string;
  title: string;
};

const CardTitle = ({ children, icon, color, title }: Props): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: (theme) => theme.spacing(4),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon}
        <Typography color={color} sx={{ ml: icon ? 0.5 : 0 }} variant="h4">
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default CardTitle;
