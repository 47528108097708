import { Typography } from '@mui/material';
import { add, intlFormat, parseISO } from 'date-fns';
import { useCallback } from 'react';

import { GetQuotationQuery } from 'src/graphql/generated/operations';

interface Props {
  quotationData: GetQuotationQuery;
}

function formatDate(date: Date) {
  return intlFormat(
    date,
    {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    },
    {
      locale: 'nl-NL',
    },
  );
}

const QuotationDate = ({ quotationData }: Props): JSX.Element => {
  const quotationExpiryDate = useCallback(() => {
    if (!quotationData.quotation.finishedAt) return;
    return formatDate(
      add(parseISO(quotationData.quotation.finishedAt), {
        days: quotationData.quotation.expiresInDays,
      }),
    );
  }, [quotationData.quotation]);

  return (
    <>
      <Typography color="text.secondary" variant="subtitle2">
        Verzenddatum:
      </Typography>
      {quotationData.quotation.finishedAt ? (
        <Typography color="text.secondary" variant="subtitle2">
          {formatDate(parseISO(quotationData.quotation.finishedAt))}
        </Typography>
      ) : (
        <Typography color="text.disabled" sx={{ fontStyle: 'italic' }} variant="subtitle2">
          Offerte nog niet verzonden
        </Typography>
      )}
      <Typography color="text.secondary" mt={2} variant="subtitle2">
        Geldig tot:
      </Typography>
      {quotationData.quotation.finishedAt ? (
        <Typography color="text.secondary" variant="subtitle2">
          {quotationExpiryDate()}
        </Typography>
      ) : (
        <Typography color="text.disabled" sx={{ fontStyle: 'italic' }} variant="subtitle2">
          {quotationData.quotation.expiresInDays} dagen na verzenddatum
        </Typography>
      )}
    </>
  );
};

export default QuotationDate;
