import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';

import { GetQuotationDocument, useDeletedQuotationLineItemMutation } from 'src/graphql/generated/hooks';
import useBreakpointDown from 'src/hooks/useBreakpointDown.hook';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';

interface Props {
  handleClose: () => unknown;
  quotationId: string;
  lineItemId?: string;
  open: boolean;
}

const DeleteLineItemDialog = ({ handleClose, lineItemId, open, quotationId }: Props): JSX.Element => {
  const mediumScreen = useBreakpointDown();

  const [deletedQuotationLineItem, { loading, error }] = useDeletedQuotationLineItemMutation({
    refetchQueries: [{ query: GetQuotationDocument, variables: { input: { quotationId } } }],
    update(cache, { data }) {
      if (!data) return;
      cache.evict({ id: `QuotationLineItem:${data.deleteQuotationLineItem.deletedQuotationLineItemId}` });
      handleClose();
    },
  });

  const handleDelete = useCallback(async () => {
    if (lineItemId === undefined) return;
    deletedQuotationLineItem({ variables: { input: { quotationLineItemId: lineItemId } } });
  }, [deletedQuotationLineItem, lineItemId]);

  useEffect(() => {
    if (!error) return;
    toast.error(error.message);
  }, [error]);

  return (
    <Dialog fullScreen={mediumScreen} open={open} onClose={handleClose}>
      <DialogTitle>
        <CardTitle color="primary" title="Line item verwijderen" />
      </DialogTitle>
      <DialogContent>
        Weet je zeker dat je deze regel permanent wil verwijderen? Dit kan niet ongedaan worden gemaakt.
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <DefaultButton disabled={loading} id="cancel" sx={{ flexGrow: 1 }} onClick={handleClose}>
          Annuleren
        </DefaultButton>
        <LoadingButton
          color="primary"
          loading={loading}
          sx={{ flexGrow: 1 }}
          variant="contained"
          onClick={handleDelete}
        >
          Verwijderen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteLineItemDialog;
