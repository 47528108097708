import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Typography } from '@mui/material';
import { useCallback } from 'react';

interface Props {
  step: number;
  title: string;
  isExpanded: boolean;
  /** Provide toggle function to show the toggle button */
  toggle?: (step: number) => void;
  description?: string;
}

/**
 * Heading of a ProductFilterStep with expand button
 */
export default function StepHeading({ step, title, isExpanded, toggle, description }: Props): JSX.Element {
  const handleToggle = useCallback(() => toggle && toggle(step), [step, toggle]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={700} sx={{ pt: 1, pb: 1 }} variant="body2">
          {step + 1}. {title}
        </Typography>
        {toggle && (
          <Button
            color="inherit"
            endIcon={
              <KeyboardArrowDownIcon
                sx={{ transform: isExpanded ? 'rotate(180deg)' : 'none', transition: 'transform 200ms' }}
              />
            }
            size="small"
            sx={{ justifyContent: 'end', minWidth: 125, color: isExpanded ? 'text.primary' : 'text.secondary' }}
            onClick={handleToggle}
          >
            {isExpanded ? 'Verberg' : 'Bekijk'} opties
          </Button>
        )}
      </Box>
      {description && isExpanded && (
        <Box sx={{ display: 'flex', pt: 2, px: 1 }}>
          <InfoOutlinedIcon color="primary" sx={{ mt: 1 }} />
          <Typography align="left" pl={2} variant="subtitle2">
            {description}
          </Typography>
        </Box>
      )}
    </>
  );
}
