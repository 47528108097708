import { Skeleton } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';

import { GetContactQuery } from 'src/graphql/generated/operations';
import GenerateInput from 'src/pages/BackOffice/components/GenerateInput/GenerateInput';

interface Props {
  isEditable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  contactData?: GetContactQuery | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FieldErrors<any>;
  contactId?: string;
}

export default function ContactBaseFormFields({
  contactData,
  errors,
  isEditable,
  control,
  contactId,
}: Props): JSX.Element {
  if (!contactData?.contact && contactId) {
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );
  }

  return (
    <>
      <GenerateInput
        control={control}
        defaultValue={contactData?.contact.jobTitle ?? ''}
        disabled={!isEditable && !!contactId}
        errorField={errors.jobTitle}
        fieldName="jobTitle"
        helperText="Vul een Functietitel in"
        label="Functietitel"
        variant="standard"
      />
      <GenerateInput
        control={control}
        defaultValue={contactData?.contact.firstName ?? ''}
        disabled={!isEditable && !!contactId}
        errorField={errors.firstName}
        fieldName="firstName"
        helperText="Vul een voornaam in"
        label="Voornaam"
        variant="standard"
      />

      <GenerateInput
        control={control}
        defaultValue={contactData?.contact.lastName ?? ''}
        disabled={!isEditable && !!contactId}
        errorField={errors.lastName}
        fieldName="lastName"
        helperText="Vul een achternaam in"
        label="Achternaam"
        variant="standard"
      />
      <GenerateInput
        control={control}
        defaultValue={contactData?.contact.quotationEmail ?? ''}
        disabled={!isEditable && !!contactId}
        errorField={errors.quotationEmail}
        fieldName="quotationEmail"
        helperText="Vul een geldig emailadres in"
        label="Emailadres"
        type="email"
        variant="standard"
      />
      <GenerateInput
        control={control}
        defaultValue={contactData?.contact.phone ?? ''}
        disabled={!isEditable && !!contactId}
        errorField={errors.phone}
        fieldName="phone"
        helperText="Vul een geldig telefoonnummer in"
        label="Telefoonnummer"
        type="tel"
        variant="standard"
      />
    </>
  );
}
