/* eslint-disable no-loop-func, no-case-declarations, no-console */
import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  NormalizedCacheObject,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import TagIcon from '@mui/icons-material/Tag';
import { Box, Chip, Typography } from '@mui/material';
import { captureException } from '@sentry/minimal';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import jwtDecode from 'jwt-decode';
import toast from 'react-hot-toast';

import { GQL_URI } from '../constants/environment';
import { ERROR_TRACE } from '../constants/localState';
import { typeDefs } from '../graphql/local/typeDefs';
import { authUserRef, JwtAccessToken, refreshAuth, setSignedOut } from '../hooks/useAuth.hook';
import { Routes } from '../pages/Routes';
import { isFirstLoad, isSameBuild, version } from './buildInfo';
import { cache } from './cache';
import { handleErrors } from './handleErrors';

const httpLink = new HttpLink({ uri: GQL_URI });

let isRefreshing = false;
let pendingRequests: Array<() => void> = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorMiddleware = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      captureException(err);
    }

    const didHandleError = handleErrors(graphQLErrors, operation);
    if (didHandleError && response) {
      response.errors = undefined;
    }
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
    if (networkError.message === 'Response not successful: Received status code 403') {
      let forward$: Observable<string | void | undefined>;
      // Modify the operation context with a new token
      if (!isRefreshing) {
        isRefreshing = true;
        forward$ = fromPromise(
          refreshAuth()
            .then((token) => {
              if (!token) return;
              if (authUserRef) {
                // Update expiry and token on the fly to be picked up by `authMiddleware` for the pending requests
                const payload = jwtDecode<JwtAccessToken>(token.jwt);
                authUserRef.expiry = new Date(payload.exp * 1000);
                authUserRef.token = token.jwt;
              }
              resolvePendingRequests();
              return token.jwt;
            })
            .catch(() => {
              pendingRequests = [];
              setSignedOut();
              return;
            })
            .finally((): void => {
              isRefreshing = false;
            }),
        ).filter((value) => Boolean(value));
      } else {
        // Will only emit once the Promise is resolved
        forward$ = fromPromise(
          new Promise<void>((resolve) => {
            pendingRequests.push(() => resolve());
          }),
        );
      }

      return forward$.flatMap(() => forward(operation));
    } else {
      sessionStorage.setItem(ERROR_TRACE, JSON.stringify(networkError));
      window.location.href = Routes.Error;
    }
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  if (authUserRef?.token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${authUserRef.token}`,
      },
    });
  }
  return forward(operation);
});

export const createApolloClient = async (): Promise<ApolloClient<NormalizedCacheObject>> => {
  const newPersistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
  await newPersistor.restore();

  const client = new ApolloClient({
    cache,
    link: from([errorMiddleware, authMiddleware, httpLink]),
    typeDefs, // Local schema
  });

  // Clear the persisted cache onResetStore
  client.onResetStore(() => newPersistor.purge());

  const shouldClear = !isSameBuild && !isFirstLoad;
  if (shouldClear) {
    toast.success(() => (
      <Box flexDirection="column">
        <Typography variant="body2">App is bijgewerkt naar versie</Typography>
        <Chip color="info" icon={<TagIcon />} label={version} size="small" sx={{ mt: 1 }} variant="outlined" />
      </Box>
    ));
    await client.resetStore();
  }

  return client;
};
