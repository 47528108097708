import { Box } from '@mui/material';

import BackOfficeRouter from './BackOfficeRouter';
import NavLayout from './components/NavBar/NavLayout';

export const BACK_OFFICE_PADDING = 24; // px

export default function BackOffice(): JSX.Element {
  return (
    <Box sx={{ display: 'flex' }}>
      <NavLayout />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: `${BACK_OFFICE_PADDING}px`,
          pl: [0, 0, `${BACK_OFFICE_PADDING}px`],
          pr: [0, 0, `${BACK_OFFICE_PADDING}px`],
        }}
      >
        <BackOfficeRouter />
      </Box>
    </Box>
  );
}
