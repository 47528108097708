import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { SuccessMessages } from '../../../../../constants/messages';
import { useMoveLineItemToRoomMutation } from '../../../../../graphql/generated/hooks';
import { QuotationRoomFieldsFragment } from '../../../../../graphql/generated/operations';
import useBreakpointDown from '../../../../../hooks/useBreakpointDown.hook';
import CardTitle from '../../../../../pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from '../../../../../pages/BackOffice/components/DefaultButton/DefaultButton';

interface Props {
  handleClose: () => unknown;
  lineItemId?: string;
  open: boolean;
  rooms: Array<QuotationRoomFieldsFragment>;
  roomId: string;
}

const MoveRoomUpOrDownModal = ({ handleClose, lineItemId, open, rooms, roomId }: Props): JSX.Element => {
  const fullScreen = useBreakpointDown();

  const [moveRoomItemToNewDestination, { loading }] = useMoveLineItemToRoomMutation({
    update(_, { errors, data }) {
      if (errors || !data) return;
      toast.success(SuccessMessages.LineItemMovedToNewRoom);
      onClose();
    },
  });

  const otherRooms = useMemo(() => rooms.filter(({ id }) => id !== roomId), [roomId, rooms]);

  const [room, setRoom] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRoom(event.target.value as string);
  };

  const onClose = useCallback(() => {
    setRoom('');
    handleClose();
  }, [handleClose]);

  const handleMoveRoomItem = useCallback(async () => {
    if (!lineItemId || !room) return;
    moveRoomItemToNewDestination({ variables: { input: { lineItemId, newRoomId: room } } });
  }, [lineItemId, moveRoomItemToNewDestination, room]);

  useEffect(() => {
    if (otherRooms.length === 1) setRoom(otherRooms[0].id);
    else setRoom('');
  }, [otherRooms]);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle>
        <CardTitle color="primary" title="Ruimte verplaatsen" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          Selecteer de gewenste ruimte waaronder de huidige ruimte moet komen te staan.
          <TextField
            disabled={rooms?.length <= 1}
            fullWidth
            label="Ruimte"
            margin="normal"
            select
            value={room}
            onChange={handleChange}
          >
            {rooms.map(({ name, id }) => (
              <MenuItem disabled={id === roomId} key={id} selected={id === room} value={id}>
                {name}
                {id === roomId && ' (huidige ruimte)'}
              </MenuItem>
            ))}
          </TextField>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <DefaultButton disabled={loading} id="cancel" sx={{ flexGrow: 1 }} onClick={handleClose}>
          Annuleren
        </DefaultButton>
        <LoadingButton
          color="primary"
          disabled={!room}
          loading={loading}
          sx={{ flexGrow: 1 }}
          variant="contained"
          onClick={handleMoveRoomItem}
        >
          Verplaatsen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default MoveRoomUpOrDownModal;
