import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { AuthRoute } from 'components/AuthRoute/AuthRoute';
import PageLoader from 'components/PageLoader/PageLoader';

import { Routes } from '../Routes';

const AdminSettings = lazy(() => import('./Admin/AdminSettings'));
const Contacts = lazy(() => import('./Contacts/Contacts'));
const Contact = lazy(() => import('./Contacts/Contact/Contact'));
const CreateProduct = lazy(() => import('./Products/CreateProduct/CreateProduct'));
const CreateUser = lazy(() => import('./Users/CreateUser/CreateUser'));
const CreateContact = lazy(() => import('./Contacts/CreateContact/CreateContact'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const NotFound = lazy(() => import('../NotFound/NotFound'));
const MyProfile = lazy(() => import('./MyProfile/MyProfile'));
const User = lazy(() => import('./Users/User/User'));
const Users = lazy(() => import('./Users/Users'));
const Product = lazy(() => import('./Products/Product/Product'));
const Products = lazy(() => import('./Products/Products'));
const UpdateQuotation = lazy(() => import('./Quotations/UpdateQuotation/UpdateQuotation'));
const Quotations = lazy(() => import('./Quotations/Quotations'));

export default function BackOfficeRouter(): JSX.Element {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path={Routes.BackOffice}>
          <Redirect to={Routes.BackOfficeDashBoard} />
        </Route>
        <AuthRoute exact mode="private" path={Routes.BackOfficeUserList}>
          <Users />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeProductList}>
          <Products />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeUserCreate}>
          <CreateUser />
        </AuthRoute>
        <AuthRoute exact mode="private" path={`${Routes.BackOfficeUserShow}`}>
          <User mode="show" />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeUserEdit}>
          <User mode="edit" />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeProductList}>
          <Products />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeProductCreate}>
          <CreateProduct />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeProductShow}>
          <Product />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeProductEdit}>
          <Product />
        </AuthRoute>
        <AuthRoute collapseSidebar exact mode="private" path={Routes.BackOfficeQuotationShow}>
          <UpdateQuotation />
        </AuthRoute>
        {/* <AuthRoute collapseSidebar exact mode="private" path={Routes.BackOfficeQuotationEdit}>
          <Quotation mode="edit" />
        </AuthRoute> */}

        <AuthRoute exact mode="private" path={Routes.BackOfficeDashBoard}>
          <Dashboard />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeDashBoardAdmin}>
          <Dashboard />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeQuotationList}>
          <Quotations />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeContactList}>
          <Contacts />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeContactCreate}>
          <CreateContact />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeContactShow}>
          <Contact />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeContactEdit}>
          <Contact />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeAdminSettings}>
          <AdminSettings />
        </AuthRoute>
        <AuthRoute exact mode="private" path={Routes.BackOfficeProfile}>
          <MyProfile />
        </AuthRoute>
        <Route path={Routes.NotFound}>
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}
