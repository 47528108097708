import { Box, Toolbar, Typography } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';

import { UserSettings } from 'src/config/UserSettings';
import { useMyProfileButtonMeQuery } from 'src/graphql/generated/hooks';
import { Routes } from 'src/pages/Routes';
import { ReactComponent as Logo2Minimized } from 'src/static/citytech-l.svg';
import { ReactComponent as Logo2 } from 'src/static/citytech-lt.svg';
import { ReactComponent as Logo } from 'src/static/logo.svg';
import { ReactComponent as LogoMinimized } from 'src/static/logo-minimized.svg';

import NavItems from './NavItems';
import NavLinks from './NavLinks';

interface Props {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavSidebar = ({ setMobileOpen }: Props): JSX.Element => {
  const [{ isDrawerMinimized }] = useContext(UserSettings);
  const history = useHistory();
  const goHome = useCallback(() => history.push(Routes.Home), [history]);
  const { data: userData } = useMyProfileButtonMeQuery();

  const initials = useMemo(() => {
    if (!userData) return '';
    return `${userData.me.companyNameOwner}`;
  }, [userData]);

  const test1 = initials;
  let test2;

  if (test1 === 'Firefly') {
    test2 = false;
  } else {
    test2 = true;
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Toolbar
        disableGutters
        sx={{
          padding: isDrawerMinimized ? 0 : '0 16px',
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={goHome}
      >
        {test2 ? (
          <Box sx={{ width: '100%', display: 'flex' }}>
            {isDrawerMinimized ? (
              <Logo2Minimized style={{ marginLeft: 12, marginRight: 12 }} />
            ) : (
              <Logo2 style={{ marginRight: 16 }} />
            )}
          </Box>
        ) : (
          <Box sx={{ width: '100%', display: 'flex' }}>
            {isDrawerMinimized ? (
              <LogoMinimized style={{ marginLeft: 12, marginRight: 12 }} />
            ) : (
              <Logo style={{ marginRight: 16 }} />
            )}
          </Box>
        )}
        {!isDrawerMinimized && (
          <Typography sx={{ width: '100%', fontWeight: 600, color: 'rgba(255,255,255,0.8)', fontSize: 14 }}>
            Back-office
          </Typography>
        )}
      </Toolbar>
      <NavItems setMobileOpen={setMobileOpen} />
      <NavLinks />
    </Box>
  );
};

export default NavSidebar;
