import { SvgIcon, SvgIconProps } from '@mui/material';

function LeafIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 469.8 469.8" {...props}>
      <path
        d="M459.1 63.8c-56.9 0-137.2 4.8-178.2 45.8-17.8 17.8-26.6 46.7-24.3 79.5.3 4.3 3.2 8 7.3 9.4 4.1 1.3 8.6.1 11.5-3.2 22.8-26.9 51.2-48.4 84.4-64 2.8-1.3 6.1-1.5 9.2-.4 2.7 1 4.8 2.8 5.8 5.1 2.3 4.8 1.5 11.1-6 14.6-.4.2-.8.5-1.2.7-.1.1-.3 0-.4.1-81.8 38.4-122 105.5-140.3 174.5-13.3-86.4-41.4-137.2-68-168.4-19.2-24.9-38.1-38.1-49-45.6-2-1.4-8.1-5.6-10.7-8.1-4.2-4.2-4.2-10.9 0-15.1 4.2-4.1 10.9-4.1 15.6.5 1.1 1 2.9 2.3 5.2 3.8l1.9 1.3c15.4 10.6 44.2 30.2 69.7 72.5 2.3 3.8 6.7 5.8 11 5 4.4-.8 7.8-4.1 8.6-8.4 5.6-28.4 3.1-70.6-22.3-96-41-41-121.4-45.8-178.2-45.8C4.8 21.6 0 26.3 0 32.2c0 56.9 4.8 137.2 45.8 178.2 17.1 17.1 43.5 24.8 69.7 24.8 21.4 0 42.4-5.4 58.4-14.6 21.9 45.4 39.4 113.2 39.4 217 0 5.9 4.8 10.7 10.7 10.7s10.7-4.8 10.7-10.7c0-57.9 9.9-127.3 46.8-184.5 14.8 14.4 40.2 23.6 68.4 24.4 1.4 0 2.8.1 4.2.1 28.7 0 54-9 69.9-24.9 41-41 45.8-121.4 45.8-178.2 0-6-4.8-10.7-10.7-10.7z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default LeafIcon;
