import { createContext, Dispatch, SetStateAction } from 'react';

import { APP_THEME_MODE, COMPANY_NAME_MODE, IS_DRAWER_MINIMIZED } from '../constants/localState';

export type AppThemeMode = 'auto' | 'dark' | 'light';

export type CompanyNameMode = 'Firefly' | 'Citytech' | 'Summit Software';
interface UserSettingsType {
  isDrawerMinimized: boolean;
  appThemeMode: AppThemeMode;
  companyNameMode: CompanyNameMode;
}

export const getAppThemeMode = (): AppThemeMode => {
  const currentlyStored = localStorage.getItem(APP_THEME_MODE);
  if (currentlyStored && ['auto', 'dark', 'light'].includes(currentlyStored)) {
    return currentlyStored as AppThemeMode;
  }
  return setAppThemeMode();
};

/**
 * Set the app theme mode to a preference and default to `light`
 */
export const setAppThemeMode = (preference: AppThemeMode = 'light'): AppThemeMode => {
  localStorage.setItem(APP_THEME_MODE, preference);
  return preference;
};

export const getIsDrawerMinimized = (): boolean => {
  const value = localStorage.getItem(IS_DRAWER_MINIMIZED);
  if (value === 'true' || value === 'false') return value === 'true';
  return setIsDrawerMinimized();
};

export const setIsDrawerMinimized = (preference = false): boolean => {
  localStorage.setItem(IS_DRAWER_MINIMIZED, preference.toString());
  return preference;
};

export const getCompanyNameMode = (): CompanyNameMode => {
  const currentlyStored = localStorage.getItem(COMPANY_NAME_MODE);
  if (currentlyStored && ['Firefly', 'Citytech', 'Summit Software'].includes(currentlyStored)) {
    return currentlyStored as CompanyNameMode;
  }
  return setCompanyNameMode();
};

export const setCompanyNameMode = (preference: CompanyNameMode = 'Citytech'): CompanyNameMode => {
  localStorage.setItem(COMPANY_NAME_MODE, preference);
  return preference;
};

export const initialUserSettings: UserSettingsType = {
  isDrawerMinimized: getIsDrawerMinimized(),
  appThemeMode: getAppThemeMode(),
  companyNameMode: getCompanyNameMode(),
};

export const UserSettings = createContext<[UserSettingsType, Dispatch<SetStateAction<UserSettingsType>>]>([
  initialUserSettings,
  () => {
    // can be empty, will be set by useState()
  },
]);
