import { useMediaQuery, useTheme } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { BACK_OFFICE_PADDING } from 'src/pages/BackOffice/BackOffice';
import { DRAWER_WIDTH } from 'src/pages/BackOffice/components/NavBar/NavLayout';
import { Routes } from 'src/pages/Routes';

import { UserSettings } from '../../config/UserSettings';
import { useAuth } from '../../hooks/useAuth.hook';
import PageLoader from '../PageLoader/PageLoader';
import { QUOTATION_WIDTH } from '../Quotation/Quotation';

type AuthRouteProps = RouteProps & { mode: 'private' | 'guest'; collapseSidebar?: boolean };

/**
 * A higher order function that returns a private or guest Route.
 */
export const AuthRoute: FC<AuthRouteProps> = ({ children, mode, collapseSidebar, ...rest }) => {
  const [auth] = useAuth();

  const [, setUserSettings] = useContext(UserSettings);

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const shouldMinimize = useMediaQuery(`(max-width:${QUOTATION_WIDTH + DRAWER_WIDTH + 2 * BACK_OFFICE_PADDING * 2}px)`);

  useEffect(() => {
    if (!collapseSidebar || mediumScreen) return;
    setUserSettings((userSettings) => ({ ...userSettings, isDrawerMinimized: shouldMinimize }));
  }, [setUserSettings, shouldMinimize, collapseSidebar, mediumScreen]);

  if (!auth) {
    return <PageLoader />;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (mode === 'private' && auth.token) || (mode === 'guest' && !auth.token) ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: mode === 'private' ? Routes.SignIn : Routes.Home,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

// /**
//  * A wrapper for <Route> that redirects to the sign in page if you're not yet authenticated.
//  */
// export const PrivateRoute = <AuthRoute mode="private" />;

// /**
//  * A wrapper for <Route> that redirects to the Homepage if you are authenticated.
//  */
// export const GuestRoute = <AuthRoute mode="guest" />;
