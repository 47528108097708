import { Grid, Skeleton, Typography } from '@mui/material';

interface Props {
  heading?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  postalCode?: string;
  city?: string;
  loading?: boolean;
  fullName?: string;
}

const AddressBlock = ({ heading, address1, postalCode, city, loading, fullName }: Props): JSX.Element => {
  return (
    <Grid container direction="column" item spacing={1}>
      {heading && (
        <Grid item>
          <Typography color="text.secondary" variant="body1">
            {heading}
          </Typography>
        </Grid>
      )}
      {loading ? (
        <Grid item>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Grid>
      ) : (
        <Grid item>
          <Typography color="text.secondary" variant="body2">
            {fullName}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {address1}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {postalCode} {city}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default AddressBlock;
