import { Button, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const DefaultButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: grey['100'],
  '&:hover': {
    backgroundColor: grey['400'],
  },
  '&:active': {
    backgroundColor: grey['500'],
  },
}));

export default DefaultButton;
