import { useCallback, useState } from 'react';

import {
  NewProductFragment,
  OldCategoryFragment,
  OldProductFragment,
  VariantFragment,
} from '../../graphql/generated/operations';
import { notMaybe } from '../../utils/types.helper';
import { FilterChangeType } from '.';
import {
  CustomFieldsValueStrict,
  FilterConfig,
  FilterFunctions,
  FilterState,
  FilterStep,
  FilterStepOptions,
} from './useFilterProgress.types';
import { collapse, getNextStep, getProductVariantsStep, parseOptions } from './useFilterProgress.utils';

/**
 * FilterProgress hook
 *
 * Wrapper around FilterProgress[] state with some useful helper functions like:
 *  * `setStep` to update a specific step to a new choice/value and discard al further steps
 *  * some helper functions to make toggling easier
 */
export const useFilterProgress = (config: FilterConfig): [state: FilterState, functions: FilterFunctions] => {
  const [filterState, setFilterState] = useState<FilterState>({
    isProductSelected: false,
    isFinished: false,
    lastChange: { type: FilterChangeType.Init },
    progress: [{ title: config.fixedStepTitles.get(0) ?? '[unknown]', isExpanded: true }],
  });

  const setStep = useCallback(
    (step: number, choice: CustomFieldsValueStrict, options?: FilterStepOptions) => {
      const _options = parseOptions(options);
      setFilterState((currentState) => {
        // Do not change the progress if: it should not be reset AND the same choice is re-selected
        if (!_options.shouldResetOnReselect && currentState.progress[step].choice === choice) {
          return {
            ...currentState,
            lastChange: { type: FilterChangeType.SameOptionReselected, step },
            progress: currentState.progress.map((s, i) => ({
              ...s,
              isExpanded: i === step ? !currentState.progress[step].isExpanded : s.isExpanded,
            })),
          };
        }

        const currentStep = { ...currentState.progress[step] };
        const progressUpToStep = currentState.progress.slice(0, step);
        const progressCollapsed = _options.collapseOtherSteps
          ? progressUpToStep.map(collapse) // Collapse all previous steps
          : progressUpToStep;

        // Call `onFinished` when there is one product remaining
        if (_options.remainingProducts?.length === 1) {
          return {
            isProductSelected: true,
            isFinished: _options.productVariant !== undefined,
            lastChange: { type: FilterChangeType.FinalProductSelected, step },
            progress: [
              ...progressCollapsed,
              {
                ...currentStep,
                isExpanded: _options.isExpanded,
                choice,
                remainingProducts: _options.remainingProducts,
                productVariant: _options.productVariant,
              },
            ],
          };
        }

        const nextStepTitle = config.fixedStepTitles.get(step + 1);
        const nextStep: FilterStep =
          nextStepTitle || !_options.remainingProducts
            ? { isExpanded: true, title: nextStepTitle ?? '...' }
            : getNextStep(
                (value, options) => setStep(step + 1, value, options),
                config.newProductFilters,
                currentState.progress.map((filterStep) => filterStep.filter?.fieldName).filter(notMaybe),
                _options.remainingProducts,
              );
        return {
          isProductSelected: false,
          isFinished: false,
          lastChange: {
            type:
              currentState.progress.length - 1 === step
                ? FilterChangeType.NewOptionSelected
                : FilterChangeType.OptionChanged,
            step,
          },
          progress: [...progressCollapsed, { ...currentStep, isExpanded: _options.isExpanded, choice }, nextStep],
        };
      });
    },
    [config.fixedStepTitles, config.newProductFilters],
  );

  const toggleIsExpanded = useCallback((step: number) => {
    setFilterState((currentState) => {
      const progress = [...currentState.progress]; // Clone the currentProgress before mutating it
      progress[step].isExpanded = !progress[step].isExpanded;
      return {
        ...currentState,
        lastChange: {
          type: progress[step].isExpanded ? FilterChangeType.ExpandOne : FilterChangeType.CollapseOne,
          step,
        },
        progress,
      };
    });
  }, []);

  const expandOrCollapseAll = useCallback(
    (shouldExpand = true) =>
      () =>
        setFilterState((currentState) => ({
          ...currentState,
          lastChange: { type: shouldExpand ? FilterChangeType.ExpandAll : FilterChangeType.CollapseAll },
          progress: currentState.progress.map((step) => ({ ...step, isExpanded: shouldExpand })),
        })),
    [],
  );

  const toggle = useCallback(
    (step: number) => (filterState.progress[step]?.choice === undefined ? undefined : () => toggleIsExpanded(step)),
    [filterState.progress, toggleIsExpanded],
  );

  const setOldCategories = useCallback(
    (oldCategories: OldCategoryFragment[]) => {
      const sortedOldCategories = [...oldCategories].sort((a, b) => a.name.localeCompare(b.name));
      // Function called on the initial cache load, but will be called again when the data from the server is available
      setFilterState((currentState) => ({
        ...currentState,
        lastChange: { type: FilterChangeType.OldCategoriesLoaded, step: 0 },
        progress: [
          {
            ...currentState.progress[0],
            options: sortedOldCategories.map((category) => ({
              title: category.name,
              onSelect: (value, options) => setStep(0, value, options),
              thumbnailUrl: category.imageUrl,
              value: category.id,
            })),
          },
        ],
      }));
    },
    [setStep],
  );

  const setOldProducts = useCallback(
    (oldProducts: readonly OldProductFragment[]) => {
      // Sort the old products by sortOrder, then by name
      const products = [...oldProducts].sort((a, b) =>
        a.sortOrder - b.sortOrder === 0 ? a.name.localeCompare(b.name) : a.sortOrder - b.sortOrder,
      );

      // Function called when an oldCategory is selected and the oldCategory (with oldProducts in it) has been loaded from the API
      setFilterState((currentState) => ({
        ...currentState,
        lastChange: { type: FilterChangeType.OldProductsLoaded, step: 1 },
        progress: [
          currentState.progress[0], // Keep the first step
          {
            ...currentState.progress[1],
            options: products.map((product) => ({
              title: product.name,
              onSelect: (value, options) => setStep(1, value, options),
              thumbnailUrl: product.thumbnail?.urlThumbnail,
              value: product.id,
            })),
          },
        ],
      }));
    },
    [setStep],
  );

  const setNewProducts = useCallback(
    (products: NewProductFragment[]) => {
      setFilterState((currentState) => {
        const step = currentState.progress.length - 1;
        const lastStep = { ...currentState.progress[step] };
        const progressUpToStep = currentState.progress.slice(0, step);
        const nextStep = getNextStep(
          (value, options) => setStep(step, value, options),
          config.newProductFilters,
          [], // This will be the first filter to be chosen i.e. there are no previous filters
          products,
          lastStep,
        );
        return {
          ...currentState,
          lastChange: { type: FilterChangeType.NewProductsLoaded, step },
          progress: [...progressUpToStep, nextStep],
        };
      });
    },
    [config.newProductFilters, setStep],
  );

  const setProductVariants = useCallback(
    (productVariants: VariantFragment[]) => {
      setFilterState((currentState) => {
        const step = currentState.progress.length - 1;
        const progress = currentState.progress.slice(0, step + 1).map(collapse);
        const remainingProducts = currentState.progress[step].remainingProducts;
        if (!remainingProducts || remainingProducts.length !== 1) {
          throw new Error('Cannot show product variants if there is no single final product');
        }
        const nextStep = getProductVariantsStep(
          (value, options) => setStep(step + 1, value, options),
          config.productVariantFilter,
          remainingProducts[0],
          productVariants,
        );
        return {
          ...currentState,
          lastChange: { type: FilterChangeType.ProductVariantsLoaded, step },
          isFinished: productVariants.length === 0, // No product variants available, skip step and mark as `isFinished`
          progress: nextStep ? [...progress, nextStep] : progress,
        };
      });
    },
    [config.productVariantFilter, setStep],
  );

  return [
    filterState,
    {
      // setFilterState, // Ideally direct access to the raw set function is not needed
      setStep,
      toggleIsExpanded,
      toggle,
      setOldCategories,
      setOldProducts,
      setNewProducts,
      setProductVariants,
      expandAll: expandOrCollapseAll(true),
      collapseAll: expandOrCollapseAll(false),
    },
  ];
};
