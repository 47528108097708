import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { ErrorMessages, SuccessMessages } from 'src/constants/messages';
import { Quotation } from 'src/graphql/generated/schema';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';

import {
  useGetAppSettingsPowerUsageCostQuery,
  useUpdateQuotationPowerUsageCostMutation,
} from '../../graphql/generated/hooks';
import { GetQuotationQuery } from '../../graphql/generated/operations';
import useBreakpointDown from '../../hooks/useBreakpointDown.hook';
import DaySlider from './DaySlider/DaySlider';
import DaysOfWeekBar from './DaysOfWeekBar/DaysOfWeekBar';

interface AdjustConsumptionProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  quotation: GetQuotationQuery['quotation'];
}

const toggleSx: SxProps = {
  backgroundColor: 'rgba(1, 1, 1, 0.02)',
  margin: '1rem 2px',
  letterSpacing: '0.4px',
  textTransform: 'capitalize',
  width: '100%',
  padding: '0.3rem 0',
  '&.Mui-selected': {
    width: '100%',
    backgroundColor: 'primary.contrastText',
    color: 'primary.main',
    borderRadius: '6px',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)',
  },
};

const AdjustConsumption: FC<AdjustConsumptionProps> = ({ open, setOpen, quotation }) => {
  const [updateQuotationPowerUsageCost] = useUpdateQuotationPowerUsageCostMutation({
    update(_, { errors, data }) {
      if (errors) {
        toast.error(ErrorMessages.UnknownError);
      } else if (data) {
        toast.success(SuccessMessages.DataProcessed);
        resetForm(data.updateQuotationPowerUsageCost);
        setOpen(false);
      }
    },
  });
  const { data: defaultPowerUsageCost } = useGetAppSettingsPowerUsageCostQuery({ fetchPolicy: 'cache-and-network' });

  const { isNewLocation, powerUsageHours, powerCostExcl, powerCostIncl, powerUsageDays } = quotation;

  const [energyPriceType, setEnergyPriceType] = useState(powerCostExcl ? 'powerCostExcl' : 'powerCostIncl');
  const [location, setLocation] = useState(isNewLocation);
  const [dayHours, setDayHours] = useState(powerUsageHours);
  const [daysStateBoolean, setDaysStateBoolean] = React.useState<Array<boolean>>(powerUsageDays);
  const [energyPrice, setEnergyPrice] = useState<number>(powerCostExcl ? powerCostExcl : powerCostIncl ?? 0);

  const mediumScreen = useBreakpointDown();
  const smallScreen = useBreakpointDown('sm');

  const resetForm = useCallback(
    (
      q: Pick<Quotation, 'powerCostExcl' | 'isNewLocation' | 'powerUsageHours' | 'powerUsageDays' | 'powerCostIncl'>,
    ) => {
      setEnergyPriceType(q.powerCostExcl ? 'powerCostExcl' : 'powerCostIncl');
      setLocation(q.isNewLocation);
      setDayHours(q.powerUsageHours);
      setDaysStateBoolean(q.powerUsageDays);
      setEnergyPrice(q.powerCostExcl ? q.powerCostExcl : q.powerCostIncl ?? 0);
    },
    [],
  );
  const handleClose = useCallback(() => {
    resetForm(quotation);
    setOpen(false);
  }, [quotation, resetForm, setOpen]);

  const handleChangeType = useCallback((event: React.MouseEvent<HTMLElement>, selected: boolean) => {
    if (selected !== null) setLocation(selected);
  }, []);

  const handleEnergyCosts = useCallback(
    (event: React.MouseEvent<HTMLElement>, selected: string) => {
      if (selected !== null) setEnergyPriceType(selected);

      // Set the energyPrice to the default value when switching between energyPriceTypes
      if (!defaultPowerUsageCost) return;
      setEnergyPrice(
        energyPriceType === 'powerCostExcl'
          ? defaultPowerUsageCost.appSettingsPowerCost.defaultPowerUsageCostExcl
          : defaultPowerUsageCost.appSettingsPowerCost.defaultPowerUsageCostIncl,
      );
    },
    [defaultPowerUsageCost, energyPriceType],
  );

  const handleSave = useCallback(() => {
    updateQuotationPowerUsageCost({
      variables: {
        input: {
          quotationId: quotation.id,
          isNewLocation: location,
          powerCostExcl: energyPriceType === 'powerCostExcl' ? energyPrice : 0,
          powerCostIncl: energyPriceType === 'powerCostIncl' ? energyPrice : 0,
          powerUsageHours: dayHours,
          powerUsageDays: daysStateBoolean,
        },
      },
    });
  }, [dayHours, daysStateBoolean, energyPrice, energyPriceType, location, quotation.id, updateQuotationPowerUsageCost]);

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <Dialog
      fullScreen={mediumScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      sx={{ display: 'flex', flexDirection: 'column' }}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '4px',
          }}
        >
          <SettingsIcon color="primary" fontSize="medium" />
          <Typography sx={{ margin: '1rem 8px', color: 'primary.main' }} variant="h3">
            Verbruik aanpassen
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '100%', padding: '0 10px', mt: '10px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">Type ruimte</Typography>
          </Box>

          <ToggleButtonGroup
            disabled={!defaultPowerUsageCost} // defaultPowerUsageCost is used to set as default value when switching between energyPriceTypes
            exclusive
            fullWidth
            value={location}
            onChange={handleChangeType}
          >
            <ToggleButton sx={toggleSx} value={true}>
              <Radio checked={location} name="radio" value={location} onChange={handleRadio} />

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant={smallScreen ? 'caption' : 'body1'}>Nieuwe ruimte</Typography>
                {!smallScreen && <Typography variant="subtitle2">Géén oude lampen vervangen</Typography>}
              </Box>
            </ToggleButton>
            <ToggleButton sx={toggleSx} value={false}>
              <Radio checked={!location} name="radio" value={location} onChange={handleRadio} />

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant={smallScreen ? 'caption' : 'body1'}>Oude ruimte</Typography>
                {!smallScreen && <Typography variant="subtitle2">Oude lampen vervangen</Typography>}
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>

          <DaySlider dayHours={dayHours} setDayHours={setDayHours} title="Gemiddeld aantal branduren per dag" />

          <DaysOfWeekBar
            daysStateBoolean={daysStateBoolean}
            setDaysStateBoolean={setDaysStateBoolean}
            title="Dagen in de week"
          />

          <Typography sx={{ mt: '1rem' }} variant="h4">
            Energiekosten (€/kW⋅h)
          </Typography>

          <ToggleButtonGroup exclusive fullWidth value={energyPriceType} onChange={handleEnergyCosts}>
            <ToggleButton sx={toggleSx} value="powerCostExcl">
              Kaal kW⋅h tarief
            </ToggleButton>
            <ToggleButton sx={toggleSx} value="powerCostIncl">
              All-in tarief
            </ToggleButton>
          </ToggleButtonGroup>
          <TextField
            id="outlined-disabled"
            inputProps={{ pattern: '/^d+$/' }}
            label="(€/kW⋅h)"
            size="small"
            sx={{
              margin: '1rem .2rem',
              width: '50%',
              backgroundColor: ' rgba(1, 1, 1, 0.02)',
              '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none',
              },
            }}
            type="number"
            value={energyPrice}
            onChange={(e) => setEnergyPrice(+e.target.value)}
          />
          <Box sx={{ margin: '.5rem 0', display: 'flex', alignItems: 'center' }}>
            <InfoOutlinedIcon color="primary" />
            {energyPriceType === 'powerCostIncl' ? (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ ml: '1rem' }} variant="caption">
                  Dit is het huidige gemiddelde kale energietarief voor de zakelijke markt,
                </Typography>
                <Typography sx={{ ml: '1rem' }} variant="caption">
                  met de daarbij horende belastingen en heffingen excl. BTW.
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ ml: '1rem' }} variant="caption">
                  Vul hier het kale kW⋅h tarief in. Op de achtergrond worden de wettelijke
                </Typography>
                <Typography sx={{ ml: '1rem' }} variant="caption">
                  belastingen toegevoegd om tot een goede besparingsindicatie te komen.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ margin: '.5rem 1rem' }}>
        <DefaultButton sx={{ width: '100%' }} onClick={handleClose}>
          Annuleren
        </DefaultButton>
        <Button color="primary" sx={{ width: '100%' }} variant="contained" onClick={handleSave}>
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdjustConsumption;
