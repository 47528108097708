import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';

import RoomDialog from './roomDialogs/RoomDialog';

interface Props {
  quotationId: string;
}

const NewRoom = ({ quotationId }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          paddingLeft: '8px',
          backgroundColor: 'primary.200',
          '&:hover': {
            backgroundColor: 'primary.300',
          },
          color: 'primary.contrastText',
          marginBottom: '24px',
          padding: '1rem',
          width: '100%',
          justifyContent: 'flex-start',
          mt: '1rem',
          borderRadius: 0,
        }}
        onClick={() => setOpen(true)}
      >
        <AddCircleOutlinedIcon sx={{ color: 'primary.contrastText' }} />

        <Typography
          color="primary"
          sx={{ display: 'flex', alignItems: 'center', marginLeft: '16px', color: 'primary.contrastText' }}
          variant="h3"
        >
          <AspectRatioIcon sx={{ marginRight: '16px' }} />
          Nieuwe ruimte toevoegen
        </Typography>
      </Button>
      <RoomDialog open={open} quotationId={quotationId} setOpen={setOpen} />
    </>
  );
};

export default NewRoom;
