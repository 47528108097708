import { Box, debounce, styled, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { useMemo } from 'react';

interface Props {
  title: string;
  setDayHours: (dayHours: number) => void;
  dayHours: number;
  disabled?: boolean;
}

const PrettoSlider = styled(Slider)({
  color: 'primary.main',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const DaySlider = ({ title, setDayHours, dayHours, disabled }: Props): JSX.Element => {
  const debouncedSetHours = useMemo(() => debounce(setDayHours, 300), [setDayHours]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      debouncedSetHours(newValue);
    }
  };
  return (
    <Box sx={{ width: '100%', pr: '1rem ' }}>
      <Typography component="span" sx={{ mb: '1rem', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="h4">{dayHours ? `(${dayHours})` : null}</Typography>
      </Typography>
      <Box>
        <PrettoSlider
          defaultValue={dayHours}
          disabled={disabled}
          key={`slider-${dayHours}`}
          max={24}
          valueLabelDisplay="auto"
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};
export default DaySlider;
