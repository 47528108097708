import { Box, Card, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Redirect } from 'react-router';

import { PaymentType } from 'src/graphql/generated/schema';
import { BACK_OFFICE_PADDING } from 'src/pages/BackOffice/BackOffice';
import { MINIMIZED_DRAWER_WIDTH } from 'src/pages/BackOffice/components/NavBar/NavLayout';
import { Routes } from 'src/pages/Routes';
import { currency } from 'src/utils/currency.helper';

import { getIsDrawerMinimized, UserSettings } from '../../config/UserSettings';
import { companyInfo } from '../../constants/companyInfo';
import { ERROR_TRACE } from '../../constants/localState';
import { useGetQuotationQuery } from '../../graphql/generated/hooks';
import PageLoader from '../PageLoader/PageLoader';
import AddressBlock from './components/AddressBlock/AddressBlock';
import EnergyConsumption from './components/EnergyConsumption/EnergyConsumption';
import QuotationConfirmation from './components/QuotationConfirmation/QuotationConfirmation';
import QuotationContact from './components/QuotationContact/QuotationContact';
import QuotationDate from './components/QuotationDate/QuotationDate';
import QuotationProjectLocatie from './components/QuotationProjectLocatie/QuotationProjectLocatie';
import QuotationReference from './components/QuotationReference/QuotationReference';
import QuotationSummary from './components/QuotationSummary/QuotationSummary';
import NewRoom from './components/Room/NewRoom';
import RoomList from './components/Room/RoomList';
import Signature from './components/Signature/Signature';

interface Props {
  quotationId: string;
  isEditable: boolean;
  isSigning?: boolean;
  setIsSigning?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QUOTATION_WIDTH = 780; // px
export const SCROLL_BAR_WIDTH = 16; // px -> required to prevent unwanted horizontal scroll due to 100vw to be including vertical scroll bar
export const QUOTATION_PADDING = 55; // px

export default function Quotation({ quotationId, isEditable, isSigning, setIsSigning }: Props): JSX.Element {
  const [, setUserSettings] = useContext(UserSettings);

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Disable minimized drawer on mobile & tablet (without persisting to localStorage)
  useEffect(() => {
    if (mediumScreen) {
      setUserSettings((settings) => ({ ...settings, isDrawerMinimized: false }));
    } else {
      setUserSettings((settings) => ({ ...settings, isDrawerMinimized: getIsDrawerMinimized() }));
    }
  }, [mediumScreen, setUserSettings]);

  const { data, error } = useGetQuotationQuery({
    fetchPolicy: 'cache-and-network',
    variables: { input: { quotationId } },
  });

  if (error) {
    sessionStorage.setItem(ERROR_TRACE, JSON.stringify(error));
    return <Redirect to={Routes.Error} />;
  }

  return (
    <Card
      sx={{
        pt: 7,
        pb: 13,
        overflowX: 'auto',
        pl: '0px',
        maxWidth: {
          sm: '100vw',
          md: `min(${QUOTATION_WIDTH}px, calc(100vw - ${
            SCROLL_BAR_WIDTH + MINIMIZED_DRAWER_WIDTH + 2 * BACK_OFFICE_PADDING
          }px))`,
        },
      }}
    >
      <Box
        sx={{
          minWidth: `${QUOTATION_WIDTH}px`,
          flexBasis: '100%',
          maxWidth: `${QUOTATION_WIDTH}px`,
        }}
      >
        {!data ? (
          <PageLoader />
        ) : (
          <Grid container direction="column">
            <Grid container direction="row" item spacing={1} sx={{ minHeight: '12rem' }}>
              <QuotationContact
                isEditable={isEditable}
                loadingQuotation={data === undefined}
                quotationData={data}
                quotationId={quotationId}
              />
              <Grid item xs={3}>
                <AddressBlock
                  address1={companyInfo.address1}
                  city={companyInfo.city}
                  fullName={data.quotation.accountManagerFullName}
                  heading={companyInfo.companyName}
                  postalCode={companyInfo.postalCode}
                />
              </Grid>
              <Grid item xs={1} />
              <QuotationProjectLocatie isEditable={isEditable} quotation={data.quotation} />
            </Grid>
            <Grid container direction="row" item spacing={1} sx={{ minHeight: '8rem' }}>
              <QuotationReference isEditable={isEditable} quotation={data.quotation} />

              <Grid item xs={3}>
                <QuotationDate quotationData={data} />
              </Grid>

              <Grid item xs={1} />
            </Grid>

            <Grid container direction="row" item spacing={1}>
              <Grid item xs={12}>
                <EnergyConsumption isEditable={isEditable} quotation={data.quotation} />
              </Grid>
            </Grid>

            <Grid container direction="row" item spacing={1}>
              <Grid item xs={12}>
                <RoomList
                  isEditable={isEditable}
                  isNewLocation={data.quotation.isNewLocation}
                  quotationId={data.quotation.id}
                  rooms={data.quotation.rooms}
                />
              </Grid>
            </Grid>

            {isEditable && (
              <Grid container direction="row" item spacing={1} sx={{ minHeight: '8rem' }}>
                <Grid item xs={12}>
                  <NewRoom quotationId={quotationId} />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              direction="row"
              item
              spacing={1}
              sx={{ minHeight: data.quotation.paymentType === PaymentType.Full ? '8rem' : '0', mt: isEditable ? 0 : 6 }}
            >
              {data.quotation.paymentType === PaymentType.Full ? (
                <QuotationSummary isEditable={isEditable} quotation={data.quotation} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '.5rem 4rem',
                    width: '100%',
                  }}
                >
                  <Typography variant="body1">Light as a Service vergoeding excl. BTW (per maand)</Typography>
                  <Typography variant="body1">
                    {currency(data.quotation.totalMonthlyLaasChargeOverwrite ?? data.quotation.totalMonthlyLaasCharge)}
                  </Typography>
                </Box>
              )}
            </Grid>

            {!isEditable && isSigning !== undefined && setIsSigning && (
              <>
                <Grid container direction="row" item>
                  <Signature isEditable={data.quotation.isEditable} isSigning={isSigning} setIsSigning={setIsSigning} />
                </Grid>

                {data.quotation.isEditable && (
                  <Grid container direction="row" item>
                    <Grid item xs={12}>
                      <QuotationConfirmation isSigning={isSigning} quotationId={data.quotation.id} />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
      </Box>
    </Card>
  );
}
