import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EuroIcon from '@mui/icons-material/Euro';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Card, Divider, SxProps, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { GetQuotationQuery } from 'src/graphql/generated/operations';
import { PaymentType } from 'src/graphql/generated/schema';
import CardEditButton from 'src/pages/BackOffice/components/CardEditButton/CardEditButton';
import { ReactComponent as Co2 } from 'src/static/co2-icon.svg';
import { ReactComponent as Tree } from 'src/static/tree-icon.svg';
import { currency } from 'src/utils/currency.helper';
import { energyUsage, round } from 'src/utils/number.helper';

import AdjustConsumption from '../../../SavingsOverview/AdjustConsumption';

interface Props {
  isEditable?: boolean;
  quotation: GetQuotationQuery['quotation'];
}

const TopBarbBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '5px 10px',
  margin: '0 1rem',
};

const TopBarStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  mt: '5px',
  mr: '1rem',
};

const RowJustifyBetweenStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: '0.5rem',
  mt: '0.5rem',
};

const MONTHS_PER_YEAR = 12;

export default function EnergyConsumption({ isEditable, quotation }: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const days = useMemo(
    () => quotation.powerUsageDays.reduce((count, day) => count + (day ? 1 : 0), 0),
    [quotation.powerUsageDays],
  );
  return (
    <Box sx={{ margin: '12px', display: 'flex', flexDirection: 'column', mt: '3rem' }}>
      <Box display="flex">
        {isEditable && (
          <Box sx={{ mr: '20px' }}>
            <CardEditButton onClick={() => setOpen(true)} />
          </Box>
        )}
        <Typography color="text.secondary" sx={{ ml: isEditable ? 0 : '45px' }} variant="h2">
          Energieverbruik
        </Typography>
      </Box>
      <AdjustConsumption open={open} quotation={quotation} setOpen={setOpen} />
      <Box sx={{ m: '20px 35px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Box sx={{ ...TopBarbBoxStyles, ml: 0 }}>
            <Typography variant="caption">Gem. branduren per dag</Typography>
            <Box sx={TopBarStyles}>
              <AccessTimeIcon fontSize="medium" sx={{ color: 'text.secondary', mr: '10px' }} />
              <Typography color="text.secondary" variant="body1">
                {quotation.powerUsageHours} uur
              </Typography>
            </Box>
          </Box>
          <Divider flexItem orientation="vertical" variant="middle" />

          <Box sx={{ ...TopBarbBoxStyles, ml: 0 }}>
            <Typography variant="caption">Dagen per week</Typography>
            <Box sx={TopBarStyles}>
              <TodayIcon fontSize="medium" sx={{ color: 'text.secondary', mr: '10px' }} />
              <Typography color="text.secondary" variant="body1">
                {days === 1 ? '1 dag' : `${days} dagen`}
              </Typography>
            </Box>
          </Box>
          <Divider flexItem orientation="vertical" variant="middle" />

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 10px' }}>
            <Typography variant="caption">Energiekosten</Typography>
            <Box sx={TopBarStyles}>
              <EuroIcon fontSize="medium" sx={{ color: 'text.secondary', mr: '10px' }} />
              <Typography color="text.secondary" variant="body1">
                {`${new Intl.NumberFormat('nl-NL').format(
                  Math.round((quotation.powerCostIncl ?? quotation.powerCostExcl ?? 0) * 1000) / 10,
                )}`}{' '}
                cent per kWh{quotation.powerCostIncl ? ' (incl. toeslagen)' : ''}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '5px 10px',
            mt: '3rem',
          }}
        >
          {!quotation.isNewLocation && (
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', mr: '3rem' }}>
              <Typography color="text.secondary" sx={{ mb: '1rem' }} variant="body1">
                Verbruik huidige verlichting per jaar
              </Typography>

              <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                <Typography variant="body2">Energie in kWh</Typography>
                <Typography variant="body1">{energyUsage(quotation.oldEnergyUsagePerYear, 0)}</Typography>
              </Box>

              <Divider sx={{ color: 'text.secondary', margin: '5px 0' }} />

              <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                <Typography variant="body2">Energie in euro</Typography>
                <Typography variant="body1">{currency(quotation.oldEnergyCostPerYear)}</Typography>
              </Box>

              <Divider sx={{ color: 'text.secondary', margin: '5px 0' }} />

              <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                <Typography variant="body2">Onderhoud</Typography>
                <Typography variant="body1">{currency(quotation.costSavingsOnMaintenancePerYear)}</Typography>
              </Box>
            </Box>
          )}

          <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <Typography color="text.secondary" sx={{ mb: '1rem' }} variant="body1">
              Verbruik LED verlichting per jaar
            </Typography>

            <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
              <Typography variant="body2">Energie in kWh</Typography>
              <Typography sx={{ color: 'primary.main' }} variant="body1">
                {energyUsage(quotation.energyUsagePerYear, 0)}
              </Typography>
            </Box>

            <Divider sx={{ color: 'text.secondary', margin: '5px 0' }} />

            <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
              <Typography variant="body2">Energie in euro</Typography>
              <Typography sx={{ color: 'primary.main' }} variant="body1">
                {currency(quotation.energyCostPerYear)}
              </Typography>
            </Box>

            <Divider sx={{ color: 'text.secondary', margin: '5px 0' }} />

            <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
              <Typography variant="body2">Onderhoud</Typography>
              <Typography sx={{ color: 'primary.main' }} variant="body1">
                {currency(0)}
              </Typography>
            </Box>
          </Box>

          {quotation.isNewLocation && quotation.paymentType === PaymentType.Laas && (
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', ml: '3rem' }}>
              <Typography color="text.secondary" sx={{ mb: '1rem' }} variant="body1">
                Light as a Service vergoeding excl. BTW
              </Typography>

              <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                <Typography variant="body2">Maandelijks</Typography>
                <Typography sx={{ color: 'primary.main' }} variant="body1">
                  {currency(quotation.totalMonthlyLaasChargeOverwrite ?? quotation.totalMonthlyLaasCharge)}
                </Typography>
              </Box>

              <Divider sx={{ color: 'text.secondary', margin: '5px 0' }} />
            </Box>
          )}
        </Box>

        {!quotation.isNewLocation && (
          <Card
            sx={{
              backgroundColor: 'primary.100',
              margin: '3rem .5rem',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ fontSize: '14.8px', fontWeight: '700', color: 'primary.dark', mb: '1rem' }}>
              Besparing met LED verlichting per jaar
            </Typography>
            <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
              <Typography variant="subtitle2">Energiebesparing in kWh per jaar</Typography>
              <Typography color="primary.dark" variant="subtitle2">
                {energyUsage(quotation.energySavingsPerYear, 0)}
              </Typography>
            </Box>

            <Divider sx={{ margin: '5px 0', borderBottom: '1px dashed rgba(19, 190, 55, 0.5)' }} />

            <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
              <Typography variant="subtitle2">Energiebesparing in Euro per jaar</Typography>
              <Typography color="primary.dark" variant="subtitle2">
                {currency(quotation.costSavingsOnEnergyPerYear)}
              </Typography>
            </Box>

            <Divider sx={{ margin: '5px 0', borderBottom: '1px dashed rgba(19, 190, 55, 0.5)' }} />

            <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
              <Typography variant="subtitle2">Besparing op onderhoud</Typography>
              <Typography color="primary.dark" variant="subtitle2">
                {currency(quotation.costSavingsOnMaintenancePerYear)}
              </Typography>
            </Box>

            <Divider sx={{ margin: '5px 0', borderBottom: '1px dashed rgba(19, 190, 55, 0.5)' }} />

            <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
              <Typography variant="subtitle2">Totale Jaarlijkse besparing</Typography>
              <Typography color="primary.dark" variant="subtitle2">
                {currency(quotation.totalCostSavingsPerYear)}
              </Typography>
            </Box>

            {quotation.paymentType === PaymentType.Laas && (
              <>
                <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                  <Typography variant="subtitle2">Maandelijkse besparing</Typography>
                  <Typography color="primary.dark" variant="subtitle2">
                    {currency(quotation.totalCostSavingsPerYear / MONTHS_PER_YEAR)}
                  </Typography>
                </Box>
                <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                  <Typography variant="subtitle2">Light as a Service vergoeding excl. BTW</Typography>
                  <Typography color="primary.dark" variant="subtitle2">
                    {currency(quotation.totalMonthlyLaasChargeOverwrite ?? quotation.totalMonthlyLaasCharge)}
                  </Typography>
                </Box>
              </>
            )}

            {quotation.paymentType === PaymentType.Full && (
              <>
                <Divider sx={{ margin: '5px 0', borderBottom: '1px dashed rgba(19, 190, 55, 0.5)' }} />

                <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                  <Typography variant="subtitle2">Oud verbruik in euro (per jaar)</Typography>
                  <Typography color="primary.dark" variant="subtitle2">
                    {currency(quotation.oldEnergyCostPerYear)}
                  </Typography>
                </Box>

                <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                  <Typography variant="subtitle2">Nieuw verbruik in euro (per jaar)</Typography>
                  <Typography color="primary.dark" variant="subtitle2">
                    {currency(quotation.energyCostPerYear)}
                  </Typography>
                </Box>
                <Divider sx={{ margin: '5px 0', borderBottom: '1px dashed rgba(19, 190, 55, 0.5)' }} />

                <Box color="text.secondary" sx={RowJustifyBetweenStyles}>
                  <Typography variant="subtitle2">Terugverdientijd</Typography>
                  <Typography color="primary.dark" variant="subtitle2">
                    {quotation.paybackTimeInYears && quotation?.paybackTimeInYears > 0
                      ? round(quotation.paybackTimeInYears, 1)
                      : '∞'}{' '}
                    jaar
                  </Typography>
                </Box>
              </>
            )}
          </Card>
        )}
        {quotation.paymentType === PaymentType.Laas && (
          <>
            <Box
              sx={{
                border: '1px solid rgba(19, 190, 55, 0.5)',
                borderColor: 'primary.main',
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
                mb: 3,
                borderRadius: 1,
              }}
            >
              <Typography color="primary.main" variant="h3">
                Direct maandelijks voordeel, zonder investering:
              </Typography>
              <Typography color="primary.dark" variant="h3">
                {currency(
                  quotation.totalCostSavingsPerYear / MONTHS_PER_YEAR -
                    (quotation.totalMonthlyLaasChargeOverwrite ?? quotation.totalMonthlyLaasCharge),
                )}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                p: 2,
                mx: 3,
              }}
            >
              <Box sx={TopBarStyles}>
                <Co2 style={{ fill: 'green' }} />
                <Typography sx={{ color: 'text.secondary', ml: 1 }} variant="body1">
                  {round(quotation.totalSavedCO2, 0)}&nbsp; kg CO2 besparing
                </Typography>
              </Box>

              <Box sx={TopBarStyles}>
                <Tree style={{ fill: 'primary.main' }} />
                <Typography sx={{ color: 'text.secondary', ml: 1 }} variant="body1">
                  {round(quotation.totalSavedTrees, 0)}&nbsp; bomen gecompenseerd
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
