import { CircularProgress } from '@mui/material';
import { FC } from 'react';

export type props = {
  size?: 'sm' | 'md' | 'lg';
  center?: boolean;
};

const getSize = (size: props['size']): number => {
  switch (size) {
    case 'sm':
      return 30;
    case 'lg':
      return 60;
    default:
      return 40;
  }
};

const Loader: FC<props> = ({ size, center, ...props }: props): React.ReactElement => {
  // const classes = useStyles({ center });

  return (
    <div>
      <CircularProgress {...props} size={getSize(size)} />
    </div>
  );
};

export default Loader;
