import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { SuccessMessages } from 'src/constants/messages';
import { GetQuotationDocument, useCloneQuotationLineItemMutation } from 'src/graphql/generated/hooks';
import { QuotationLineItemFragment } from 'src/graphql/generated/operations';
import useBreakpointDown from 'src/hooks/useBreakpointDown.hook';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';

interface Props {
  handleClose: () => unknown;
  quotationId: string;
  lineItem: QuotationLineItemFragment | undefined;
  open: boolean;
  quotationRoomId: string | undefined;
}

const CloneLineItemDialog = ({ handleClose, lineItem, open, quotationId }: Props): JSX.Element => {
  const mediumScreen = useBreakpointDown();

  const [cloneQuotationLineItem, { loading }] = useCloneQuotationLineItemMutation({
    refetchQueries: [{ query: GetQuotationDocument, variables: { input: { quotationId } } }],
    update(_, { errors, data }) {
      if (errors || !data) return;
      toast.success(SuccessMessages.LineItemDuplicated);
      handleClose();
    },
  });

  const handleClone = useCallback(async () => {
    if (!lineItem) return;

    cloneQuotationLineItem({ variables: { input: { quotationLineItemId: lineItem.id } } });
  }, [lineItem, cloneQuotationLineItem]);

  return (
    <Dialog fullScreen={mediumScreen} open={open} onClose={handleClose}>
      <DialogTitle>
        <CardTitle color="primary" title="Line item dupliceren" />
      </DialogTitle>
      <DialogContent>Weet je zeker dat je deze regel wil dupliceren?</DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <DefaultButton disabled={loading} id="cancel" sx={{ flexGrow: 1 }} onClick={handleClose}>
          Annuleren
        </DefaultButton>
        <LoadingButton color="primary" loading={loading} sx={{ flexGrow: 1 }} variant="contained" onClick={handleClone}>
          Dupliceren
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default CloneLineItemDialog;
