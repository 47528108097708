import { Box, Typography } from '@mui/material';

import { FilterStepComponentProps } from 'src/hooks/useFilterProgress';
import { currency } from 'src/utils/currency.helper';
import { rangeString } from 'src/utils/number.helper';

export default function SeriesDetails({
  title,
  remainingProductsAfterSelection,
}: FilterStepComponentProps): JSX.Element {
  const priceRange = rangeString(remainingProductsAfterSelection, 'price', {
    transformFn: currency,
    unknownDescription: '[onbekend]',
  });

  const wattageRange = rangeString(remainingProductsAfterSelection, (p) => p.customFields?.wattage, {
    unknownDescription: '[onbekend]',
  });
  const lightIntensityRange = rangeString(remainingProductsAfterSelection, (p) => p.customFields?.lightIntensity, {
    unknownDescription: '[onbekend]',
  });
  const warrantyRange = rangeString(remainingProductsAfterSelection, (p) => p.customFields?.warranty, {
    unknownDescription: '[onbekend]',
  });

  return (
    <Box my={1}>
      <Typography align="left" pl={2} variant="h4">
        {title}
      </Typography>
      <Box
        component="ul"
        sx={{
          textAlign: 'left',
          paddingLeft: 2,
          margin: '10px 0',
          listStyleType: 'circle',
          '& li': { height: '17px' },
        }}
      >
        <li>{priceRange} excl. BTW</li>
        {wattageRange && lightIntensityRange !== '-Infinity' ? (
          <li>
            {wattageRange !== '-Infinity' && <>{wattageRange} W</>}
            {wattageRange !== '-Infinity' && <> en </>}
            {lightIntensityRange !== '-Infinity' && <>{lightIntensityRange} lm</>}
          </li>
        ) : (
          wattageRange && <li>{wattageRange} W</li>
        )}
        {warrantyRange !== '-Infinity' && <li>{warrantyRange} jaar garantie</li>}
      </Box>
    </Box>
  );
}
