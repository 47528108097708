import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import {
  useNewProductsLazyQuery,
  useOldCategoryQuery,
  useOldProductsLazyQuery,
  useProductVariantsLazyQuery,
} from 'src/graphql/generated/hooks';
import { NewProductFragment, OldProductFragment, VariantFragment } from 'src/graphql/generated/operations';
import { FilterState, InitialFilter, useFilterProgress } from 'src/hooks/useFilterProgress';

import Loader from '../../Loader/Loader';
import ProductFilterOption from './components/ProductFilterOption/ProductFilterOption';
import StepHeading from './components/StepHeading/StepHeading';
import { filterConfig } from './productFilter.config';

interface Props {
  initialFilterChoices?: InitialFilter[];
  onChange?: (filterState: FilterState) => void;
  onFinished?: (
    filterState: FilterState,
    oldProduct: OldProductFragment,
    product: NewProductFragment,
    productVariant?: VariantFragment,
  ) => void;
}

/**
 * Component to start from an 'old product category' and present the user with simple filters until
 * only one product (variant) remains.
 *
 *  * The `onChange` function is called every time a change is made by the user
 *  * The `onFinished` function is called when a final product or product-variant has been selected
 *
 * 1. Starts by querying the oldCategory with its related old subcategories.
 * 2. When a subcategory has more than one 'old product', the user is presented with a list of old products
 * 3. All related products of the selected 'old product' are fetched, including their custom-fields.
 *    A set of filters compares the custom-fields in a fixed order. All custom-field filters are compared and
 *    provided to the user as options when their values differ. When the user chooses an option, only products
 *    where the current custom-field matches the chosen value will be kept. All others are discarded.
 *    This process walks the custom-fields until one product remains.
 *
 *    Example: first check if all products have the same `length` (if they are all undefined, they are the same).
 *    If multiple values for the `length` field are found, the user is presented with these options.
 * 4. When one product remains the product variants of that product are fetched. If there are multiple,
 *    the user is asked to pick the product variant in the same way as all earlier option sets.
 * 5. When the desired product and product option are known, the onFinished callback function is called with the
 *    set of FilterProgress steps that led to this result and the final product and optionally the product variant.
 */
export default function ProductFilter({ initialFilterChoices, onChange, onFinished }: Props): JSX.Element {
  // Filter state hook
  const [filterState, { toggle, setOldCategories, setOldProducts, setNewProducts, setProductVariants }] =
    useFilterProgress(filterConfig);

  const [isHydrating, setIsHydrating] = useState(initialFilterChoices !== undefined);

  // Step 0 query (old subcategories)
  const { data: oldCategoryData } = useOldCategoryQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => setOldCategories(d.oldCategory.children),
  });

  // Step 1 lazy query (old products)
  const [getOldProducts, { data: oldProductsData }] = useOldProductsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => setOldProducts(d.category.products),
  });

  // Step 2 lazy query (related new products - these new products need to be filtered until 1 remains)
  const [getNewProducts, { data: newProductsData }] = useNewProductsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => setNewProducts(d.oldProduct.relatedProducts),
  });

  // Step N+1 lazy query (variants of final product - pick one)
  const [getProductVariants, { data: productVariantsData }] = useProductVariantsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => setProductVariants(d.productVariants.variants),
  });

  // Replay initial choices (when in edit mode)
  const initialReplayStep = useRef(0);
  useEffect(() => {
    if (!initialFilterChoices) return; // This is a create line-item, not an edit

    // Check if already finished
    if (initialFilterChoices.length === initialReplayStep.current) return;

    // Check if the next step is available
    const nextReplayStep = initialFilterChoices[initialReplayStep.current];
    if (!nextReplayStep || nextReplayStep?.choice === undefined) return;

    // Check if all data is available to select the next step
    const currentStep = filterState.progress[initialReplayStep.current];
    if (!currentStep?.options) return; // options not yet loaded

    const nextStep = currentStep.options.find(
      (option) =>
        option.value === nextReplayStep?.choice &&
        (nextReplayStep.filter === initialReplayStep.current ||
          nextReplayStep.filter === currentStep.filter?.fieldName ||
          nextReplayStep.filter === 'productVariant'),
    );
    if (!nextStep?.onSelect) {
      if (nextStep === undefined) {
        toast.error('Eerder geselecteerde optie niet (meer) beschikbaar.');
      } else {
        toast.error('Oeps, er is iets misgegaan.');
      }
      initialReplayStep.current = initialFilterChoices.length; // Mark as done
      setIsHydrating(false);
      return;
    }

    nextStep.onSelect(nextReplayStep.choice); // Simulate click

    initialReplayStep.current++; // Mark step as selected
    if (initialFilterChoices.length === initialReplayStep.current) {
      setIsHydrating(false);
    }
  }, [initialFilterChoices, filterState]);

  // Simulate a selection (click) when only one option is available at the current step
  useEffect(() => {
    const currentStep = filterState.progress[filterState.progress.length - 1];
    if (currentStep.choice !== undefined || currentStep?.options?.length !== 1) return;
    currentStep.options[0].onSelect?.(currentStep.options[0].value);
  }, [filterState.progress]);

  // Query the products in the selected oldCategory (to show in step 1)
  useEffect(() => {
    if (!oldCategoryData || !filterState.progress[0]?.choice) return; // Unknown what to query
    if (oldProductsData?.category.id === filterState.progress[0]?.choice) return; // Already loaded
    getOldProducts({ variables: { input: { categoryId: filterState.progress[0].choice.toString() } } });
  }, [getOldProducts, filterState.progress, oldCategoryData, oldProductsData]);

  // Query the related 'new' products for the selected 'old' product in step 1, to start the actual product filtering
  useEffect(() => {
    if (!oldProductsData || !filterState.progress[1]?.choice) return; // Unknown what to query
    if (newProductsData?.oldProduct.id === filterState.progress[1]?.choice) return; // Already loaded
    getNewProducts({ variables: { input: { productId: Number(filterState.progress[1].choice) } } });
  }, [filterState.progress, getNewProducts, newProductsData, oldProductsData]);

  // Trigger the `onChange` function when `progressState` changes
  useEffect(() => {
    if (!filterState) return;
    onChange?.(filterState);
  }, [onChange, filterState]);

  // Fetch product variants if `isProductSelected` is toggled to true
  useEffect(() => {
    if (!filterState.isProductSelected) return;
    const finalProduct = filterState.progress[filterState.progress.length - 1].remainingProducts?.[0];
    if (productVariantsData?.productVariants.id === finalProduct?.id) return; // Already loaded
    if (!finalProduct) {
      Sentry.captureException('FilterState.isProductSelected, but no final product found', { extra: { filterState } });
    } else {
      getProductVariants({ variables: { input: { productId: finalProduct.id } } });
    }
  }, [filterState, getProductVariants, productVariantsData?.productVariants.id]);

  // Call `onFinished` when a single product has been selected
  useEffect(() => {
    if (!onFinished || !filterState.isFinished) return; // onFinished is not set as a Prop or no final product known yet
    const lastStep = filterState.progress[filterState.progress.length - 1];
    const oldProduct = oldProductsData?.category.products.find(
      (oldProduct) => oldProduct.id === filterState.progress[1].choice,
    );
    const finalProduct = lastStep.remainingProducts?.[0];
    const productVariant = lastStep.productVariant;
    if (!finalProduct) {
      Sentry.captureException('FilterState.isFinished, but no final product found', { extra: { filterState } });
    } else if (!oldProduct) {
      Sentry.captureException('FilterState.isFinished, but no old product found', { extra: { filterState } });
    } else {
      onFinished(filterState, oldProduct, finalProduct, productVariant);
    }
  }, [filterState, oldProductsData?.category.products, onFinished]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      {isHydrating ? (
        <Loader />
      ) : (
        Array.isArray(filterState.progress) &&
        filterState.progress.map((step, i) => (
          <Fragment key={`filter-s${i}`}>
            <StepHeading
              description={step.description ?? step.filter?.description}
              isExpanded={step.isExpanded}
              step={i}
              title={step.title}
              toggle={step.options?.length === 1 ? undefined : toggle(i)} // Only show toggle button if there is more than 1 option
            />
            {step.options ? (
              step.options.map((option, j) => (
                <ProductFilterOption
                  isHydrating={isHydrating}
                  isSelected={step.choice === option.value}
                  isStepExpanded={step.isExpanded}
                  key={`filter-s${i}-o${j}`}
                  option={option}
                />
              ))
            ) : (
              <Loader />
            )}
          </Fragment>
        ))
      )}
    </Box>
  );
}
