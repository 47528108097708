import { Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { GetQuotationQuery } from 'src/graphql/generated/operations';
import CardEditButton from 'src/pages/BackOffice/components/CardEditButton/CardEditButton';

import QuotationEditProjectLocatieDialog from './QuotationEditProjectLocatieDialog/QuotationEditProjectLocatieDialog';

interface Props {
  quotation?: GetQuotationQuery['quotation'];
  isEditable?: boolean;
}

const QuotationProjectLocatie = ({ quotation, isEditable }: Props): JSX.Element => {
  const [openEditProjectLocatie, setOpenEditProjectLocatie] = useState(false);

  const editQuotationProjectLocatie = useCallback(() => {
    setOpenEditProjectLocatie(true);
  }, []);

  if (!quotation) {
    return <Skeleton animation="wave" />;
  }

  return (
    <>
      <Tooltip placement="right" title="Hier kunt u de projectlocatie invullen!">
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={1}>
          {isEditable && <CardEditButton onClick={editQuotationProjectLocatie} />}
        </Grid>
      </Tooltip>
      <Grid container direction="column" item paddingRight={16} spacing={8} xs={7}>
        <Grid container direction="row" item spacing={0}>
          <Grid item>
            <Typography variant="h3"></Typography>
          </Grid>
          <Grid item>
            {quotation.projectLocatie &&
              quotation.projectLocatie.split('\n').map((p, i) => (
                <Typography color="text.secondary" key={`notes-${i}`} sx={{ pt: 0 }} variant="h4">
                  {p}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <QuotationEditProjectLocatieDialog
        open={openEditProjectLocatie}
        quotation={quotation}
        setOpen={setOpenEditProjectLocatie}
      />
    </>
  );
};

export default QuotationProjectLocatie;
