import TitleIcon from '@mui/icons-material/Title';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { SuccessMessages } from 'src/constants/messages';
import { useUpdateQuotationProjectLocatieMutation } from 'src/graphql/generated/hooks';
import { GetQuotationQuery } from 'src/graphql/generated/operations';
import { InputUpdateQuotationProjectLocatieDescription } from 'src/graphql/generated/schema';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';

interface Props {
  quotation: GetQuotationQuery['quotation'];
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const QuotationEditProjectLocatieDialog = ({ open, setOpen, quotation }: Props): JSX.Element => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<InputUpdateQuotationProjectLocatieDescription>({ mode: 'onChange' });

  const [updateQuotationProjectLocatie, { loading }] = useUpdateQuotationProjectLocatieMutation({
    update(_, { errors }) {
      if (errors) return toast.error(errors[0].message);
      toast.success(SuccessMessages.QuotationUpdated);
      setOpen(false);
    },
  });

  const handleSave = useCallback(
    ({ projectLocatie }: InputUpdateQuotationProjectLocatieDescription) => {
      if (!quotation.id) return;
      updateQuotationProjectLocatie({
        variables: {
          input: {
            quotationId: quotation.id,
            projectLocatie,
          },
        },
      });
    },
    [quotation.id, updateQuotationProjectLocatie],
  );

  return (
    <form id="quotation-edit-project-locatie" onSubmit={handleSubmit(handleSave)}>
      <Dialog fullScreen={mediumScreen} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <CardTitle color="primary" icon={<TitleIcon color="primary" />} title="Projectlocatie gegevens:" />
        </DialogTitle>

        <DialogContent>
          <Controller
            control={control}
            defaultValue={quotation.projectLocatie ?? ''}
            name="projectLocatie"
            render={({ field }) => (
              <TextField
                helperText="Vul de projectlocatie in"
                inputProps={{ maxLength: '180' }}
                label="Projectlocatie"
                multiline
                rows={4}
                sx={{ mt: 1, width: '100%' }}
                {...field}
              />
            )}
            rules={{ maxLength: 180 }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <DefaultButton disabled={loading} id="cancel" sx={{ flexGrow: 1 }} onClick={() => setOpen(false)}>
            Annuleren
          </DefaultButton>
          <LoadingButton
            color="primary"
            disabled={!isValid}
            form="quotation-edit-project-locatie"
            loading={loading}
            sx={{ flexGrow: 1 }}
            type="submit"
            variant="contained"
          >
            Opslaan
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default QuotationEditProjectLocatieDialog;
