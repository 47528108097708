import preval from 'preval.macro';

import { BUILD_TIMESTAMP } from '../constants/localState';

// store time at moment of build
const timestamp = preval`module.exports=new Date().getTime().toString();`;
const versionDate = preval`module.exports=()=>{a=new Date();p=(b)=>(b.toString().padStart(2,'0'));return p(a.getUTCDate())+'-'+p(a.getUTCMonth()+1)+'-'+a.getUTCFullYear();}`;
const version = import.meta.env.VITE_VERSION || versionDate;

// get previous build timestamp from storage
const prevTimestamp = localStorage.getItem(BUILD_TIMESTAMP);

// check if the same or DO_NOT_CHECK_TIMESTAMP (used for Cypress tests!)
// if no timestamp saved, it is probably the first load, so "same build"
const isSameBuild = !prevTimestamp || prevTimestamp === 'DO_NOT_CHECK_TIMESTAMP' || prevTimestamp === timestamp;

const isFirstLoad = !prevTimestamp;

// set build to the same value
localStorage.setItem(BUILD_TIMESTAMP, timestamp);

export { timestamp, version, isSameBuild, isFirstLoad };
