// Local storage (persisted)
export const BUILD_TIMESTAMP = 'build-timestamp';

// Local storage (clearable)
export const IS_SIGNED_IN = 'is-signed-in';
export const APP_THEME_MODE = 'app-theme-mode';
export const IS_DRAWER_MINIMIZED = 'is-drawer-minimized';
export const SIGN_IN_EMAIL = 'sign-in-email';
export const SHOULD_STORE_EMAIL = 'should-store-email';
export const AUTH_SIGN_OUT = 'check-sign-out';
export const AUTH_SIGN_IN = 'check-sign-in';
export const QUOTATION_LIST_FILTER = 'quotation-list-filter';
export const COMPANY_NAME_MODE = 'company-name-mode';
export const SHOULD_STORE_COMPANY_NAME = 'should-store-company-name';

// Session storage
export const ERROR_TRACE = 'error-trace';
