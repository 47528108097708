import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import ShowSendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import Settings from '@mui/icons-material/Settings';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SvgIconTypeMap,
  SxProps,
  Tooltip,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useCallback, useContext, useMemo } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { UserSettings } from 'src/config/UserSettings';
import { useAuth } from 'src/hooks/useAuth.hook';
import { Routes } from 'src/pages/Routes';

import MyProfileButton from './MyProfileButton';

type Route = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  name: string;
  to?: LinkProps['to'];
};

interface Props {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const mainRoutes: Route[] = [
  {
    icon: ShowChartOutlinedIcon,
    name: 'Dashboard',
    to: Routes.BackOfficeDashBoard,
  },
  {
    icon: AttachMoneyOutlinedIcon,

    name: 'Offertes',
    to: {
      pathname: Routes.BackOfficeQuotationList,
      state: 'reload',
    },
  },
  {
    icon: InsertEmoticonOutlinedIcon,
    name: 'Klanten',
    to: {
      pathname: Routes.BackOfficeContactList,
      state: 'reload',
    },
  },
];

const adminRoutes: Route[] = [
  {
    icon: ShowSendTwoToneIcon,
    name: 'Producten',
    to: Routes.BackOfficeProductList,
  },

  {
    icon: SupervisedUserCircleOutlinedIcon,
    name: 'Team',
    to: Routes.BackOfficeUserList,
  },
  {
    icon: Settings,
    name: 'Admin',
    to: Routes.BackOfficeAdminSettings,
  },
];

const totalRoutes: Route[] = [...mainRoutes, ...adminRoutes];

const selectedSx = (minimize: boolean): SxProps => {
  return {
    display: 'flex',
    borderRadius: 1,
    width: minimize ? '50px' : 'auto',
    height: minimize ? '50px' : 'auto',
    marginLeft: minimize ? '-10px' : 'auto',
    justifyContent: 'center',
    backgroundColor: minimize ? 'actions.450' : 'auto',
    '&.Mui-selected': {
      '& .MuiListItemIcon-root': {
        backgroundColor: 'primary.main',
        color: 'white',
        borderRadius: '100px',
        width: 'auto',
        height: 'auto',
        margin: minimize ? 'auto' : '0 16px 0 8px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'auto',
      },
      '& .MuiListItemText-root': {
        color: 'white',
      },
      backgroundColor: 'actions.300',
      '&:hover': {
        backgroundColor: 'actions.200',
      },
    },
    '&:hover': {
      backgroundColor: minimize ? 'actions.300' : 'actions.400',
    },
  };
};
const listItemSx: SxProps = {
  '& *': {
    fontSize: '14px',
    color: 'rgba(255,255,255,0.8)',
    '& .MuiListItemIcon-root': {
      marginLeft: '16px',
    },
  },
};

const NavItems = ({ setMobileOpen }: Props): JSX.Element => {
  const [{ isDrawerMinimized }] = useContext(UserSettings);

  const [auth] = useAuth();

  const handleNavLinkClick = useCallback(() => {
    setMobileOpen(false);
  }, [setMobileOpen]);

  const routes = useMemo(() => (auth?.user?.role === 'admin' ? totalRoutes : mainRoutes), [auth?.user?.role]);

  return (
    <List sx={{ margin: '16px' }}>
      {auth?.user ? <MyProfileButton setMobileOpen={setMobileOpen} /> : <Skeleton animation="wave" />}

      {routes.map((mainRoute: Route) => (
        <ConditionalWrapper
          condition={isDrawerMinimized}
          key={mainRoute.name}
          wrapper={(children) => (
            <Tooltip placement="right" title={mainRoute.name}>
              {children}
            </Tooltip>
          )}
        >
          <ListItemButton
            activeClassName="Mui-selected"
            component={NavLink}
            disableGutters
            key={mainRoute.name}
            sx={selectedSx(isDrawerMinimized)}
            to={mainRoute.to ?? ''}
            onClick={handleNavLinkClick}
          >
            <ListItemIcon
              sx={{ display: 'flex', justifyContent: 'center', marginLeft: isDrawerMinimized ? '0' : '-8px' }}
            >
              <mainRoute.icon />
            </ListItemIcon>
            {!isDrawerMinimized && <ListItemText primary={mainRoute.name} sx={listItemSx} />}
          </ListItemButton>
        </ConditionalWrapper>
      ))}
    </List>
  );
};

export default NavItems;
