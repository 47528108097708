import { Box, Divider, FormLabel, MenuItem, TextField } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormReset, UseFormWatch } from 'react-hook-form';

import { CreateContactFormInput } from 'components/Quotation/components/QuotationContact/QuotationCreateContactDialog/QuotationCreateContactDialog';
import { provincesBE, provincesNL } from 'src/constants/provinces';
import { GetContactQuery, KvkCompanyFieldsFragment } from 'src/graphql/generated/operations';

import { CreateCocContactFormInput } from '../../CreateContact/CreateContact';
import { UpdateContactFormInput } from './ContactCocForm';

enum ADDRESS_TYPE {
  SHIPPING = 'shippingAddress',
  BILLING = 'billingAddress',
}

interface Props {
  prefix: 'shipping' | 'billing';
  control: Control<CreateCocContactFormInput>;
  errors:
    | FieldErrors<CreateCocContactFormInput>
    | FieldErrors<CreateContactFormInput>
    | FieldErrors<UpdateContactFormInput>;
  company?: KvkCompanyFieldsFragment;
  contactData?: GetContactQuery;
  reset:
    | UseFormReset<CreateCocContactFormInput>
    | UseFormReset<CreateContactFormInput>
    | UseFormReset<UpdateContactFormInput>;
  getValues:
    | UseFormGetValues<CreateCocContactFormInput>
    | UseFormGetValues<CreateContactFormInput>
    | UseFormGetValues<UpdateContactFormInput>;
  disabled: boolean;
  watch:
    | UseFormWatch<CreateCocContactFormInput>
    | UseFormWatch<CreateContactFormInput>
    | UseFormWatch<UpdateContactFormInput>;
}

const ADDRESS_FIELDS: { [key: string]: ADDRESS_TYPE } = {
  shipping: ADDRESS_TYPE.SHIPPING,
  billing: ADDRESS_TYPE.BILLING,
};

const getKeyValue =
  <T extends Record<string, unknown>, U extends keyof T>(obj: T) =>
  (key: U) =>
    obj[key];

const generateCompanyObject = (
  company: KvkCompanyFieldsFragment,
  getValues:
    | UseFormGetValues<CreateCocContactFormInput>
    | UseFormGetValues<CreateContactFormInput>
    | UseFormGetValues<UpdateContactFormInput>,
  contactData?: GetContactQuery,
) => {
  if (company.streetName) {
    const { firstName, lastName } = getValues() as CreateCocContactFormInput;
    return {
      address: `${company.streetName ?? ''}${company.houseNumber ? ` ${company.houseNumber}` : ''}${
        company.houseNumberAddition ? ` ${company.houseNumberAddition}` : ''
      }`,
      zipcode: company.postalCode ?? '',
      city: company.place ?? '',
      country: 'Nederland',
      stateOrProvince: '',
      firstName: contactData?.contact.firstName ?? firstName,
      lastName: contactData?.contact.lastName ?? lastName,
      companyName: company.tradeName ?? '',
      cocNumber: company?.kvkNumber ?? contactData?.contact.cocNumber ?? '',
      /* exactId: contactData?.contact.exactId ?? exactId, */
    };
  }
  return {};
};

const generateData = (
  prefix: string,
  {
    address = '',
    zipcode = '',
    city = '',
    country = '',
    stateOrProvince = '',
    firstName = '',
    lastName = '',
    companyName = '',
    cocNumber = '',
    exactId = '',
  },
) => {
  return {
    [`${prefix}Address`]: address,
    [`${prefix}Zipcode`]: zipcode,
    [`${prefix}City`]: city,
    [`${prefix}StateOrProvince`]: stateOrProvince,
    [`${prefix}Country`]: country,
    [`${prefix}FirstName`]: firstName,
    [`${prefix}LastName`]: lastName,
    companyName,
    cocNumber,
    exactId,
  };
};

export default function ContactCocFormAddressFields({
  control,
  errors,
  prefix,
  company,
  reset,
  getValues,
  disabled,
  contactData,
  watch,
}: Props): JSX.Element {
  // Whenever the `company` prop changes, we must force a rerender of the component with the new default values
  // initial state object (based on current selected company)
  const initialState = useMemo(() => {
    // If manually set to a company, use company data

    return company
      ? generateData(prefix, generateCompanyObject(company, getValues, contactData))
      : // If not, prefer existing contact data
      contactData
      ? generateData(prefix, {
          address: contactData.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.address1,
          zipcode: contactData.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.postalCode,
          city: contactData.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.city,
          country: contactData.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.country,
          firstName: contactData.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.firstName,
          lastName: contactData.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.lastName,
          stateOrProvince: contactData.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.stateOrProvince,
          companyName: contactData?.contact.companyName ?? '',
          cocNumber: contactData?.contact.cocNumber ?? '',
          exactId: contactData?.contact.exactId ?? '',
        })
      : // If there is no existing contact data, use empty state
        generateData(prefix, {});
  }, [company, prefix, contactData, getValues]);

  // reset current form state to initialState of currently selected company
  useEffect(() => {
    if (!company && !contactData) {
      return;
    }
    reset({
      ...getValues(),
      ...initialState,
    });
  }, [initialState, reset, getValues, prefix, company, contactData]);

  const stateOrProvinceIsEmpty =
    company && !(watch as UseFormWatch<CreateCocContactFormInput>)(`${prefix}StateOrProvince`);

  return (
    <>
      <Controller
        control={control}
        defaultValue=""
        name={`${prefix}Address`}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            error={errors[`${prefix}Address`] !== undefined}
            fullWidth
            helperText={errors[`${prefix}Address`] && 'Vul een adres in'}
            label="Adres"
            type="text"
            variant="standard"
            {...field}
            sx={{
              mt: 2,
            }}
          />
        )}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        defaultValue=""
        name={`${prefix}Zipcode`}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            error={errors[`${prefix}Zipcode`] !== undefined}
            fullWidth
            helperText={errors[`${prefix}Zipcode`] && 'Vul een postcode in'}
            label="Postcode"
            sx={{
              mt: 2,
            }}
            type="text"
            {...field}
            variant="standard"
          />
        )}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        defaultValue=""
        name={`${prefix}City`}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            error={errors[`${prefix}City`] !== undefined}
            fullWidth
            helperText={errors[`${prefix}City`] && 'Vul een plaats in'}
            label="Plaats"
            type="text"
            variant="standard"
            {...field}
            sx={{
              mt: 2,
            }}
          />
        )}
        rules={{ required: true }}
      />

      <Controller
        control={control}
        defaultValue={contactData?.contact[getKeyValue(ADDRESS_FIELDS)(prefix)]?.stateOrProvince || ''}
        name={`${prefix}StateOrProvince`}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            error={errors[`${prefix}StateOrProvince`] !== undefined || stateOrProvinceIsEmpty}
            fullWidth
            helperText={(errors[`${prefix}StateOrProvince`] || stateOrProvinceIsEmpty) && 'Vul een provincie in'}
            label="Provincie"
            select
            sx={{
              mt: 2,
            }}
            variant="standard"
            {...field}
          >
            <MenuItem disabled value={'Nederland'}>
              Nederland
            </MenuItem>
            {provincesNL.map((province) => (
              <MenuItem key={province} value={province}>
                {province}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem disabled value={'België'}>
              België
            </MenuItem>

            {provincesBE.map((province) => (
              <MenuItem key={province} value={province}>
                {province}
              </MenuItem>
            ))}
          </TextField>
        )}
        rules={{ required: true }}
      />

      <Controller
        control={control}
        defaultValue=""
        name={`${prefix}Country`}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            error={errors[`${prefix}Country`] !== undefined}
            fullWidth
            helperText={errors[`${prefix}Country`] && 'Vul een land in'}
            label="Land"
            type="text"
            variant="standard"
            {...field}
            sx={{
              mt: 2,
            }}
          />
        )}
        rules={{ required: true }}
      />

      <FormLabel sx={{ mt: 2 }}>Geadresseerd aan</FormLabel>
      <Box sx={{ display: 'flex' }}>
        <Controller
          control={control}
          defaultValue=""
          name={`${prefix}FirstName`}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              error={errors[`${prefix}FirstName`] !== undefined}
              helperText={errors[`${prefix}FirstName`] && 'Vul een voornaam'}
              label="Voornaam"
              type="text"
              variant="standard"
              {...field}
              sx={{
                mt: 2,
                width: '50%',
              }}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          defaultValue=""
          name={`${prefix}LastName`}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              error={errors[`${prefix}LastName`] !== undefined}
              helperText={errors[`${prefix}LastName`] && 'Vul een achternaam in'}
              label="Achternaam"
              type="text"
              variant="standard"
              {...field}
              sx={{
                mt: 2,

                ml: 1,
                width: '50%',
              }}
            />
          )}
          rules={{ required: true }}
        />
      </Box>
    </>
  );
}
