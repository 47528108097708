import { Box, Typography } from '@mui/material';

import { FilterStepComponentProps } from 'src/hooks/useFilterProgress';
import LeafIcon from 'src/static/productFilter/retrofit/LeafIcon';

export default function RetrofitFalse({ title }: FilterStepComponentProps): JSX.Element {
  return (
    <Box>
      <Typography
        color="secondary.contrastText"
        component="div"
        sx={{
          position: 'absolute',
          left: 0,
          top: '1rem',
          borderRadius: '0 3px 3px 0',
          py: '0.5rem',
          px: '1.25rem',
          bgcolor: 'secondary.main',
          fontSize: '10px',
        }}
      >
        Beste keuze
      </Typography>
      <Box>
        <LeafIcon color="secondary" fontSize="small" sx={{ mx: 0.5 }} />
        <LeafIcon color="secondary" fontSize="small" sx={{ mx: 0.5 }} />
        <LeafIcon color="secondary" fontSize="small" sx={{ mx: 0.5 }} />
      </Box>
      <Typography variant="h4">{title}</Typography>
    </Box>
  );
}
