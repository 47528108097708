import MenuIcon from '@mui/icons-material/Menu';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { AppBar, Box, Drawer, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getIsDrawerMinimized, setIsDrawerMinimized, UserSettings } from 'src/config/UserSettings';

import NavSidebar from './NavSidebar';

export const DRAWER_WIDTH = 200;
export const MINIMIZED_DRAWER_WIDTH = 62;

export default function NavLayout(): JSX.Element {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [userSettings, setUserSettings] = useContext(UserSettings);

  const [mobileOpen, setMobileOpen] = useState(false); // TODO: rename to `drawerOpen`

  const handleToggleMinimized = useCallback(() => {
    setUserSettings((settings) => ({ ...settings, isDrawerMinimized: !userSettings.isDrawerMinimized }));
    setIsDrawerMinimized(!userSettings.isDrawerMinimized);
  }, [setUserSettings, userSettings.isDrawerMinimized]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((isMobileOpen) => !isMobileOpen);
  }, []);

  // Disable minimized drawer on mobile (without persisting to localStorage)
  useEffect(() => {
    if (mediumScreen) {
      setUserSettings((settings) => ({ ...settings, isDrawerMinimized: false }));
    } else {
      setUserSettings((settings) => ({ ...settings, isDrawerMinimized: getIsDrawerMinimized() }));
    }
  }, [mediumScreen, setUserSettings]);

  return (
    <Box
      aria-label="Main navigation"
      component="nav"
      sx={{
        width: { md: userSettings.isDrawerMinimized ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH },
        flexShrink: { sm: 0 },
        transition: '0.2s linear',
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            sx={{ mr: 2, display: { md: 'none' } }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* MOBILE DRAWER */}
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={mobileOpen}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
        }}
        variant="temporary"
        onClose={handleDrawerToggle}
      >
        <NavSidebar setMobileOpen={setMobileOpen} />
      </Drawer>
      {/* DESKTOP DRAWER */}
      <Drawer
        open
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: userSettings.isDrawerMinimized ? MINIMIZED_DRAWER_WIDTH : DRAWER_WIDTH,
            overflowX: 'hidden',
            flexShrink: 0,
            whiteSpace: 'nowrap',
            transition: '0.2s linear',
          },
        }}
        variant="permanent"
      >
        <NavSidebar setMobileOpen={setMobileOpen} />

        <IconButton
          sx={{
            color: 'white',
            width: 40,
            height: 40,
            alignSelf: userSettings.isDrawerMinimized ? 'center' : 'start',
            marginLeft: userSettings.isDrawerMinimized ? 0 : 8,

            backgroundColor: 'actions.400',
            ':hover': {
              backgroundColor: 'actions.300',
            },
            bottom: 26,
            right: userSettings.isDrawerMinimized ? 0 : 26,
            transform: 'rotate(90deg)',
          }}
          onClick={handleToggleMinimized}
        >
          {userSettings.isDrawerMinimized ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
        </IconButton>
      </Drawer>
    </Box>
  );
}
