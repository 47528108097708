import { Grid, Skeleton, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { GetQuotationQuery } from 'src/graphql/generated/operations';
import CardEditButton from 'src/pages/BackOffice/components/CardEditButton/CardEditButton';

import QuotationEditReferenceDialog from './QuotationEditReferenceDialog/QuotationEditReferenceDialog';

interface Props {
  quotation?: GetQuotationQuery['quotation'];
  isEditable?: boolean;
}

const QuotationReference = ({ quotation, isEditable }: Props): JSX.Element => {
  const [openEditReference, setOpenEditReference] = useState(false);

  const editQuotationReference = useCallback(() => {
    setOpenEditReference(true);
  }, []);

  if (!quotation) {
    return <Skeleton animation="wave" />;
  }

  return (
    <>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={1}>
        {isEditable && <CardEditButton onClick={editQuotationReference} />}
      </Grid>
      <Grid container direction="column" item paddingRight={1} spacing={2} xs={7}>
        <Grid container direction="row" item spacing={1} xs={1}>
          <Grid item>
            <Typography variant="h1">Offerte {quotation.reference}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          {quotation.description &&
            quotation.description.split('\n').map((p, i) => (
              <Typography color="text.secondary" key={`notes-${i}`} sx={{ pt: 0 }} variant="body2">
                {p}
              </Typography>
            ))}
        </Grid>
      </Grid>
      <QuotationEditReferenceDialog open={openEditReference} quotation={quotation} setOpen={setOpenEditReference} />
    </>
  );
};

export default QuotationReference;
