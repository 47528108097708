import { Box } from '@mui/material';

import { PartialBoolean } from 'src/hooks/useFilterProgress';

import EiaCheckIcon from './EiaCheckIcon';

interface Props {
  isEligibleForEia: Exclude<PartialBoolean, false>;
}

const EiaCheck: React.FC<Props> = ({ isEligibleForEia }) => (
  <Box sx={{ height: '1.5rem', width: '7rem', position: 'absolute', right: '5px', top: '5px' }}>
    <EiaCheckIcon color={isEligibleForEia === true ? 'primary' : undefined} fontSize="inherit" sx={{ mx: 0.5 }} />
    <span>EIA geschikt</span>
  </Box>
);

export default EiaCheck;
