import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateContactMutation, useGetQuotationLazyQuery } from 'src/graphql/generated/hooks';
import { InputCreateContact } from 'src/graphql/generated/schema';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';
import ContactBaseFormFields from 'src/pages/BackOffice/Contacts/components/ContactBaseForm/ContactBaseFormFields';
import ContactCocFormFields from 'src/pages/BackOffice/Contacts/components/ContactCocForm/ContactCocFormFields';

interface Props {
  quotationId: string;
  closeCreateContactDialog: () => unknown;
  createQuotationContactDialog: boolean;
}

export interface CreateContactFormInput {
  billingAddress: string;
  billingCity: string;
  billingCountry: string;
  billingFirstName: string;
  billingLastName: string;
  billingStateOrProvince: string;
  billingZipcode: string;
  firstName: string;
  lastName: string;
  notes: string;
  phone: string;
  quotationEmail: string;
  shippingAddress: string;
  shippingCity: string;
  shippingCountry: string;
  shippingFirstName: string;
  shippingLastName: string;
  shippingStateOrProvince: string;
  shippingZipcode: string;
  cocNumber: string;
  companyName: string;
  exactId: string;
}

const QuotationCreateContactDialog = ({
  quotationId,
  closeCreateContactDialog,
  createQuotationContactDialog,
}: Props): JSX.Element => {
  const [sameBillingAddress, setSameBillingAddress] = useState(true);

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [createContact, { data: createContactData, loading: loadingCreateContact }] = useCreateContactMutation({
    errorPolicy: 'all',
  });

  const [getQuotation] = useGetQuotationLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm<CreateContactFormInput>({
    mode: 'onChange',
  });

  // Create New User, add to Quotation and close Dialog when successful
  const create = useCallback(
    async (formInput: CreateContactFormInput) => {
      const input: InputCreateContact = {
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        notes: formInput.notes,
        phone: formInput.phone,
        quotationEmail: formInput.quotationEmail,
        cocNumber: formInput.cocNumber,
        companyName: formInput.companyName,
        exactId: formInput.exactId,
        billingAddress: {
          address1: formInput.billingAddress ?? formInput.shippingAddress,
          city: formInput.billingCity ?? formInput.shippingCity,
          country: formInput.billingCountry ?? formInput.shippingCountry,
          postalCode: formInput.billingZipcode ?? formInput.shippingZipcode,
          firstName: formInput.billingFirstName ?? formInput.shippingFirstName,
          lastName: formInput.billingLastName ?? formInput.shippingLastName,
          stateOrProvince: formInput.billingStateOrProvince ?? formInput.shippingStateOrProvince,
        },

        hasDifferentBillingAndShippingAddress: !sameBillingAddress,
        ...(sameBillingAddress
          ? {}
          : {
              shippingAddress: {
                address1: formInput.shippingAddress,
                city: formInput.shippingCity,
                country: formInput.shippingCountry,
                postalCode: formInput.shippingZipcode,
                firstName: formInput.shippingFirstName,
                stateOrProvince: formInput.shippingStateOrProvince,
                lastName: formInput.shippingLastName,
              },
            }),
        quotationId,
      };
      await createContact({ variables: { input } });
    },
    [createContact, quotationId, sameBillingAddress],
  );

  // When cancel is pressed purposely, the Create Contact Form resets
  const cancelCreateContactDialog = useCallback(() => {
    reset();
    closeCreateContactDialog();
  }, [closeCreateContactDialog, reset]);

  // Check if creation was successful and then close Dialog
  useEffect(() => {
    if (createContactData) {
      // Upon creation of the new contact, we need to refetch the Quotation data to update the parent components.
      // Unfortunately, since CreateContact does not return a Quotation, this does not happen automatically.
      // This is therefore purely to update other components - we do not use the Quotation data in this component.
      getQuotation({ variables: { input: { quotationId } } });
      closeCreateContactDialog();
    }
  }, [closeCreateContactDialog, createContactData, getQuotation, quotationId]);

  return (
    <Dialog
      fullScreen={mediumScreen}
      fullWidth
      maxWidth="sm"
      open={createQuotationContactDialog}
      onClose={closeCreateContactDialog}
    >
      <DialogTitle>
        <CardTitle color="primary" icon={<PersonAddIcon color="primary" />} title="Contact toevoegen" />
      </DialogTitle>
      <DialogContent>
        <form id="createQuotationContactForm" onSubmit={handleSubmit(create)}>
          <ContactBaseFormFields control={control} errors={errors} isEditable={true} />
          <ContactCocFormFields
            control={control}
            disabled={false}
            errors={errors}
            getValues={getValues}
            loadingContactProcess={loadingCreateContact}
            reset={reset}
            sameBillingAddress={sameBillingAddress}
            setSameBillingAddress={setSameBillingAddress}
            watch={watch}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <DefaultButton
          disabled={loadingCreateContact}
          id="cancelCreateContact"
          sx={{
            flexGrow: 1,
          }}
          onClick={cancelCreateContactDialog}
        >
          Annuleren
        </DefaultButton>
        <LoadingButton
          color="primary"
          disabled={!isValid}
          form="createQuotationContactForm"
          loading={loadingCreateContact}
          sx={{
            flexGrow: 1,
          }}
          type="submit"
          variant="contained"
        >
          Opslaan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default QuotationCreateContactDialog;
