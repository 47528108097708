import SendIcon from '@mui/icons-material/Send';
import { Autocomplete, Checkbox, debounce, FormControlLabel, styled, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormReset, UseFormWatch } from 'react-hook-form';
import toast from 'react-hot-toast';

import { CreateContactFormInput } from 'components/Quotation/components/QuotationContact/QuotationCreateContactDialog/QuotationCreateContactDialog';
import { useDetailedKvkSearchLazyQuery, useKvkSearchLazyQuery } from 'src/graphql/generated/hooks';
import { GetContactQuery, KvkCompanyFieldsFragment } from 'src/graphql/generated/operations';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';

import { CreateCocContactFormInput } from '../../CreateContact/CreateContact';
import { UpdateContactFormInput } from './ContactCocForm';
import ContactCocFormAddressFields from './ContactCocFormAddressFields';

const PREFIX = 'ContactCocFormFields';

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  content: `${PREFIX}-content`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.wrapper}`]: {
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  '& :last-child': {
    marginBottom: 0,
  },
}));

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  disabled: boolean;
  loadingContactProcess?: boolean;
  sameBillingAddress: boolean;
  getValues:
    | UseFormGetValues<UpdateContactFormInput>
    | UseFormGetValues<CreateContactFormInput>
    | UseFormGetValues<CreateCocContactFormInput>;
  setSameBillingAddress: Dispatch<SetStateAction<boolean>>;
  contactData?: GetContactQuery;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FieldErrors<any>;
  reset:
    | UseFormReset<UpdateContactFormInput>
    | UseFormReset<CreateContactFormInput>
    | UseFormReset<CreateCocContactFormInput>;
  watch:
    | UseFormWatch<UpdateContactFormInput>
    | UseFormWatch<CreateContactFormInput>
    | UseFormWatch<CreateCocContactFormInput>;
}

const hasAddress = ({ streetName, houseNumber, postalCode, place }: KvkCompanyFieldsFragment): boolean =>
  Boolean(streetName && houseNumber && postalCode && place);

const ContactCocFormFields = ({
  control,
  disabled,
  setSameBillingAddress,
  sameBillingAddress,
  getValues,
  errors,
  contactData,
  reset,
  watch,
}: Props): JSX.Element => {
  const [getCompany, { data: companiesData, loading, error: getCompanyError }] = useKvkSearchLazyQuery();
  const [getDetailed, { data: detailedData, loading: loadingDetailed }] = useDetailedKvkSearchLazyQuery();

  const [search, setSearch] = useState<string>('');
  const [company, setCompany] = useState<KvkCompanyFieldsFragment>();
  const [showError, setShowError] = useState(false);

  const handleSameBillingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameBillingAddress(event.target.checked);
    reset({ ...getValues() });
  };

  const debouncedGetCompany = useMemo(() => {
    return debounce(getCompany, 300);
  }, [getCompany]);

  useEffect(() => {
    if (!search || search.length > 32) return;
    if (company && `${company.tradeName} - ${company.kvkNumber}` === search) return;
    debouncedGetCompany({ variables: { input: { search } } });
  }, [search, debouncedGetCompany, company]);

  useEffect(() => {
    if (getCompanyError) {
      toast.error(getCompanyError.message);
    }
  }, [getCompanyError]);

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
      if (companiesData?.kvkSearch.some((x) => `${x.tradeName} - ${x.kvkNumber}` === newInputValue)) return;

      if (newInputValue.length > 32) {
        setShowError(true);
      } else {
        setSearch(newInputValue);
        setShowError(false);
      }
    },
    [companiesData?.kvkSearch],
  );

  useEffect(() => {
    if (!search || search.length > 32) return;
    if (company && `${company.tradeName} - ${company.kvkNumber}` === search) return;
    debouncedGetCompany({ variables: { input: { search } } });
  }, [search, debouncedGetCompany, company]);

  // Fill the result of the detailed KvK search
  useEffect(() => {
    if (!detailedData?.detailedKvkSearch) return;
    if (hasAddress(detailedData.detailedKvkSearch)) {
      setCompany(detailedData.detailedKvkSearch);
    } else if (
      detailedData.detailedKvkSearch.streetName ||
      detailedData.detailedKvkSearch.houseNumber ||
      detailedData.detailedKvkSearch.postalCode ||
      detailedData.detailedKvkSearch.place
    ) {
      toast('Incomplete adresgegevens gevonden', { icon: '⚠️' });
      setCompany(detailedData.detailedKvkSearch);
    } else {
      toast.error('Geen adresgegevens gevonden');
    }
  }, [detailedData?.detailedKvkSearch]);

  return (
    <Root className={classes.root}>
      <Autocomplete
        disabled={disabled}
        getOptionLabel={(option) => `${option.tradeName} - ${option.kvkNumber}`}
        loading={loading || loadingDetailed}
        loadingText="Zoeken..."
        noOptionsText="Typ om te zoeken zoeken"
        options={companiesData?.kvkSearch ?? []}
        renderInput={(params) => (
          <>
            {showError && (
              <Typography sx={{ color: 'red' }} variant="body1">
                De zoekterm mag maximaal 32 karakters lang zijn.
              </Typography>
            )}
            <TextField
              {...params}
              error={showError}
              label="Zoek in het KvK-register"
              placeholder="Zoek op bedrijfsnaam of kvk nummer"
            />
          </>
        )}
        renderOption={(props, option) => (
          <li {...props} key={`${option.tradeName}-${option.kvkNumber}-${option.type}`}>
            {option.tradeName} - {option.kvkNumber}
          </li>
        )}
        sx={{ mt: 2 }}
        onChange={(event, newValue) => {
          if (!newValue) return;
          setShowError(false);
          // Use the direct `zoeken` result if all address fields are known
          if (hasAddress(newValue)) {
            return setCompany(newValue);
          }
          // Trigger an extra `basisprofiel/hoofdvestiging` search
          getDetailed({ variables: { input: { kvkNumber: newValue.kvkNumber } } });
        }}
        onInputChange={handleInputChange}
      />

      <div className={classes.wrapper}>
        <Controller
          control={control}
          defaultValue={company?.tradeName ?? ''}
          name={'companyName'}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              error={errors['companyName'] !== undefined}
              fullWidth
              helperText={errors['companyName'] && 'Vul een bedrijfsnaam in'}
              label="Bedrijfsnaam"
              sx={{
                mt: 2,
              }}
              type="text"
              {...field}
              variant="standard"
            />
          )}
          rules={{ required: true }}
        />

        <Controller
          control={control}
          defaultValue={company?.kvkNumber ?? contactData?.contact.cocNumber ?? ''}
          name={'cocNumber'}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              error={errors['cocNumber'] !== undefined}
              fullWidth
              helperText={errors['cocNumber'] && 'Vul een Kvk nummer in'}
              label="KVK nummer"
              sx={{
                mt: 2,
              }}
              type="text"
              variant="standard"
              {...field}
            />
          )}
          rules={{ required: true }}
        />

        <Controller
          control={control}
          defaultValue={company?.kvkNumber ?? contactData?.contact.exactId ?? ''}
          name={'exactId'}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              error={errors['exactId'] !== undefined}
              fullWidth
              helperText={errors['exactId'] && 'Vul exact debiteurennummer in'}
              label="Exact debiteurennummer"
              sx={{
                mt: 2,
              }}
              type="text"
              variant="standard"
              {...field}
            />
          )}
          rules={{ required: true }}
        />
      </div>

      <div className={classes.wrapper}>
        <CardTitle color="primary" icon={<SendIcon color="primary" />} title={'Factuuradres'} />
        <ContactCocFormAddressFields
          company={company}
          contactData={contactData}
          control={control}
          disabled={disabled}
          errors={errors}
          getValues={getValues}
          prefix={'billing'}
          reset={reset}
          watch={watch}
        />
      </div>
      <div className={classes.wrapper}>
        <CardTitle color="primary" icon={<SendIcon color="primary" />} title={'Verzendadres'} />
        <FormControlLabel
          control={
            <Checkbox
              checked={sameBillingAddress}
              disabled={disabled}
              name="same"
              onChange={handleSameBillingAddress}
            />
          }
          label="Zelfde als factuuradres"
        />

        {!sameBillingAddress && (
          <ContactCocFormAddressFields
            company={company}
            contactData={contactData}
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            prefix={'shipping'}
            reset={reset}
            watch={watch}
          />
        )}
      </div>
    </Root>
  );
};

export default ContactCocFormFields;
