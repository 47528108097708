import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMove from '@mui/icons-material/DriveFileMove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { QuotationLineItemFragment, QuotationRoomFieldsFragment } from 'src/graphql/generated/operations';
import { PaymentType } from 'src/graphql/generated/schema';
import { ReactComponent as RoomIcon } from 'src/static/add_room.svg';

import LineItem from './LineItem';

interface Props {
  room: QuotationRoomFieldsFragment;
  numberOfRooms: number;
  isEditable?: boolean;
  setOpenUpdateRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRoomProp: React.Dispatch<React.SetStateAction<QuotationRoomFieldsFragment | undefined>>;
  setOpenDeleteListItemModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUpdateListItemModal: React.Dispatch<React.SetStateAction<string>>;
  setLineItem: React.Dispatch<React.SetStateAction<QuotationLineItemFragment | undefined>>;
  setRoomImgDialog: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOpenMoveListItemToNewRoomModal: React.Dispatch<React.SetStateAction<string>>;
  setOpenMoveRoomUpOrDownModal: React.Dispatch<React.SetStateAction<string>>;
  setOpenCloneListItemModal: React.Dispatch<React.SetStateAction<string>>;
}

const RoomListItem = ({
  room,
  numberOfRooms,
  isEditable,
  setOpenUpdateRoomModal,
  setOpenDeleteRoomModal,
  setRoomProp,
  setLineItem,
  setOpenUpdateListItemModal,
  setOpenDeleteListItemModal,
  setOpenMoveListItemToNewRoomModal,
  setOpenMoveRoomUpOrDownModal,
  setOpenCloneListItemModal,
  setRoomImgDialog,
}: Props): JSX.Element => {
  const [roomAnchorEl, setRoomAnchorEl] = useState<null | HTMLElement>(null);
  const openRoom = Boolean(roomAnchorEl);

  const handleClickRoom = (event: React.MouseEvent<HTMLElement>) => {
    setRoomAnchorEl(event.currentTarget);
    setRoomProp(room);
  };
  const handleCloseRoom = () => {
    setRoomAnchorEl(null);
  };
  const handleMoveRoomUpOrDownModal = () => {
    setOpenMoveListItemToNewRoomModal(room.id);
  };
  const handleAddNewLineItem = useCallback(() => {
    setLineItem(undefined);
    setOpenUpdateListItemModal(room.id);
  }, [room.id, setLineItem, setOpenUpdateListItemModal]);

  const days = useMemo(() => room.powerUsageDays?.filter((day) => day).length, [room]);
  const hours = useMemo(() => room.powerUsageHours ?? undefined, [room]);

  return (
    <Grid container spacing={0} sx={{ pr: 7 }}>
      <Grid alignItems="center" container direction="column" item justifyContent="flex-start" sx={{ pt: 2 }} xs={1}>
        {room?.imageUrl && (
          <IconButton sx={{ m: 0 }} onClick={() => setRoomImgDialog(room.id)}>
            <Avatar
              alt="Afbeelding van de ruimte"
              src={room.imageUrl}
              sx={{ width: '48px', height: '30px' }}
              variant="rounded"
            />
          </IconButton>
        )}
        {isEditable && (
          <>
            <Tooltip sx={{ margin: '16px 0' }} title="Ruimte opties">
              <IconButton
                size="small"
                sx={{ transform: openRoom ? 'rotate(180deg)' : 'none', transition: 'transform 200ms' }}
                onClick={handleClickRoom}
              >
                {openRoom ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={roomAnchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              open={openRoom}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClick={handleCloseRoom}
              onClose={handleCloseRoom}
            >
              <MenuItem onClick={() => setOpenUpdateRoomModal(true)}>
                <IconButton size="small" sx={{ color: 'text.disabled' }}>
                  <ModeEditIcon fontSize="small" />
                </IconButton>
              </MenuItem>
              <MenuItem disabled={numberOfRooms <= 1} onClick={() => setOpenMoveRoomUpOrDownModal(room.id)}>
                <Tooltip placement="right" title="Ruimte omhoog of naar omlaag verplaatsen">
                  <IconButton size="small" sx={{ color: 'text.disabled' }}>
                    <DriveFileMove fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MenuItem>
              <MenuItem onClick={() => setOpenDeleteRoomModal(true)}>
                <IconButton size="small" sx={{ color: 'text.disabled' }}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </MenuItem>
            </Menu>
          </>
        )}
      </Grid>
      <Grid item xs={11}>
        <TableContainer>
          <Table aria-label="room" sx={{ minWidth: 650, border: 'none', marginBottom: '8px', tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: 0, td: { fontSize: '12px' } }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="h3">
                    <RoomIcon style={{ marginRight: 16 }} />
                    {room.name}
                  </Typography>
                  <sub>
                    {room.roomHeight} meter
                    {room.verticallyAccessible ? ' | Verticaal bereikbaar' : ' | Verticaal niet bereikbaar'} | {''}
                    Aan-tijd: {room.sensorOnTimePercentage && Math.round(room.sensorOnTimePercentage * 100)}%
                  </sub>
                  {(days !== undefined || hours !== undefined) && (
                    <Typography sx={{ fontSize: 12, color: '#46505C', fontStyle: 'italic' }} variant="body2">
                      {hours !== undefined ? `${hours} uur per dag` : ''}
                      {days !== undefined && hours !== undefined ? ' · ' : ''}
                      {days !== undefined ? `${days} dag${days !== 1 ? 'en' : ''} per week` : ''}
                    </Typography>
                  )}
                </TableCell>
                {room.quotation.paymentType === PaymentType.Full ? (
                  <>
                    {!room.quotation.isNewLocation && (
                      <TableCell align="right" sx={{ paddingLeft: 0 }} width="90px">
                        Besparing per jaar
                      </TableCell>
                    )}
                    <TableCell align="right" width="90px">
                      Prijs
                    </TableCell>
                    <TableCell align="right" width="70px">
                      Aantal
                    </TableCell>
                    <TableCell align="right" width="90px">
                      Totaal
                    </TableCell>
                  </>
                ) : room.quotation.isNewLocation ? (
                  <TableCell align="center" sx={{ fontSize: '12px', verticalAlign: 'bottom' }} width="90px">
                    Aantal
                  </TableCell>
                ) : (
                  <>
                    <TableCell align="center" sx={{ fontSize: '12px', verticalAlign: 'bottom' }} width="60px">
                      Aantal
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px', verticalAlign: 'bottom' }} width="130px">
                      Wattage
                      <br /> oud/nieuw
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px', verticalAlign: 'bottom' }} width="140px">
                      Besparing in
                      <br /> kWh en CO2
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px', verticalAlign: 'bottom' }} width="100px">
                      Besparing
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            {room.lineItems.map((lineItem, index) => {
              return (
                <LineItem
                  isEditable={isEditable}
                  key={index}
                  lineItem={lineItem}
                  numberOfRooms={numberOfRooms}
                  quotation={room.quotation}
                  quotationRoomId={room.id}
                  setLineItem={setLineItem}
                  setOpenCloneListItemModal={setOpenCloneListItemModal}
                  setOpenDeleteListItemModal={setOpenDeleteListItemModal}
                  setOpenMoveListItemToNewRoomModal={setOpenMoveListItemToNewRoomModal}
                  setOpenUpdateListItemModal={setOpenUpdateListItemModal}
                />
              );
            })}
          </Table>
        </TableContainer>
        {isEditable && (
          <Button
            fullWidth
            startIcon={<AddCircleIcon />}
            sx={{ justifyContent: 'flex-start' }}
            onClick={handleAddNewLineItem}
          >
            Nieuw product toevoegen
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default RoomListItem;
