import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMove from '@mui/icons-material/DriveFileMove';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Box,
  debounce,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

import { QuotationLineItemFragment, QuotationRoomFieldsFragment } from 'src/graphql/generated/operations';
import { PaymentType } from 'src/graphql/generated/schema';
import { currency } from 'src/utils/currency.helper';
import { round } from 'src/utils/number.helper';

interface Props {
  lineItem: QuotationLineItemFragment;
  quotationRoomId: string;
  setLineItem: React.Dispatch<React.SetStateAction<QuotationLineItemFragment | undefined>>;
  setOpenDeleteListItemModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCloneListItemModal: React.Dispatch<React.SetStateAction<string>>;
  setOpenUpdateListItemModal: React.Dispatch<React.SetStateAction<string>>;
  setOpenMoveListItemToNewRoomModal: React.Dispatch<React.SetStateAction<string>>;
  isEditable: boolean | undefined;
  quotation: QuotationRoomFieldsFragment['quotation'];
  numberOfRooms: number;
}

const LineItem = ({
  lineItem,
  numberOfRooms,
  setLineItem,
  setOpenDeleteListItemModal,
  setOpenUpdateListItemModal,
  setOpenMoveListItemToNewRoomModal,
  setOpenCloneListItemModal,
  isEditable,
  quotationRoomId,
  quotation,
}: Props): JSX.Element => {
  const [hover, setHover] = useState(false);

  const [lineItemAnchorEl, setLineItemAnchorEl] = useState<null | HTMLElement>(null);
  const openLineItem = Boolean(lineItemAnchorEl);

  // Debounce the hover state to allow the user to leave the table row and enter the the arrow to open the dropdown menu
  const debouncedSetHover = useMemo(() => debounce(setHover, 10), []);

  const handleClickLineItem = (event: React.MouseEvent<HTMLElement>) => {
    setLineItemAnchorEl(event.currentTarget);
  };
  const handleCloseLineItem = () => {
    setLineItemAnchorEl(null);
  };

  const handleUpdateListItem = () => {
    setOpenUpdateListItemModal(quotationRoomId);
  };

  const handleCloneListItem = () => {
    setOpenCloneListItemModal(quotationRoomId);
  };

  const handleDeleteListItem = () => {
    setOpenDeleteListItemModal(true);
  };

  const handleMoveToRoomListItem = () => {
    setOpenMoveListItemToNewRoomModal(quotationRoomId);
  };

  const handleClickMenu = (lineItem: QuotationLineItemFragment) => {
    setLineItem(lineItem);
    setLineItemAnchorEl(null);
  };

  return (
    <TableBody
      sx={{ '&:hover': { backgroundColor: 'primary.100' } }}
      onMouseOut={() => debouncedSetHover(false)}
      onMouseOver={() => debouncedSetHover(true)}
    >
      {isEditable && hover && (
        <TableRow sx={{ position: 'absolute', ml: '-60px', mt: '-8px' }}>
          <TableCell sx={{ border: 'none' }}>
            <>
              <Tooltip sx={{ margin: '16px 0' }} title="Product opties">
                <IconButton
                  size="small"
                  sx={{ ml: 1, transform: openLineItem ? 'rotate(180deg)' : 'none', transition: 'transform 200ms' }}
                  onClick={handleClickLineItem}
                >
                  {openLineItem ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={lineItemAnchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                open={openLineItem}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClick={() => handleClickMenu(lineItem)}
                onClose={handleCloseLineItem}
              >
                <MenuItem onClick={handleUpdateListItem}>
                  <Tooltip placement="right" title="Bewerken">
                    <IconButton size="small" sx={{ color: 'text.disabled' }}>
                      <ModeEditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </MenuItem>

                <MenuItem disabled={numberOfRooms <= 1} onClick={handleMoveToRoomListItem}>
                  <Tooltip placement="right" title="Verplaatsen naar andere ruimte">
                    <IconButton size="small" sx={{ color: 'text.disabled' }}>
                      <DriveFileMove fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </MenuItem>
                <MenuItem onClick={handleCloneListItem}>
                  <Tooltip placement="right" title="Dupliceren">
                    <IconButton size="small" sx={{ color: 'text.disabled' }}>
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </MenuItem>

                <MenuItem onClick={handleDeleteListItem}>
                  <Tooltip placement="right" title="Verwijderen">
                    <IconButton size="small" sx={{ color: 'text.disabled' }}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </MenuItem>
              </Menu>
            </>
          </TableCell>
        </TableRow>
      )}

      <TableRow
        sx={{
          td: { fontSize: '12px', color: 'text.secondary', border: 0, padding: '0 1rem .5rem 1rem' },
          '&:last-lineItem': {
            borderBottom: '1px',
            borderBottomColor: 'text.disabled',
            borderStyle: 'dotted',
          },
          border: 0,
        }}
      >
        <TableCell scope="row" sx={{ paddingLeft: 0 }} width={300}>
          <Typography sx={{ fontSize: '12px', mt: 1, color: '#1E1E1E' }} variant="body1">
            {lineItem.oldProductName}
            {lineItem.product?.sku && (
              <Typography component="span" display="inline" fontSize={10} variant="body2">
                {' '}
                - {lineItem.product.sku}
              </Typography>
            )}
          </Typography>
          {lineItem.name}
        </TableCell>
        {quotation.paymentType === PaymentType.Full ? (
          <>
            {!quotation.isNewLocation && (
              <TableCell align="right">
                {lineItem.costSavingsPerYear ? (
                  <Box
                    sx={{
                      display: 'inline-flex',
                      padding: '1px 8px 2px',
                      borderRadius: '100px',
                      height: '21px',
                      backgroundColor: '#D0F2D7',
                      color: 'primary.main',
                      fontSize: '12px',
                    }}
                  >
                    {currency(lineItem.costSavingsPerYear)}
                  </Box>
                ) : (
                  '-'
                )}
              </TableCell>
            )}
            <TableCell align="right">{currency(lineItem.price)}</TableCell>
            <TableCell align="right">{lineItem?.quantity}</TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              {currency(lineItem.totalPrice)}
            </TableCell>
          </>
        ) : quotation.isNewLocation ? (
          <TableCell align="center">{lineItem?.quantity}</TableCell>
        ) : (
          <>
            <TableCell align="center">{lineItem?.quantity}</TableCell>
            <TableCell align="center" width={120}>
              <Box
                sx={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  gap: 2,
                  alignItems: 'center',
                  width: '100%',
                  padding: '1px 8px 2px',
                  borderRadius: '100px',
                  height: '21px',
                  fontSize: '12px',
                  backgroundColor: 'rgb(255,198,150)',
                  background:
                    'linear-gradient(90deg, rgba(253,196,148,1) 0%, rgba(253,196,148,1) 40%, rgba(198,229,207,1) 60%, rgba(198,229,207,1) 100%)',
                }}
              >
                <span>{lineItem?.oldProductWattage}</span>
                <span>{lineItem?.wattage}</span>
              </Box>
            </TableCell>
            <TableCell width={150}>
              <Box
                sx={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  gap: 2,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span>{round(lineItem?.energySavingsPerYear, 0)}</span>
                <span>{round(lineItem?.savedCO2, 0)} kg</span>
              </Box>
            </TableCell>
            <TableCell align="center">
              <Box
                sx={{
                  display: 'inline-flex',
                  padding: '1px 8px 2px',
                  borderRadius: '100px',
                  height: '21px',
                  backgroundColor: '#D0F2D7',
                  fontSize: '12px',
                }}
              >
                {currency(lineItem.costSavingsPerYear)}
              </Box>
            </TableCell>
          </>
        )}
      </TableRow>
    </TableBody>
  );
};

export default LineItem;
