import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { UserSettings } from 'src/config/UserSettings';
import { useMyProfileButtonMeQuery } from 'src/graphql/generated/hooks';
import { useAuth } from 'src/hooks/useAuth.hook';
import { Routes } from 'src/pages/Routes';

interface Route {
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  name: string;
  to?: string;
  fn?: () => unknown;
}

interface Props {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyProfileButton = ({ setMobileOpen }: Props): JSX.Element => {
  const [{ isDrawerMinimized }] = useContext(UserSettings);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [, { signOut }] = useAuth();

  const history = useHistory();

  const { data: userData } = useMyProfileButtonMeQuery();

  const options: Route[] = useMemo(
    () => [
      {
        name: 'Mijn account',
        icon: SettingsIcon,
        to: Routes.BackOfficeProfile,
      },
      {
        name: 'Uitloggen',
        icon: LockOutlinedIcon,
        fn: signOut,
      },
    ],
    [signOut],
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleClickListItem = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, option: Route) => {
      option.fn?.();
      if (option.to) {
        setMobileOpen(false);
        history.push(option.to);
      }
      setAnchorEl(null);
    },
    [history, setMobileOpen],
  );

  const initials = useMemo(() => {
    if (!userData) return '';
    return `${userData.me.firstName?.charAt(0).toUpperCase()}${userData.me.lastName?.charAt(0).toUpperCase()}${
      userData.me.companyNameOwner
    }`;
  }, [userData]);

  if (!userData) {
    return <Skeleton animation="wave" />;
  }

  return (
    <>
      <List aria-label="My profile" component="menu">
        <ListItem
          disableGutters
          sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', marginLeft: '4px' }}
          onClick={handleClickListItem}
        >
          <Avatar
            alt={userData.me.firstName ?? 'username'}
            sx={{ backgroundColor: 'primary.main', marginRight: '8px' }}
          >
            {initials}
          </Avatar>
          {!isDrawerMinimized && (
            <>
              <ListItemText
                primary={userData.me.firstName}
                sx={{ display: 'flex', justifyContent: 'center', color: 'white', userSelect: 'none' }}
              />
              <ListItemIcon>
                <ExpandMoreIcon sx={{ transform: anchorEl ? 'scaleY(-1)' : 'scaleY(1)', transition: '0.2s linear' }} />
              </ListItemIcon>
            </>
          )}
        </ListItem>
      </List>
      <Menu
        anchorEl={anchorEl}
        id="lock-menu"
        keepMounted
        open={!!anchorEl}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#222940',
          },
        }}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            sx={{
              color: 'rgba(255,255,255,0.8)',
              ':hover': {
                color: 'white',
                backgroundColor: 'actions.300',
              },
            }}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            <ListItemIcon>
              <option.icon />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MyProfileButton;
