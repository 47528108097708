import { Operation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import toast from 'react-hot-toast';

import { ErrorMessages } from '../constants/messages';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleErrors = (errors: readonly GraphQLError[], operation: Operation): boolean => {
  if (errors.length > 0) {
    // const context = operation.getContext();
    const message: string = typeof errors[0]?.message === 'string' ? errors[0].message : 'Unknown error.';
    if (message.startsWith('User not found')) {
      toast.error(ErrorMessages.UserNotFound);
      return false;
    }
    switch (message) {
      case 'Not allowed. Sign out first.':
        toast.error(ErrorMessages.SignOut);
        window.location.reload(); // Not very nice, but a full reload will show the user that they are signed in
        return true;

      case 'Sign in to view this resource':
        toast.error(ErrorMessages.SignIn);
        return true;

      case 'Unknown credentials.':
        toast.error(ErrorMessages.UnknownCredentials);
        return true;

      case 'Input data validation failed':
        return false;

      case 'Invalid password reset token':
        toast.error(ErrorMessages.InvalidToken);
        return false;

      case 'Invalid credentials':
        toast.error(ErrorMessages.InvalidPassword);
        return true;

      default:
        toast.error(ErrorMessages.UnknownError);
        break;
    }

    return false;
  }
  return false;
};
