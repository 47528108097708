import { Box, Typography } from '@mui/material';

import { FilterStepComponentProps } from 'src/hooks/useFilterProgress';
import LeafIcon from 'src/static/productFilter/retrofit/LeafIcon';

export default function RetrofitTrue({ title }: FilterStepComponentProps): JSX.Element {
  return (
    <Box>
      <Box>
        <LeafIcon color="secondary" fontSize="small" sx={{ mx: 0.5 }} />
      </Box>
      <Typography variant="h4">{title}</Typography>
    </Box>
  );
}
