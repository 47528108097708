import CreateIcon from '@mui/icons-material/Create';
import { Button, ButtonTypeMap, ExtendButtonBase } from '@mui/material';

const CardEditButton: ExtendButtonBase<ButtonTypeMap> = ({ ...props }) => {
  return (
    <Button
      startIcon={<CreateIcon />}
      sx={{
        height: '28px',
        minWidth: '28px',
        padding: '4px',
        fontSize: '20px',
        color: '#46505C',
        border: '1px solid #D9E2F0',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.04)',
        borderRadius: '4px',
        '& .MuiButton-startIcon': {
          m: 0,
        },
        '&:hover': {
          bgcolor: 'rgba(0, 0, 0, 0.04)',
          borderColor: '#D9E2F0',
        },
        '&:active': {
          bgcolor: '#BDBDBD',
          borderColor: '#D9E2F0',
        },
      }}
      variant="outlined"
      {...props}
    ></Button>
  );
};

export default CardEditButton;
