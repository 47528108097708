import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { Box, IconButton, InputAdornment, Paper, Switch, TextField, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { InputSetQuotationLineItem, PaymentType } from 'src/graphql/generated/schema';
import { currency } from 'src/utils/currency.helper';

import { FilterOptionThumbnail } from '../ProductFilter/components/ProductFilterOption/ProductFilterOption';
import StepHeading from '../ProductFilter/components/StepHeading/StepHeading';
import { LineItem, OtherFields } from '../SetLineItem';

interface Props {
  lineItem: LineItem;
  setLineItem: React.Dispatch<React.SetStateAction<InputSetQuotationLineItem | undefined>>;
  otherFields: React.MutableRefObject<OtherFields>;
  step: number;
  isNewLocation?: boolean;
  paymentType: PaymentType | undefined;
  laasToPriceFactor?: number;
}

export default function ProductOptions({
  lineItem,
  setLineItem,
  step,
  isNewLocation,
  paymentType,
  laasToPriceFactor,
  otherFields,
}: Props): JSX.Element {
  const [editOldProductWattage, setEditOldProductWattage] = useState<boolean>(
    lineItem.oldProductWattage !== lineItem.oldProduct?.customFields.wattage,
  );

  const productTotalQuantity = useMemo(() => {
    if (!lineItem.quantity) return;
    // return quantitySet ? quantitySet * lineItem.quantity : lineItem.quantity;
    return lineItem.quantity;
  }, [lineItem.quantity]);

  const price = useMemo(
    () => lineItem.productVariant?.calculatedPrice ?? lineItem.product?.price,
    [lineItem.product?.price, lineItem.productVariant?.calculatedPrice],
  );

  const productTotalPrice = useMemo(() => {
    if (!price || !lineItem.quantity) return;
    return price * lineItem.quantity;
  }, [lineItem.quantity, price]);

  const monthlyPrice = useMemo(
    () => (laasToPriceFactor === undefined ? 0 : (price ?? 0) / laasToPriceFactor),
    [laasToPriceFactor, price],
  );

  const productTotalMonthlyPrice = useMemo(
    () => monthlyPrice * (lineItem?.quantity ?? 0),
    [lineItem?.quantity, monthlyPrice],
  );

  const hasAssemblyService = useMemo(() => {
    return lineItem.assemblyCost === undefined
      ? !!lineItem.product?.customFields.assemblyCost // Can it be enabled, if so, default to true
      : lineItem.assemblyCost !== 0; // True if not 0
  }, [lineItem.assemblyCost, lineItem.product?.customFields.assemblyCost]);

  // Order + condition to show the steps, used to calculate the step heading number
  const showSteps = useMemo(
    () => ({
      quantity: true,
      wattage: !isNewLocation && lineItem.wattage,
      assemblyCost: lineItem.product?.customFields.assemblyCost,
      outsideSize: true,
      connectionType: true,
      ceilingType: true,
      otherComments: true,
    }),
    [isNewLocation, lineItem.product?.customFields.assemblyCost, lineItem.wattage],
  );

  const getStep = useCallback(
    (stepName: keyof typeof showSteps): number => {
      const entries = Object.entries(showSteps);
      const untilStep = entries.findIndex(([showStepName]) => showStepName === stepName);
      if (untilStep < 0) return 0; // StepName should not be shown or is unknown
      return entries
        .filter((_, i) => i < untilStep)
        .reduce((curr, [, shouldShowStep]) => curr + (shouldShowStep ? 1 : 0), step);
    },
    [showSteps, step],
  );

  return (
    <>
      <StepHeading isExpanded step={getStep('quantity')} title="Aantal" />
      <Paper
        elevation={2}
        sx={{
          p: 2,
          mt: 2.5,
          mb: 1,
          display: 'flex',
          alignItems: 'center',
          transition: 'all 200ms',
          overflow: 'hidden',
          border: '2px solid',
          borderColor: 'primary.main',
        }}
      >
        <Box sx={{ width: '4rem', height: '4rem', mr: 2 }}>
          {lineItem.product?.thumbnail?.urlThumbnail && (
            <FilterOptionThumbnail
              alt={lineItem.product?.thumbnail?.description ?? ''}
              src={lineItem.product?.thumbnail?.urlThumbnail}
            />
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography variant="h4">{lineItem.oldProductName}</Typography>
          <Typography color="text.secondary" variant="subtitle2">
            {/* {quantitySet ? quantitySet + 'x' : ''}*/}
            {lineItem.name}{' '}
            {lineItem.productVariant && lineItem.productVariant.optionValues[0]?.label
              ? ` - ${lineItem.productVariant.optionValues[0].label}`
              : ''}
          </Typography>

          {paymentType === PaymentType.Full ? (
            <Typography color="primary.main" variant="body2">
              {productTotalQuantity && productTotalQuantity > 1 && `${productTotalQuantity}x ${currency(price)} = `}
              {currency(productTotalPrice)}
            </Typography>
          ) : (
            monthlyPrice &&
            productTotalQuantity && (
              <Typography color="primary.main" variant="body2">
                {productTotalQuantity &&
                  productTotalQuantity > 1 &&
                  `${productTotalQuantity}x ${currency(monthlyPrice)} = `}
                {currency(productTotalMonthlyPrice)} per maand
              </Typography>
            )
          )}
        </Box>
        <Box sx={{ width: '4rem', height: '4rem' }}>
          {lineItem.productVariant?.imageUrl && (
            <FilterOptionThumbnail
              alt={lineItem.productVariant?.optionValues[0]?.label ?? ''}
              src={lineItem.productVariant?.imageUrl}
            />
          )}
        </Box>
      </Paper>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: { min: 1 },
          endAdornment: (
            <InputAdornment position="end">
              <>
                <IconButton
                  disabled={lineItem.quantity === 1}
                  sx={{ color: 'primary.main' }}
                  onClick={() =>
                    setLineItem({
                      ...lineItem,
                      quantity: lineItem.quantity ? lineItem.quantity - 1 : undefined,
                    })
                  }
                >
                  <DoDisturbOnOutlinedIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'primary.main' }}
                  onClick={() =>
                    setLineItem({
                      ...lineItem,
                      quantity: lineItem.quantity ? lineItem.quantity + 1 : undefined,
                    })
                  }
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </>
            </InputAdornment>
          ),
        }}
        fullWidth
        id="product-quantity"
        label="Aantal producten"
        sx={{
          mt: 2,
          '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
        }}
        type="number"
        value={lineItem.quantity}
        onChange={(event) =>
          setLineItem({
            ...lineItem,
            quantity: parseInt(event.target.value),
          })
        }
      />
      {showSteps.wattage && (
        <Box sx={{ mt: 3 }}>
          <StepHeading isExpanded step={getStep('wattage')} title="Wattage huidige lamp overschrijven" />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography align="left" variant="subtitle2">
                Wattage van de huidige/ oude lamp overschrijven (
                {lineItem.oldProduct?.customFields.wattage?.toString() ?? 0} Watt)
              </Typography>
              <Switch
                checked={editOldProductWattage}
                onChange={() => {
                  setEditOldProductWattage((c) => {
                    // Reset the value when toggling from true back to false
                    if (c) {
                      setLineItem({
                        ...lineItem,
                        oldProductWattage: lineItem.oldProduct?.customFields.wattage ?? 0,
                      });
                    }
                    return !c;
                  });
                }}
              />
            </Box>
            {editOldProductWattage && (
              <Box sx={{ display: 'flex' }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position="end" sx={{ mr: 1 }}>
                        Watt
                        <IconButton
                          aria-label="toggle old wattage overwrite"
                          edge="end"
                          sx={{ ml: 1 }}
                          onClick={() =>
                            setLineItem({
                              ...lineItem,
                              oldProductWattage: lineItem.oldProduct?.customFields.wattage ?? 0,
                            })
                          }
                        >
                          <AutorenewIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  id="product-quantity"
                  label="Werkelijke wattage"
                  sx={{ mt: 2 }}
                  type="number"
                  value={lineItem.oldProductWattage ?? ''}
                  onChange={(event) =>
                    setLineItem({
                      ...lineItem,
                      oldProductWattage: event.target.value ? parseInt(event.target.value) : null,
                    })
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      {showSteps.assemblyCost && (
        <Box sx={{ mt: 3 }}>
          <StepHeading isExpanded step={getStep('assemblyCost')} title="Interesse in montage door Firefly?" />
          <Box sx={{ display: 'flex', mt: 1, ml: 3, alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography align="left" variant="subtitle2">
              Firefly beschikt over een ervaren montage-team en eigen hoogwerkers. Hierdoor monteren wij uw lampen voor
              de scherpste prijs. Als u voor montage kiest bent u verzekerd van een veilige en goedwerkende installatie.
              Vink deze optie aan om een vrijblijvend voorstel te ontvangen.
            </Typography>
            <Switch
              checked={hasAssemblyService}
              onChange={() =>
                setLineItem({
                  ...lineItem,
                  assemblyCost: !lineItem.assemblyCost ? lineItem.product?.customFields.assemblyCost : 0,
                })
              }
            />
          </Box>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gap={2} mt={3}>
        <Box>
          <StepHeading isExpanded step={getStep('outsideSize')} title="Buitenmaat" />
          <TextField
            defaultValue={lineItem.outsideSize ?? ''}
            fullWidth
            minRows={2}
            multiline
            placeholder="Buitenmaat"
            onChange={(e) => {
              otherFields.current.outsideSize = e.target.value;
            }}
          />
        </Box>
        <Box>
          <StepHeading isExpanded step={getStep('connectionType')} title="Type aansluiting" />
          <TextField
            defaultValue={lineItem.connectionType ?? ''}
            fullWidth
            minRows={2}
            multiline
            placeholder="Type aansluiting"
            onChange={(e) => {
              otherFields.current.connectionType = e.target.value;
            }}
          />
        </Box>
        <Box>
          <StepHeading isExpanded step={getStep('ceilingType')} title="Type plafond" />
          <TextField
            fullWidth
            minRows={2}
            multiline
            // eslint-disable-next-line react/jsx-sort-props
            onChange={(e) => {
              otherFields.current.ceilingType = e.target.value;
            }}
            placeholder="Type plafond"
            defaultValue={lineItem.ceilingType ?? ''}
          />
        </Box>

        <Box>
          <StepHeading isExpanded step={getStep('otherComments')} title="Overige opmerkingen" />
          <TextField
            defaultValue={lineItem.otherComments ?? ''}
            fullWidth
            minRows={2}
            multiline
            placeholder="Overige opmerkingen"
            onChange={(e) => {
              otherFields.current.otherComments = e.target.value;
            }}
          />
        </Box>
      </Box>
    </>
  );
}
