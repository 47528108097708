import LoadingButton from '@mui/lab/LoadingButton';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';

import { ErrorMessages, SuccessMessages } from 'src/constants/messages';
import { useGetQuotationQuery, useUpdateQuotationStatusMutation } from 'src/graphql/generated/hooks';
import { DealStatus } from 'src/graphql/generated/schema';

interface QuotationParams {
  quotationId: string;
}

interface Props {
  variant: 'text' | 'contained' | 'outlined';
  isSigning: boolean;
}

const FinalAgreementBtn = ({ variant, isSigning }: Props): JSX.Element => {
  const { quotationId } = useParams<QuotationParams>();

  const { data } = useGetQuotationQuery({
    variables: { input: { quotationId } },
    fetchPolicy: 'cache-and-network',
  });

  const costumer = data?.quotation.customer;
  const rooms = data?.quotation.rooms;

  const existLineItem = rooms?.filter((i) => {
    return i.lineItems.length > 0;
  });

  const [updateQuotationStatus, { loading: loadingUpdateQuotationStatus }] = useUpdateQuotationStatusMutation({
    update(_, { errors }) {
      if (errors) return toast.error(errors[0].message);
      toast.success(SuccessMessages.QuotationUpdated);
    },
  });

  const confirmQuotation = useCallback(() => {
    if (costumer === null) {
      toast.error(ErrorMessages.ContactNotFound);
      return;
    }
    if (rooms?.length === 0) {
      toast.error(ErrorMessages.RequireOneRoom);
      return;
    }
    if (existLineItem?.length === 0) {
      toast.error(ErrorMessages.RequireOneLineItem);
      return;
    }
    updateQuotationStatus({ variables: { input: { quotationId, status: DealStatus.Won } } });
  }, [costumer, existLineItem?.length, quotationId, rooms?.length, updateQuotationStatus]);

  return (
    <LoadingButton
      disabled={isSigning}
      loading={loadingUpdateQuotationStatus}
      sx={{ height: 'inherit' }}
      variant={variant}
      onClick={confirmQuotation}
    >
      Definitief akkoord
    </LoadingButton>
  );
};

export default FinalAgreementBtn;
