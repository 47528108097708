export enum ErrorMessages {
  SignOut = 'Niet toegestaan. Log eerst uit.',
  SignIn = 'Niet toegestaan. Log eerst in.',
  AccountInvalidated = 'Uitgelogd. Sessie verlopen of wachtwoord aangepast.',
  UnknownCredentials = 'Ongeldige inloggegevens.',
  InvalidToken = 'Ongeldige of gebruikte token.',
  InvalidId = 'Ongeldig ID',
  TokenNotFound = 'Token niet gevonden. Mogelijk is deze verlopen.',
  UnknownError = 'Oeps! Er is iets mis gegaan.',
  UnknownEmail = 'Geen account met dit emailadres gevonden.',
  UserNotFound = 'Gebruiker niet gevonden.',
  AccountBlocked = 'Account is geblokkeerd.',
  DuplicateKey = 'Dit email adres is al in gebruik.',
  ShippingBillingAddressSame = 'Verzendadres en factuuradres zijn hetzelfde.',
  InvalidPassword = 'Ongeldig wachtwoord.',
  ContactNotFound = 'Contact niet gevonden.',
  RequireOneRoom = 'Kies tenminste één ruimte.',
  RequireOneLineItem = 'Kies tenminste één product.',
  NegativeValue = 'Voer een waarde in van 0 of groter.',
  NoValue = 'Voer een waarde in.',
}

export enum SuccessMessages {
  AccountCreatedAndInvited = 'Gebruiker aangemaakt en uitgenodigd.',
  ContactCreated = 'Contact aangemaakt.',
  ContactUpdated = 'Contact bijgewerkt.',
  ContactDeleted = 'Contact verwijderd.',
  QuotationUpdated = 'Offerte bijgewerkt.',
  QuotationSend = 'Offerte verzonden.',
  QuotationResend = 'Offerte opnieuw verzonden.',
  AccountActivated = 'Wachtwoord ingesteld en account geactiveerd.',
  ChangeAccount = 'Account gegevens zijn aangepast.',
  ChangeAppSetting = 'Instelling is aangepast.',
  SignedOut = 'Uitgelogd.',
  SignedIn = 'Ingelogd.',
  ReSignedIn = 'Opnieuw ingelogd.',
  PasswordResetLinkSend = 'Wachtwoord reset link verzonden.',
  PasswordChanged = 'Nieuw wachtwoord opgeslagen.',
  EmailForgotten = 'Emailadres niet langer opgeslagen.',
  ResendInvite = 'Uitnodiging opnieuw verzonden.',
  UserBlocked = 'Gebruiker geblokkeerd.',
  UserUnblocked = 'Gebruiker gedeblokkeerd.',
  DataProcessed = 'Verbruikgegevens opgeslagen.',
  SignatureCreated = 'Handtekening aangemaakt.',
  SignatureCleared = 'Handtekening is gewist.',
  ProductAdded = 'Product toegevoegd.',
  QuotationCloned = 'Offerte gedupliceerd',
  LaaSChargeOverwriteUpdated = 'Maandelijks bedrag bijgewerkt.',
  LineItemMovedToNewRoom = 'Line item is verplaatst.',
  LineItemDuplicated = 'Line item gedupliceerd.',
  QuotationDeleted = 'Offerte verwijderd.',
  ProductDeleted = 'ProductDeleted',
  ProductUpdated = 'ProductUpdated',
  ProductCreated = 'ProductCreated',
}
