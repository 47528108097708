import { Theme } from '@emotion/react';
import { nlNL } from '@mui/material/locale';
import { createTheme, darken, lighten } from '@mui/material/styles';

/**
 * This is necessary if you want to extend the default theme options.
 * For example: we want a new color group outside of `primary` and `secondary`.
 *
 * https://next.material-ui.com/customization/palette/#adding-new-colors
 */
declare module '@mui/material/styles' {
  // interface Theme {
  //   status: {
  //     danger: React.CSSProperties['color'];
  //   };
  // }

  interface Palette {
    actions: Palette['primary'];
  }

  interface PaletteOptions {
    actions: PaletteOptions['primary'];
  }

  interface PaletteColor {
    '450'?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
    '450'?: string;
  }
}

export const mainColor = '#9ba1cf';
export const secondaryColor = '#13BE37';
export const actionColor = '#3B3E4F';
export const infoColor = '#616ED7';
export const errorColor = '#CF7478';

export const createAppTheme = (isDarkMode: boolean): Theme =>
  createTheme(
    {
      typography: {
        fontFamily: ['Lato', 'sans-serif'].join(','),
        body1: {
          fontWeight: 700,
        },
        body2: {
          fontWeight: 400,
        },
        h1: {
          fontSize: '26px',
          fontWeight: 700,
        },
        h2: {
          fontSize: '22px',
          fontWeight: 700,
        },
        h3: {
          fontSize: '18px',
          fontWeight: 700,
        },
        h4: {
          fontSize: '14px',
          fontWeight: 700,
        },
        subtitle2: {
          fontWeight: 400,
        },
      },

      palette: {
        mode: isDarkMode ? 'dark' : 'light',

        primary: {
          main: mainColor,
          dark: darken(mainColor, 0.4),
          light: lighten(mainColor, 0.3),
          '100': lighten(mainColor, 0.4),
          '200': lighten(mainColor, 0.3),
          '300': lighten(mainColor, 0.2),
          '400': lighten(mainColor, 0.1),
          '500': mainColor,
          '600': darken(mainColor, 0.1),
          '700': darken(mainColor, 0.2),
          '800': darken(mainColor, 0.3),
          '900': darken(mainColor, 0.4),
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: secondaryColor,
          dark: darken(secondaryColor, 0.4),
          light: lighten(secondaryColor, 0.3),
          '200': lighten(secondaryColor, 0.3),
          '300': lighten(secondaryColor, 0.2),
          '400': lighten(secondaryColor, 0.1),
          '500': secondaryColor,
          '600': darken(secondaryColor, 0.1),
          '700': darken(secondaryColor, 0.2),
          '800': darken(secondaryColor, 0.3),
          '900': darken(secondaryColor, 0.4),
          contrastText: '#FFFFFF',
        },
        actions: {
          main: actionColor,
          dark: '',
          light: '',
          '200': lighten(actionColor, 0.3),
          '300': lighten(actionColor, 0.2),
          '400': lighten(actionColor, 0.1),
          '450': lighten(actionColor, 0.05),
          '500': actionColor,
          '600': darken(actionColor, 0.1),
          '700': darken(actionColor, 0.2),
          '800': darken(actionColor, 0.3),
          '900': darken(actionColor, 0.4),
        },
        text: {
          primary: '#1E1E1E',
          secondary: '#46505C',
          disabled: '#87919D',
        },
        info: {
          main: infoColor,
        },
        error: {
          main: errorColor,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            html, body, #root {
              background: ${isDarkMode ? '#121419' : '#F4F5F7'};
            }
          `,
        },
        MuiBreadcrumbs: {
          styleOverrides: {
            separator: {
              marginRight: '4px',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '6px',
              textTransform: 'none',
              padding: '8px 22px',
            },
            containedPrimary: {
              boxShadow: isDarkMode ? 'none' : '0px 5px 10px rgba(48, 50, 64, 0.24)',
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: isDarkMode ? 'none' : '0px 0px 10px 0px rgba(48, 50, 64, 0.05)',
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              padding: '24px',
            },
          },
        },
        MuiCardActions: {
          styleOverrides: {
            root: {
              padding: '8px 24px 24px',
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: isDarkMode ? '#080B15' : actionColor,
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              '&.Mui-disabled:before': {
                borderBottomStyle: 'solid',
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              '&:hover': {
                color: actionColor,
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: isDarkMode ? 'black' : 'white',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            elevation1: {
              boxShadow: isDarkMode ? 'none' : '0px 0px 10px 0px rgba(48, 50, 64, 0.05)',
            },
            elevation2: {
              boxShadow: isDarkMode ? 'none' : '0px 1px 5px rgba(0, 0, 0, 0.12)',
            },
          },
        },
      },
      breakpoints: {},
    },
    nlNL,
  );
