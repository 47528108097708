import { Box, SxProps, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

const days = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'];

interface Props {
  title?: string;
  setDaysStateBoolean: (daysStateBoolean: Array<boolean>) => void;
  daysStateBoolean: boolean[];
  disabled?: boolean;
}

const selectedSx: SxProps = {
  backgroundColor: ' rgba(1, 1, 1, 0.02)',
  letterSpacing: '0.4px',
  textTransform: 'capitalize',
  flex: '1 3 80px',

  '&.Mui-selected': {
    backgroundColor: 'primary.contrastText',
    color: 'primary.main',
    borderRadius: '6px',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)',
  },
};
const DaysOfWeekBar = ({ title, setDaysStateBoolean, daysStateBoolean, disabled }: Props): JSX.Element => {
  const numOfDays = useMemo(() => daysStateBoolean.reduce((acc, day) => acc + (day ? 1 : 0), 0), [daysStateBoolean]);

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, selectedDays: string[]) => {
      setDaysStateBoolean(days.map((day) => selectedDays.includes(day)));
    },
    [setDaysStateBoolean],
  );

  return (
    <Box sx={{ width: '100%', padding: ' 10px 10px 10px 0' }}>
      <Typography component="span" sx={{ mb: '1rem', display: 'flex', justifyContent: 'space-between', pr: '.5rem' }}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="h4">({numOfDays})</Typography>
      </Typography>
      <Box>
        <ToggleButtonGroup
          disabled={disabled}
          sx={{ width: '100%' }}
          value={daysStateBoolean.map((d, i) => (d ? days[i] : undefined)).filter((d) => d)}
          onChange={handleChange}
        >
          {days.map((day, index) => (
            <ToggleButton aria-label={`Toggle ${day}`} key={index} sx={selectedSx} value={day}>
              {day}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};
export default DaysOfWeekBar;
