import { Box } from '@mui/material';
import { useState } from 'react';

import { QuotationLineItemFragment, QuotationRoomFieldsFragment } from 'src/graphql/generated/operations';

import SetLineItem from '../../../SetLineItem/SetLineItem';
import CloneLineItemDialog from './roomDialogs/CloneLineItemDialog';
import DeleteLineItemDialog from './roomDialogs/DeleteLineItemDialog';
import DeleteRoomDialog from './roomDialogs/DeleteRoomDialog';
import MoveLineItemToRoomModal from './roomDialogs/MoveLineItemToRoomModal';
import MoveRoomUpOrDownModal from './roomDialogs/MoveRoomUpOrDownModal';
import RoomDialog from './roomDialogs/RoomDialog';
import RoomImgDialog from './roomDialogs/RoomImgDialog';
import RoomListItem from './RoomListItem';

interface Props {
  quotationId: string;
  isNewLocation: boolean;
  rooms?: Array<QuotationRoomFieldsFragment>;
  isEditable?: boolean;
}

const RoomList = ({ rooms, quotationId, isEditable, isNewLocation }: Props): JSX.Element => {
  const [roomProp, setRoomProp] = useState<QuotationRoomFieldsFragment>();
  const [openDeleteRoomModal, setOpenDeleteRoomModal] = useState(false);
  const [openUpdateRoomModal, setOpenUpdateRoomModal] = useState(false);
  const [roomImgDialog, setRoomImgDialog] = useState<string | undefined>(undefined);

  const [openDeleteListItemModal, setOpenDeleteListItemModal] = useState(false);
  const [openCloneListItemModal, setOpenCloneListItemModal] = useState('');

  const [openUpdateListItemModal, setOpenUpdateListItemModal] = useState('');
  const [openMoveListItemToNewRoomModal, setOpenMoveListItemToNewRoomModal] = useState('');
  const [openMoveRoomUpOrDownModal, setOpenMoveRoomUpOrDownModal] = useState('');
  const [lineItem, setLineItem] = useState<QuotationLineItemFragment>();

  if (!rooms) return <Box sx={{ marginLeft: '16px' }}>Er zijn nog geen ruimtes aangemaakt</Box>;

  return (
    <>
      <Box
        component="ul"
        sx={{
          listStyleType: 'none',
          padding: 0,
          margin: 0,
        }}
      >
        {rooms.map((room) => (
          <li key={room.id}>
            <RoomListItem
              isEditable={isEditable}
              numberOfRooms={rooms.length}
              room={room}
              setLineItem={setLineItem}
              setOpenCloneListItemModal={setOpenCloneListItemModal}
              setOpenDeleteListItemModal={setOpenDeleteListItemModal}
              setOpenDeleteRoomModal={setOpenDeleteRoomModal}
              setOpenMoveListItemToNewRoomModal={setOpenMoveListItemToNewRoomModal}
              setOpenMoveRoomUpOrDownModal={setOpenMoveRoomUpOrDownModal}
              setOpenUpdateListItemModal={setOpenUpdateListItemModal}
              setOpenUpdateRoomModal={setOpenUpdateRoomModal}
              setRoomImgDialog={setRoomImgDialog}
              setRoomProp={setRoomProp}
            />
          </li>
        ))}
      </Box>
      <RoomDialog
        open={openUpdateRoomModal}
        quotationId={quotationId}
        room={roomProp}
        setOpen={setOpenUpdateRoomModal}
      />
      <DeleteRoomDialog
        handleClose={() => setOpenDeleteRoomModal(false)}
        open={openDeleteRoomModal}
        quotationId={quotationId}
        quotationRoomId={roomProp?.id}
      />
      <DeleteLineItemDialog
        handleClose={() => setOpenDeleteListItemModal(false)}
        lineItemId={lineItem?.id}
        open={openDeleteListItemModal}
        quotationId={quotationId}
      />
      <CloneLineItemDialog
        handleClose={() => setOpenCloneListItemModal('')}
        lineItem={lineItem}
        open={!!openCloneListItemModal}
        quotationId={quotationId}
        quotationRoomId={openCloneListItemModal}
      />
      <SetLineItem
        currentLineItem={lineItem}
        dialog
        isNewLocation={isNewLocation}
        open={!!openUpdateListItemModal}
        quotationId={quotationId}
        quotationRoomId={openUpdateListItemModal}
        onClose={() => setOpenUpdateListItemModal('')}
      />
      <RoomImgDialog roomImgDialog={roomImgDialog} rooms={rooms} setRoomImgDialog={setRoomImgDialog} />
      <MoveLineItemToRoomModal
        handleClose={() => setOpenMoveListItemToNewRoomModal('')}
        lineItemId={lineItem?.id}
        open={!!openMoveListItemToNewRoomModal}
        roomId={openMoveListItemToNewRoomModal}
        rooms={rooms}
      />
      <MoveRoomUpOrDownModal
        handleClose={() => setOpenMoveRoomUpOrDownModal('')}
        lineItemId={lineItem?.id}
        open={!!openMoveRoomUpOrDownModal}
        roomId={openMoveRoomUpOrDownModal}
        rooms={rooms}
      />
    </>
  );
};
export default RoomList;
