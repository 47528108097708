import { Tiltability } from 'src/graphql/generated/schema';
import { CustomFieldsValueStrict, FilterConfig, FilterOptionConfig } from 'src/hooks/useFilterProgress';
import NarrowAngle from 'src/static/productFilter/beamAngle/narrow.png';
import WideAngle from 'src/static/productFilter/beamAngle/wide.png';

import { FilterOptionThumbnail } from './components/ProductFilterOption/ProductFilterOption';
import RetrofitFalse from './components/Retrofit/RetrofitFalse';
import RetrofitTrue from './components/Retrofit/RetrofitTrue';
import SeriesDetails from './components/Series/SeriesDetails';

const suspensionHeightRegex = /^(?<n>[0-9]+)(?<rest>-?.*)$/;

export const filterConfig: FilterConfig = {
  fixedStepTitles: new Map([
    [0, 'Selecteer de categorie'],
    [1, 'Selecteer het specifieke lamptype'],
  ]),
  newProductFilters: [
    {
      fieldName: 'retrofit',
      title: 'Hoe wil je het armatuur vervangen',
      description:
        'Een nieuw armatuur zorgt ervoor dat het verbruik van de lamp afneemt. ' +
        'Bij het hergebruiken van het armatuur zorgen oude componenten voor stroomverlies.',
      match: new Map([
        [false, { component: RetrofitFalse, title: 'Plaats nieuw armatuur' }],
        [true, { component: RetrofitTrue, title: 'Behoud huidig armatuur (retrofit)' }],
      ]),
      showThumbnail: false,
    },
    {
      fieldName: 'tiltability',
      title: 'Kantelbaar',
      match: new Map([
        [Tiltability.NotTiltable, { title: 'Nee' }],
        [Tiltability.Tiltable, { title: 'Ja' }],
        [Tiltability.FullyTiltable, { title: '360 °' }],
      ]),
    },
    {
      fieldName: 'suspensionHeights',
      title: 'Ophanghoogte',
      match: (value: CustomFieldsValueStrict): FilterOptionConfig => {
        const match = suspensionHeightRegex.exec(value.toString());
        const sortOrder = match?.groups?.n
          ? match?.groups?.rest
            ? Number(match?.groups?.n) + 0.1 // sort `6-9` after `6`
            : Number(match?.groups?.n)
          : undefined;
        return { title: `${value} meter`, sortOrder };
      },
    },
    {
      fieldName: 'series',
      title: 'Welk type armatuur wil je',
      description:
        'Een UGR paneel is speciaal ontwikkeld voor kantoren en zorgt voor minder lichthinder. ' +
        'Hiermee voldoet u aan de gestelde werkplek NEN-norm.',
      showIsEligibleForEia: true,
      match: new Map([
        ['Budget', { component: SeriesDetails, title: 'Budget' }],
        ['Standaard', { component: SeriesDetails, title: 'Standaard' }],
        ['Pro', { component: SeriesDetails, title: 'Pro' }],
        ['Pro-Line', { component: SeriesDetails, title: 'Pro-Line' }],
        ['UGR', { component: SeriesDetails, title: 'UGR' }],
        ['UGR Block', { component: SeriesDetails, title: 'UGR Block' }],
        ['Sensor', { component: SeriesDetails, title: 'Sensor' }],
      ]),
    },
    {
      fieldName: 'lightIntensityText',
      title: 'Lichtsterkte',
      match: new Map([
        ['weinig', { title: 'Weinig Licht' }],
        ['medium', { title: 'Gemiddeld Licht' }],
        ['veel', { title: 'Veel licht' }],
      ]),
    },
    {
      fieldName: 'wattage',
      title: 'Kies je nieuwe armatuur',
      showIsEligibleForEia: true,
      match: (value: CustomFieldsValueStrict): FilterOptionConfig => {
        // if (value === 150 || value === 18) return { title: `Standaard licht (${value} Watt)`, sortOrder: 0 };
        // if (value === 200 || value === 30) return { title: `Extra licht (${value} Watt)`, sortOrder: 1 };
        // return { title: `${value} Watt`, sortOrder: 2 };
        return { title: `${value} Watt` };
      },
    },
    // {
    //   fieldName: 'diameter',
    //   title: 'Welke afmeting heeft het armatuur',
    //   match: (value: CustomFieldsValueStrict): FilterOptionConfig => {
    //     if (value <= 84) return { title: '< 85mm', sortOrder: 0 };
    //     if (value >= 85 && value <= 109) return { title: '85 - 109mm', sortOrder: 1 };
    //     if (value >= 110 && value <= 119) return { title: '110 - 119mm', sortOrder: 2 };
    //     if (value >= 120 && value <= 169) return { title: '120 - 169mm', sortOrder: 3 };
    //     if (value >= 170 && value <= 195) return { title: '170 - 195mm', sortOrder: 4 };
    //     if (value >= 196 && value <= 250) return { title: '196 - 250mm', sortOrder: 5 };
    //     // If (value > 250) -> this is the default case
    //     return { title: '> 250mm', sortOrder: 6 };
    //   },
    // },
    {
      fieldName: 'diameter',
      title: 'Welke afmeting heeft het armatuur',
      match: (value: CustomFieldsValueStrict): FilterOptionConfig => {
        return { title: `Ø ${value} mm.` };
      },
    },
    {
      fieldName: 'beamAngle',
      title: 'Stralingsbundel',
      match: (value: CustomFieldsValueStrict): FilterOptionConfig => {
        if (value <= 15)
          return {
            title: `Zeer gericht (${value} °)`,
            thumbnailComponent: <FilterOptionThumbnail alt="Narrow angle" src={NarrowAngle} />,
            sortOrder: 2,
          };
        if (value >= 110)
          return {
            title: `Breed gericht (${value} °)`,
            thumbnailComponent: <FilterOptionThumbnail alt="Narrow angle" src={NarrowAngle} />,
            sortOrder: 0,
          };
        if (value < 110)
          return {
            title: `Gemiddeld gericht (${value} °)`,
            thumbnailComponent: <FilterOptionThumbnail alt="Wide angle" src={WideAngle} />,
            sortOrder: 1,
          };
        return { title: `${value} °` };
      },
    },
    {
      fieldName: 'fixtureColor',
      title: 'Armatuur kleur',
      match: new Map([
        ['Wit', { title: 'Wit' }],
        ['Zilver', { title: 'Zilver' }],
        ['Zwart', { title: 'Zwart' }],
      ]),
    },
    {
      fieldName: 'socket',
      title: 'Fitting',
      match: new Map([
        ['E14', { title: 'E14' }],
        ['E27', { title: 'E27' }],
        ['GU10', { title: 'GU10' }],
      ]),
    },
    {
      fieldName: 'tubeType',
      title: 'Kies je type buis',
      match: new Map([
        ['T5', { title: 'T5' }],
        ['T8', { title: 'T8' }],
      ]),
    },
    {
      fieldName: 'hasStarter',
      title: 'Heeft het armatuur een starter?',
      match: new Map([
        [true, { title: 'Ja' }],
        [false, { title: 'Nee' }],
      ]),
    },
  ],
  productVariantFilter: {
    title: 'Lichtkleur',
    match: (variant): FilterOptionConfig => {
      const count = variant.optionValues.length;
      const label = variant.optionValues.reduce(
        (acc, val, i) => `${acc ?? ''}${i === 0 ? '' : i === count - 1 ? ' & ' : ', '}${val.label ?? ''}`,
        '',
      );
      const getColorLabel = (option: string): string => {
        if (option.includes('3000')) return ' - Warm wit';
        if (option.includes('4000')) return ' - Natuurlijk wit';
        if (option.includes('5700') || option.includes('6000')) return ' - Helder wit';

        return '';
      };
      const title = label + getColorLabel(label) || (variant.sku ? `SKU: ${variant.sku}` : '[onbekend]');

      return {
        title,
        thumbnailUrl: variant.imageUrl ?? undefined,
      };
    },
  },
};
