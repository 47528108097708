import { useApolloClient } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { AUTH_SIGN_IN, AUTH_SIGN_OUT, ERROR_TRACE } from '../constants/localState';
import { ErrorMessages, SuccessMessages } from '../constants/messages';
import { UserRole } from '../graphql/generated/schema';
import { Routes } from '../pages/Routes';
export interface JwtToken {
  jwt: string;
}

export interface JwtRefreshToken {
  /** unique identifier of the `User` */
  id: string;
  aud: string;
  iat: number;
  exp: number;
  iss: string;
}
export interface JwtAccessToken extends JwtRefreshToken {
  role: Lowercase<UserRole>;
}

export interface SignInDto {
  email: string;
  password: string;
  companyNameOwner: string;
}

export interface AuthUser {
  token?: string;
  expiry?: Date;
  user?: {
    id: string;
    role: Lowercase<UserRole>;
  };
  headers?: HeadersInit;
}

export type Auth = [
  AuthUser | undefined,
  {
    signIn: (signInDto: SignInDto) => Promise<JwtToken | undefined>;
    signOut: () => Promise<void>;
    reSignIn: (signInDto: SignInDto) => Promise<void>;
    refresh: () => Promise<JwtToken | undefined>;
    // sendPasswordResetEmail: (email: string) => void;
    // confirmPasswordReset: (code: string, password: string) => void;
  },
];

// Internal context provided by <ProvideAuth /> and accessed via useAuth() hook
const AuthContext = createContext<Auth>([undefined, {}] as Auth);

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export const ProvideAuth: FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = (): Auth => {
  return useContext(AuthContext);
};

/**
 * External function overwritten by the useProvideAuth function from the `useAuth` hook
 */
export let refreshAuth = async (): Promise<JwtToken | undefined> => {
  // eslint-disable-next-line no-console
  console.error('refreshAuth() called before it was set');
  return;
};

/**
 * External function overwritten by the useProvideAuth function from the `useAuth` hook
 */
export let setSignedOut = async (): Promise<void> => {
  // eslint-disable-next-line no-console
  console.error('refreshFailed() called before it was set');
};

export let authUserRef: AuthUser | undefined;

// Provider hook that creates auth object and handles state
function useProvideAuth(): Auth {
  const [authUser, setAuthUser] = useState<AuthUser | undefined>(undefined);
  const [refreshTimer, setRefreshTimer] = useState<ReturnType<typeof setInterval>>();

  const client = useApolloClient();

  // Helper function to parse and decode a jwt
  const tokenToAuthUser = useCallback(
    ({ jwt }: JwtToken): AuthUser => {
      const payload = jwtDecode<JwtAccessToken>(jwt);
      const expiry = new Date(payload.exp * 1000);

      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
      // Refresh 10 seconds before the access token expires (with a minimum of 5 seconds)
      const offset = payload.exp * 1000 - new Date().getTime() - 10_000;
      setRefreshTimer(setTimeout(() => refreshAuth(), offset > 5_000 ? offset : 5_000));

      return {
        token: jwt,
        expiry,
        user: { id: payload.id, role: payload.role },
        headers: jwt ? { Authorization: `Bearer ${jwt}` } : undefined,
      };
    },
    [refreshTimer],
  );

  // Overwrite refreshAuth function, it can still be called from outside of the hook
  refreshAuth = useCallback(async () => {
    // Skip refresh if we are on the ErrorPage
    if (window.location.pathname === Routes.Error) return;

    // Attempt to refresh the access token by using the Refresh token (stored in a cookie)
    const response = await fetch('/api/auth/refresh', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    });
    if (!response.ok) {
      if (response.status >= 500) {
        const error = await response.text();
        sessionStorage.setItem(ERROR_TRACE, error);
        window.location.href = Routes.Error;
      }
      const error = await response.json();
      if (error.message === 'Refresh token was invalidated, sign in again.') {
        // Longer duration since the refresh is a background task and not a direct user action
        toast.error(ErrorMessages.AccountInvalidated, { duration: 10_000 });
      } else if (error.message === 'User is blocked') {
        // Longer duration since the refresh is a background task and not a direct user action
        toast.error(ErrorMessages.AccountBlocked, { duration: 10_000 });
      }
      // Do not show an error if (error.message === 'No refresh token found'), this is always the case on first load
      setSignedOut();
      return;
    }
    const token: JwtToken = await response.json();
    authUserRef = tokenToAuthUser(token);
    setAuthUser(authUserRef);
    return token;
  }, [tokenToAuthUser]);

  // Overwrite setSignedOut function. Function can also be called from outside the context (in createApolloClient)
  setSignedOut = useCallback(async () => {
    if (refreshTimer) clearTimeout(refreshTimer);

    // Reset the Apollo store (which clears the persisted cache)
    await client.resetStore();

    // Announce that the user is singed out, other tabs/ windows on the same device will listen to this event
    window.localStorage.setItem(AUTH_SIGN_OUT, Date.now().toString());

    authUserRef = { expiry: undefined, token: undefined, user: undefined };
    setAuthUser(authUserRef);
  }, [client, refreshTimer]);

  const signIn: Auth[1]['signIn'] = useCallback(
    async (signInDto) => {
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify(signInDto),
      });
      if (!response.ok) {
        if (response.status >= 500) {
          const error = await response.text();
          sessionStorage.setItem(ERROR_TRACE, error);
          window.location.href = Routes.Error;
          return;
        }
        const error = await response.json();
        if (error.message === 'Invalid credentials') {
          toast.error(ErrorMessages.UnknownCredentials);
        } else if (error.message === 'User is blocked') {
          toast.error(ErrorMessages.AccountBlocked);
        } else {
          toast.error(ErrorMessages.UnknownError);
        }
        return;
      }
      const token: JwtToken = await response.json();
      authUserRef = tokenToAuthUser(token);
      setAuthUser(authUserRef);
      window.localStorage.setItem(AUTH_SIGN_IN, Date.now().toString());
      return token;
    },
    [tokenToAuthUser],
  );

  const signOut: Auth[1]['signOut'] = useCallback(async () => {
    await fetch('/api/auth/sign-out', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    });
    await setSignedOut();
    toast.success(SuccessMessages.SignedOut);
  }, []);

  const reSignIn: Auth[1]['reSignIn'] = useCallback(
    async (signInDto) => {
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify(signInDto),
      });
      const token: JwtToken = await response.json();
      authUserRef = tokenToAuthUser(token);
      setAuthUser(authUserRef);
      toast.success(SuccessMessages.ReSignedIn);
    },
    [tokenToAuthUser],
  );

  // Listen to localStorage, if the AUTH_SIGN_OUT is updated, we should sign this window out as well
  useEffect(() => {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === AUTH_SIGN_OUT) {
        toast.success(SuccessMessages.SignedOut);
        authUserRef = { expiry: undefined, token: undefined, user: undefined };
        setAuthUser(authUserRef);
      } else if (event.key === AUTH_SIGN_IN) {
        toast.success(SuccessMessages.SignedIn);
        window.location.reload();
      }
    });
  }, []);

  // Refresh authentication on mount (if the useAuth hook is used)
  useEffect(() => {
    refreshAuth();
  }, []);

  return [
    authUser,
    {
      signIn,
      signOut,
      reSignIn,
      refresh: refreshAuth,
      // sendPasswordResetEmail,
      // confirmPasswordReset,
    },
  ];
}
