import { Grid } from '@mui/material';
import { useCallback, useState } from 'react';

import { GetQuotationQuery } from 'src/graphql/generated/operations';
import CardEditButton from 'src/pages/BackOffice/components/CardEditButton/CardEditButton';

import AddressBlock from '../AddressBlock/AddressBlock';
import QuotationAddContactDialog from './QuotationAddContactDialog/QuotationAddContactDialog';
import QuotationCreateContactDialog from './QuotationCreateContactDialog/QuotationCreateContactDialog';

interface Props {
  quotationData?: GetQuotationQuery;
  loadingQuotation?: boolean;
  quotationId?: string;
  isEditable?: boolean;
}

const QuotationContact = ({ quotationData, quotationId, loadingQuotation, isEditable }: Props): JSX.Element => {
  const [addContactDialog, setAddContactDialog] = useState(false);
  const [createQuotationContactDialog, setCreateQuotationContactDialog] = useState(false);

  const closeAddContactDialog = useCallback(() => {
    setAddContactDialog(false);
  }, []);

  // First close Add Contact Dialog before opening Create New Contact Dialog
  const openCreateContactDialog = useCallback(() => {
    closeAddContactDialog();
    setCreateQuotationContactDialog(true);
  }, [closeAddContactDialog]);

  // Reset Create New Contact Form excluded as it can be annoying when Dialog exited by accident
  const closeCreateContactDialog = useCallback(() => {
    setCreateQuotationContactDialog(false);
  }, []);

  const openAddContactDialog = useCallback(() => {
    setAddContactDialog(true);
  }, []);

  return (
    <>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={1}>
        {isEditable && <CardEditButton onClick={openAddContactDialog} />}
      </Grid>
      <Grid container item xs={7}>
        <AddressBlock
          address1={quotationData?.quotation.customer?.billingAddress?.address1}
          city={quotationData?.quotation.customer?.billingAddress?.city}
          fullName={quotationData?.quotation.customer?.fullName}
          heading={quotationData?.quotation.customer?.companyName ?? (isEditable ? 'Selecteer een contact' : undefined)}
          loading={loadingQuotation}
          postalCode={quotationData?.quotation.customer?.billingAddress?.postalCode}
        />
      </Grid>
      {quotationId && (
        <>
          <QuotationAddContactDialog
            addContactDialog={addContactDialog}
            closeAddContactDialog={closeAddContactDialog}
            openCreateContactDialog={openCreateContactDialog}
            quotationData={quotationData}
            quotationId={quotationId}
          />
          <QuotationCreateContactDialog
            closeCreateContactDialog={closeCreateContactDialog}
            createQuotationContactDialog={createQuotationContactDialog}
            quotationId={quotationId}
          />
        </>
      )}
    </>
  );
};

export default QuotationContact;
