import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CustomPaper from 'components/CustomPaper/CustomPaper';
import { useAddContactToQuotationMutation, useContactByNameLazyQuery } from 'src/graphql/generated/hooks';
import { DefaultContactWithAddressFieldsFragment, GetQuotationQuery } from 'src/graphql/generated/operations';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';

import AddressBlock from '../../AddressBlock/AddressBlock';

interface Props {
  quotationData?: GetQuotationQuery;
  quotationId: string;
  closeAddContactDialog: () => unknown;
  addContactDialog: boolean;
  openCreateContactDialog: () => void;
}

const QuotationAddContactDialog = ({
  quotationData,
  quotationId,
  closeAddContactDialog,
  addContactDialog,
  openCreateContactDialog,
}: Props): JSX.Element => {
  const [search, setSearch] = useState('');
  const [contact, setContact] = useState<DefaultContactWithAddressFieldsFragment>();
  const [showError, setShowError] = useState(false);

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [getContactByName, { data: contactByNameData, loading: loadingContactByName }] = useContactByNameLazyQuery();

  const [addQuotationContact, { data: addQuotationContactData, loading: loadingAddQuotationContact }] =
    useAddContactToQuotationMutation({ errorPolicy: 'all' });

  const debouncedGetContact = useMemo(() => {
    return debounce(getContactByName, 300);
  }, [getContactByName]);

  const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    if (newInputValue.length > 32) {
      setShowError(true);
    } else {
      setSearch(newInputValue);
      setShowError(false);
    }
  }, []);

  // Add/replace Contact from Autocomplete to Quotation and close Dialog when successful
  const handleAddContact = useCallback(async () => {
    if (!quotationId || !contact) return;
    await addQuotationContact({
      variables: {
        input: {
          quotationId,
          contactId: contact.id,
        },
      },
    });
  }, [addQuotationContact, contact, quotationId]);

  const closeSelf = useCallback(() => {
    setContact(undefined);
    setSearch('');
    closeAddContactDialog();
  }, [closeAddContactDialog]);

  useEffect(() => {
    if (!search || search.length > 32) return;
    debouncedGetContact({ variables: { input: { name: search } } });
  }, [search, debouncedGetContact, contact]);

  useEffect(() => {
    if (!addQuotationContactData) return;
    closeSelf();
  }, [addQuotationContactData, closeAddContactDialog, closeSelf]);

  return (
    <Dialog fullScreen={mediumScreen} fullWidth maxWidth="sm" open={addContactDialog} onClose={closeAddContactDialog}>
      <DialogTitle>
        <CardTitle color="primary" icon={<PersonIcon color="primary" />} title="Contact selecteren" />
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          PaperComponent={({ children, ...args }) => (
            <CustomPaper
              {...args}
              startIcon={<PersonAddIcon />}
              text="Nieuw contact toevoegen"
              onClick={openCreateContactDialog}
            >
              {children}
            </CustomPaper>
          )}
          getOptionLabel={(option) => `${option.fullName} - ${option.companyName}`}
          loading={loadingContactByName}
          loadingText="Zoeken..."
          noOptionsText="Typ om te zoeken zoeken"
          options={contactByNameData?.contactByName ?? []}
          renderInput={(params) => (
            <>
              {showError && (
                <Typography sx={{ color: 'red' }} variant="body1">
                  De zoekterm mag maximaal 32 karakters lang zijn.
                </Typography>
              )}

              <TextField
                {...params}
                error={showError}
                label="Zoek in het klantenbestand"
                placeholder="Zoek op voor-, achter- of bedrijfsnaam"
              />
            </>
          )}
          sx={{ mt: 2, mb: 3 }}
          onChange={(_, newValue) => {
            if (!newValue) return;
            setShowError(false);
            setContact(newValue);
          }}
          onInputChange={handleInputChange}
        />
        <AddressBlock
          address1={
            contact ? contact.billingAddress?.address1 : quotationData?.quotation.customer?.billingAddress?.address1
          }
          city={contact ? contact.billingAddress?.city : quotationData?.quotation.customer?.billingAddress?.city}
          fullName={contact ? contact.fullName : quotationData?.quotation.customer?.fullName}
          heading={contact || quotationData?.quotation.customer ? 'Bedrijfsgegevens' : ''}
          postalCode={
            contact ? contact.billingAddress?.postalCode : quotationData?.quotation.customer?.billingAddress?.postalCode
          }
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <DefaultButton
          disabled={loadingContactByName}
          id="cancelFindContact"
          sx={{
            flexGrow: 1,
          }}
          onClick={closeSelf}
        >
          Annuleren
        </DefaultButton>
        <LoadingButton
          color="primary"
          disabled={!contact}
          loading={loadingContactByName || loadingAddQuotationContact}
          sx={{
            flexGrow: 1,
          }}
          variant="contained"
          onClick={handleAddContact}
        >
          Opslaan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default QuotationAddContactDialog;
