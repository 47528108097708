import * as Sentry from '@sentry/react';

import { NewProductFragment, VariantFragment } from '../../graphql/generated/operations';
import { notMaybe } from '../../utils/types.helper';
import {
  FilterConfig,
  FilterOption,
  FilterOptionConfig,
  FilterStep,
  FilterStepOptions,
  ParsedFilterStepOptions,
  PartialBoolean,
  PartialCustomFieldFilter,
} from './useFilterProgress.types';

/** Ensure options is set and set default values where required */
export const parseOptions = (options?: FilterStepOptions): ParsedFilterStepOptions => ({
  isExpanded: options?.isExpanded ?? false,
  collapseOtherSteps: options?.collapseOtherSteps ?? true,
  shouldResetOnReselect: options?.shouldResetOnReselect ?? false,
  remainingProducts: options?.remainingProducts,
  productVariant: options?.productVariant,
});

/** Callback function to collapse every step (set `isExpanded` to `false`) */
export const collapse = (step: FilterStep): FilterStep => ({ ...step, isExpanded: false });

/** Get the next step */
export const getNextStep = (
  onSelect: FilterOption['onSelect'],
  filters: FilterConfig['newProductFilters'],
  selectedFilters: string[],
  remainingProducts: readonly NewProductFragment[],
  init?: Partial<FilterStep>,
): FilterStep => {
  let options: FilterOption[] = [];
  let filter: PartialCustomFieldFilter = { title: init?.title ?? '...' };
  const orderedRemainingProducts = [...remainingProducts].sort((a, b) => a.sortOrder - b.sortOrder);

  for (const currFilter of filters) {
    if (selectedFilters.includes(currFilter.fieldName)) continue;
    // Get unique values
    const uniqueValues = new Set( // Set to get the unique values for this filter
      orderedRemainingProducts
        .map((p) => p.customFields[currFilter.fieldName]) // Get the value of the customField for this filter
        .filter(notMaybe) // Remove `undefined` and `null` values
        .flat() // Some values might be arrays themselves
        .map((fieldName) => (typeof fieldName === 'string' ? fieldName.trim() : fieldName)),
    );

    // This filter won't limit the limit the size of `remainingProducts` array
    // if there are less than 2 unique values for this customField, so continue to the next filter.
    if (uniqueValues.size < 2) continue;

    // Check which options would limit the size of `remainingProducts`
    if (typeof currFilter.match === 'function') {
      // Get matchMap by running the unique values through the match function
      // and collecting remaining products for each filter option
      const matchMap = new Map<string, { config: FilterOptionConfig; products: NewProductFragment[] }>();
      for (const uniqueValue of uniqueValues.values()) {
        const config = currFilter.match(uniqueValue);
        if (!config) continue;
        const products = orderedRemainingProducts.filter((p) => {
          const pFilterKey = p.customFields[currFilter.fieldName];
          const pKey = typeof pFilterKey === 'string' ? pFilterKey.trim().toLowerCase() : pFilterKey;
          // For suspension heights
          if (Array.isArray(pKey)) return pKey.some((pKeyItem) => uniqueValue === pKeyItem);
          else return uniqueValue === pKey;
        });
        const existing = matchMap.get(config.title);
        if (existing) existing.products.push(...products);
        else matchMap.set(config.title, { config, products });
      }

      // Skip this filter returns in just 1 option as it would not make the set of remaining products smaller
      if (matchMap.size <= 1) continue;

      // Update the iterator of the matchMap to sort by sortOrder (see: https://stackoverflow.com/a/48324540)
      // Spreading the matchMap (`...matchMap`) will implicitly call the newly defined iterator function and sort the created array
      matchMap[Symbol.iterator] = function* () {
        yield* [...this.entries()].sort((a, b) =>
          a[1].config.sortOrder !== undefined && b[1].config.sortOrder !== undefined
            ? a[1].config.sortOrder - b[1].config.sortOrder
            : 0,
        );
      };

      options = [...matchMap].map(([, { config, products }]) => {
        let isEligibleForEia: PartialBoolean | undefined = undefined;
        if (currFilter.showIsEligibleForEia) {
          const productsEligibleForEia = products.reduce(
            (acc, product) => acc + (product.customFields.isEligibleForEia ? 1 : 0),
            0,
          );
          isEligibleForEia =
            productsEligibleForEia === 0
              ? false // None are eligible
              : productsEligibleForEia === products.length
              ? true // All are eligible
              : 'some'; // Some are eligible
        }

        return {
          title: config.title,
          value: config.title,
          component: config.component,
          showThumbnail: currFilter.showThumbnail,
          thumbnailComponent: config.thumbnailComponent,
          thumbnailUrl: config.thumbnailUrl,
          isEligibleForEia,
          remainingProductsAfterSelection: products,
          onSelect: (value, options) => onSelect?.(value, { ...options, remainingProducts: [...products] }),
        };
      });
    } else if (currFilter.match) {
      // Get the options by matching the unique values to the keys of the Map
      for (const [filterKey, config] of currFilter.match.entries()) {
        const key = typeof filterKey === 'string' ? filterKey.trim() : filterKey;

        // Skip this key if it does not exist in the uniqueValues set
        if (!uniqueValues.has(key)) continue;

        const remainingProductsAfterSelection = orderedRemainingProducts.filter((p) => {
          const pFilterKey = p.customFields[currFilter.fieldName];
          const pKey = typeof pFilterKey === 'string' ? pFilterKey.trim() : pFilterKey;
          return key === pKey;
        });

        let isEligibleForEia: PartialBoolean | undefined = undefined;
        if (currFilter.showIsEligibleForEia) {
          const remainingProductsEligibleForEia = remainingProductsAfterSelection.reduce(
            (acc, product) => acc + (product.customFields.isEligibleForEia ? 1 : 0),
            0,
          );
          isEligibleForEia =
            remainingProductsEligibleForEia === 0
              ? false // None are eligible
              : remainingProductsEligibleForEia === remainingProductsAfterSelection.length
              ? true // All are eligible
              : 'some'; // Some are eligible
        }

        options.push({
          title: config.title,
          value: key,
          component: config.component,
          showThumbnail: currFilter.showThumbnail,
          thumbnailComponent: config.thumbnailComponent,
          thumbnailUrl: config.thumbnailUrl,
          isEligibleForEia,
          remainingProductsAfterSelection,
          onSelect: (value, options) =>
            onSelect?.(value, { ...options, remainingProducts: [...remainingProductsAfterSelection] }),
        });
      }
    }

    // Continue to the next filter if there is only one option, or no options at all
    if (options.length <= 1) continue;

    // Add this filter and break the loop. This filter should show up next
    filter = { ...currFilter };

    break;
  }

  // Edge case: there were not enough filters to limit the `remainingProducts` to one final product
  // Solution: Show all remaining products as a list and let the user choose
  if (options.length === 0) {
    if (orderedRemainingProducts.length !== 1) {
      Sentry.captureMessage('All filters exhausted, showing a full product list as a last resort', {
        extra: { remainingProducts },
      });
    }
    filter = {
      title: 'Kies een product',
      description:
        orderedRemainingProducts.length !== 1
          ? 'Er zijn geen filters meer beschikbaar, kies een van deze producten.'
          : undefined,
      showIsEligibleForEia: true,
    };
    options = orderedRemainingProducts.map((product) => ({
      title: product.name,
      value: product.id,
      thumbnailUrl: product.thumbnail?.urlThumbnail,
      isEligibleForEia: !!product.customFields.isEligibleForEia,
      remainingProductsAfterSelection: [product],
      onSelect: (value, options) => onSelect?.(value, { ...options, remainingProducts: [product] }),
    }));
  }

  return {
    isExpanded: init?.isExpanded ?? true,
    title: filter.title,
    filter: filter ?? init?.filter,
    remainingProducts: orderedRemainingProducts,
    options,
  };
};

export const getProductVariantsStep = (
  onSelect: FilterOption['onSelect'],
  filter: FilterConfig['productVariantFilter'],
  remainingProduct: NewProductFragment,
  productVariants: VariantFragment[],
): FilterStep | undefined => {
  const sortedProductVariants = [...productVariants].sort((a, b) => {
    const labelA = a.optionValues?.[0]?.label;
    const labelB = b.optionValues?.[0]?.label;
    return labelA && labelB ? labelA.localeCompare(labelB) : 0;
  });
  const options: FilterOption[] = sortedProductVariants.map((productVariant) => {
    const match = filter.match?.(productVariant);

    return {
      title: match?.title ?? '[onbekend]',
      value: productVariant.id,
      remainingProductsAfterSelection: [remainingProduct],
      productVariantAfterSelection: productVariant,
      onSelect: (value, selectOptions) =>
        onSelect?.(value, { ...selectOptions, remainingProducts: [remainingProduct], productVariant }),
      thumbnailUrl: match?.thumbnailUrl,
    };
  });

  // Do not add a step if there is nothing to choose
  if (options.length === 0) {
    return;
  }

  return {
    isExpanded: options.length !== 0,
    title: filter.title,
    // choice: options.length === 1 ? options[0].value : undefined, // Auto select if there is only one option
    remainingProducts: [remainingProduct],
    options,
  };
};
