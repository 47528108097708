import '../node_modules/react-vis/dist/style.css';

import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import PageLoader from 'components/PageLoader/PageLoader';

import { createApolloClient } from './config/createApolloClient';
import { initialUserSettings, UserSettings } from './config/UserSettings';
import { createAppTheme } from './globalStyles/appTheme';
import { ProvideAuth } from './hooks/useAuth.hook';
import Pages from './pages/Pages';

function App(): JSX.Element {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const [settings, setSettings] = useState(initialUserSettings);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const isDarkMode = useMemo(
    () => (settings.appThemeMode === 'auto' ? prefersDarkMode : settings.appThemeMode === 'dark'),
    [prefersDarkMode, settings.appThemeMode],
  );
  const theme = useMemo(() => createAppTheme(isDarkMode), [isDarkMode]);

  useEffect(() => {
    createApolloClient().then((client) => setClient(client));
  }, []);

  if (client === undefined) {
    return <PageLoader />;
  }

  return (
    <UserSettings.Provider value={[settings, setSettings]}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster />
        <ApolloProvider client={client}>
          <ProvideAuth>
            <Suspense fallback={<PageLoader />}>
              <Pages />
            </Suspense>
          </ProvideAuth>
        </ApolloProvider>
      </ThemeProvider>
    </UserSettings.Provider>
  );
}

export default App;
