import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress, debounce, IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import ReactSignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-canvas';

import { ErrorMessages, SuccessMessages } from 'src/constants/messages';
import { UPLOAD_SIGNATURE_AFTER_MS } from 'src/constants/settings';
import { useGetQuotationSignatureQuery, useUpdateQuotationSignatureMutation } from 'src/graphql/generated/hooks';

import { QUOTATION_PADDING, QUOTATION_WIDTH } from '../../Quotation';

interface Params {
  quotationId: string;
}

interface Props {
  isEditable: boolean;
  isSigning: boolean;
  setIsSigning: React.Dispatch<React.SetStateAction<boolean>>;
}

type ProgressState = 'hidden' | 'empty' | 'show';

const Signature = ({ isEditable, isSigning, setIsSigning }: Props): JSX.Element => {
  const mountedRef = useRef(false);

  const progressEmptyTimeout = useRef<ReturnType<typeof setInterval>>();
  const progressShowTimeout = useRef<ReturnType<typeof setInterval>>();

  const { quotationId } = useParams<Params>();
  const [progressState, setProgressState] = useState<ProgressState>('hidden');

  const { data } = useGetQuotationSignatureQuery({
    variables: { input: { quotationId } },
    fetchPolicy: 'cache-and-network',
  });

  const [updateQuotationSignature, { loading }] = useUpdateQuotationSignatureMutation({
    update(_, { errors }) {
      setProgressState('hidden');
      if (errors) toast.error(ErrorMessages.UnknownError, { duration: 4000 });
    },
  });

  const signPad = useRef<ReactSignatureCanvas>(null);

  const clear = useCallback(async () => {
    setProgressState('hidden');
    signPad.current?.clear();
    signPad.current?.on();
    const { data } = await updateQuotationSignature({
      variables: { input: { quotationId, signature: '' } },
      optimisticResponse: {
        UpdateQuotationSignature: { __typename: 'Quotation', id: quotationId, signature: '', isEditable: true },
      },
    });
    if (!mountedRef.current || !data) return;
    toast.success(SuccessMessages.SignatureCleared);
  }, [updateQuotationSignature, quotationId]);

  const save = useCallback(async () => {
    setIsSigning(false);
    const signature = signPad.current ? signPad.current?.toDataURL() : '';
    const { data } = await updateQuotationSignature({ variables: { input: { quotationId, signature } } });
    if (!mountedRef.current || !data) return;
    toast.success(SuccessMessages.SignatureCreated);
  }, [quotationId, setIsSigning, updateQuotationSignature]);

  const debouncedSave = useMemo(() => debounce(save, UPLOAD_SIGNATURE_AFTER_MS), [save]);

  useEffect(() => {
    signPad.current?.clear();

    if (data?.quotation.signature) {
      signPad.current?.fromDataURL(data?.quotation.signature);
      signPad.current?.off();
    }
  }, [data?.quotation.signature]);

  // Enable editing when the signPad is empty AND the quotation is editable, disable otherwise
  useEffect(() => {
    if (signPad.current?.isEmpty() && data?.quotation.isEditable) signPad.current.on();
    else signPad.current?.off();
  }, [data?.quotation.isEditable]);

  // effect just for tracking mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        my: '2rem',
        width: `${QUOTATION_WIDTH - 2 * QUOTATION_PADDING}px`,
        position: 'relative',
        mx: 'auto',
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      {isSigning && progressState !== 'hidden' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: progressState === 'empty' ? '0px' : '100%',
            height: '2px',
            backgroundColor: 'rgba(19, 190, 55, 0.5)',
            transition: `width ${UPLOAD_SIGNATURE_AFTER_MS - 200}ms ease-in-out`,
          }}
        />
      )}
      <Box
        sx={{
          background: 'rgba(1, 1, 1, 0.02)',
          backgroundImage:
            data?.quotation.signature || loading
              ? 'linear-gradient(135deg, rgba(19, 190, 55, 0.25) 4.55%, rgba(1, 1, 1, 0.02) 4.55%, rgba(1, 1, 1, 0.02) 50%, rgba(19, 190, 55, 0.25) 50%, rgba(19, 190, 55, 0.25) 54.55%, rgba(1, 1, 1, 0.02) 54.55%, rgba(1, 1, 1, 0.02) 100%)'
              : '',
          backgroundSize: '15.56px 15.56px',
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '30%',
            left: '50%',
          }}
        >
          {loading && <CircularProgress color="primary" />}
        </Box>
        {isEditable && (
          <IconButton
            color="primary"
            disabled={isSigning || signPad.current?.isEmpty()}
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={clear}
          >
            <DeleteIcon />
          </IconButton>
        )}
        <SignaturePad
          canvasProps={{ width: QUOTATION_WIDTH - 2 * QUOTATION_PADDING, height: 200, className: 'sigCanvas' }}
          ref={signPad}
          onBegin={() => setIsSigning(true)}
          onEnd={() => {
            // Clear any existing timeouts
            if (progressEmptyTimeout.current) clearTimeout(progressEmptyTimeout.current);
            if (progressShowTimeout.current) clearTimeout(progressShowTimeout.current);

            setProgressState('hidden'); // Reset immediately (by hiding it)
            progressEmptyTimeout.current = setTimeout(() => setProgressState('empty'), 100); // Set to empty after 100ms
            progressShowTimeout.current = setTimeout(() => setProgressState('show'), 200); // Start the transition to 'show' after 200ms
            return debouncedSave();
          }}
        />
      </Box>
    </Box>
  );
};

export default Signature;
