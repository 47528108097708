import { Reference, StoreObject } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';

import { SuccessMessages } from '../../constants/messages';
import {
  GetQuotationDocument,
  useGetQuotationQuery,
  useSetQuotationLineItemMutation,
} from '../../graphql/generated/hooks';
import {
  NewProductFragment,
  OldProductFragment,
  QuotationLineItemFragment,
  VariantFragment,
} from '../../graphql/generated/operations';
import { InputSetQuotationLineItem } from '../../graphql/generated/schema';
import useBreakpointDown from '../../hooks/useBreakpointDown.hook';
import { FilterChangeType, FilterState, InitialFilter } from '../../hooks/useFilterProgress';
import ProductFilter from './ProductFilter/ProductFilter';
import ProductOptions from './ProductOptions/ProductOptions';

interface Props {
  quotationId: string;
  quotationRoomId: string;
  isNewLocation?: boolean;
  currentLineItem?: QuotationLineItemFragment;
  onClose?: () => void;
}

interface DialogProps extends Props {
  dialog: boolean;
  open: boolean;
}

export type LineItem = InputSetQuotationLineItem & {
  oldProduct?: OldProductFragment;
  product?: NewProductFragment;
  productVariant?: VariantFragment;
  filterState?: FilterState;
};

export type OtherFields = Pick<
  InputSetQuotationLineItem,
  'otherComments' | 'outsideSize' | 'ceilingType' | 'connectionType'
>;

export default function SetLineItem(props: Props): JSX.Element;
export default function SetLineItem(props: DialogProps): JSX.Element;

export default function SetLineItem({
  quotationId,
  currentLineItem,
  quotationRoomId,
  isNewLocation = false,
  onClose,
  ...dialogProps
}: Props | DialogProps): JSX.Element {
  const dialog = 'dialog' in dialogProps ? dialogProps.dialog : false;
  const open = 'open' in dialogProps ? dialogProps.open : true;

  const mediumScreen = useBreakpointDown();

  const otherFields = useRef<OtherFields>({});
  const [lineItem, setLineItem] = useState<LineItem | undefined>();
  const [step, setStep] = useState<number>();

  /** When updating, is the original product being updated (`true`) or did the user switch to another product (`false`) */
  const [updateSameProduct, setUpdateSameProduct] = useState(false);

  const { data } = useGetQuotationQuery({
    variables: { input: { quotationId } },
    fetchPolicy: 'cache-and-network',
  });

  const [setQuotationLineItem, { loading }] = useSetQuotationLineItemMutation({
    refetchQueries: [{ query: GetQuotationDocument, variables: { input: { quotationId } } }],
    update(cache, { errors, data: updatedLineItem }) {
      if (errors || !updatedLineItem) return;
      cache.modify({
        id: `QuotationRoom:${updatedLineItem.setQuotationLineItem.room.id}`,
        fields: {
          lineItems(existingLineItemRefs = [], { readField }) {
            if (
              existingLineItemRefs.some(
                (ref?: Reference | StoreObject) => readField('id', ref) === updatedLineItem.setQuotationLineItem.id,
              )
            ) {
              return existingLineItemRefs;
            }
            return [...existingLineItemRefs, updatedLineItem];
          },
        },
      });
      toast.success(SuccessMessages.ProductAdded);
      onClose?.();
    },
  });

  // Clear the product and productVariant if the filter resets
  const onChange = useCallback((filterState: FilterState) => {
    if (filterState.lastChange.type === FilterChangeType.FinalProductSelected) return;

    setStep((currentStep) => {
      // If going back mark updateSameProduct as false to indicate the user switched to another
      // product and current metadata/options no longer apply
      if (currentStep && filterState.lastChange.step && currentStep > filterState.lastChange.step) {
        setUpdateSameProduct(false);
      }
      return currentStep && filterState.lastChange.step
        ? currentStep > filterState.lastChange.step
          ? currentStep
          : filterState.lastChange.step
        : undefined;
    });
    setLineItem(undefined);
    otherFields.current = {};
  }, []);

  // Parse filter choices
  const initialFilterChoices: InitialFilter[] = useMemo(
    () => (currentLineItem?.filterChoices ? JSON.parse(currentLineItem.filterChoices) : undefined),
    [currentLineItem?.filterChoices],
  );

  // Set the final product (and possibly the variant) when the filter is finished
  const onFinished = useCallback(
    (
      filterState: FilterState,
      oldProduct: OldProductFragment,
      product: NewProductFragment,
      productVariant?: VariantFragment,
    ) => {
      const filterChoices = filterState.progress.map<InitialFilter>((step, i) => ({
        filter: step.productVariant ? 'productVariant' : step.filter?.fieldName ?? i,
        choice: step.choice,
      }));

      setLineItem({
        quotationLineItemId: currentLineItem?.id,
        product,
        productVariant,
        oldProduct,
        oldProductId: oldProduct.id,
        oldProductName: oldProduct.name,
        oldProductQuantity: product.customFields.retrofitQuantity,
        oldProductWattage: isNewLocation
          ? 0
          : updateSameProduct
          ? currentLineItem?.oldProductWattage ?? oldProduct.customFields.wattage
          : oldProduct.customFields.wattage,
        productId: product.id,
        name: product.name,
        wattage: product.customFields.wattage,
        assemblyCost: updateSameProduct
          ? currentLineItem?.assemblyCost ?? product.customFields.assemblyCost ?? 0
          : product.customFields.assemblyCost ?? 0,
        serviceCost: updateSameProduct
          ? currentLineItem?.serviceCost ?? product.customFields.serviceCost ?? 0
          : product.customFields.serviceCost ?? 0,

        quotationRoomId,
        filterChoices: JSON.stringify(filterChoices),
        productVariantId: productVariant?.id,
        productVariantLabel: productVariant?.optionValues[0]?.label,
        quantity: updateSameProduct ? currentLineItem?.quantity ?? 1 : 1, // Initial value
        ceilingType: currentLineItem?.ceilingType,
        outsideSize: currentLineItem?.outsideSize,
        connectionType: currentLineItem?.connectionType,
        otherComments: currentLineItem?.otherComments,
      });
      otherFields.current = {
        ceilingType: currentLineItem?.ceilingType,
        outsideSize: currentLineItem?.outsideSize,
        connectionType: currentLineItem?.connectionType,
        otherComments: currentLineItem?.otherComments,
      };
      setStep(filterState.progress.length);
    },
    [currentLineItem, isNewLocation, quotationRoomId, updateSameProduct],
  );

  const submit = useCallback(() => {
    if (!lineItem) return;

    // Strip `product`, `productVariant` and `oldProduct` properties from `lineItem`
    const { product, productVariant, oldProduct, ...input } = lineItem;
    input.ceilingType = otherFields.current?.ceilingType || null;
    input.outsideSize = otherFields.current?.outsideSize || null;
    input.connectionType = otherFields.current?.connectionType || null;
    input.otherComments = otherFields.current?.otherComments || null;
    setQuotationLineItem({ variables: { input } });
  }, [lineItem, setQuotationLineItem, otherFields]);

  const scrollToLastSelection = useCallback(
    (): NodeJS.Timeout =>
      setTimeout((): void => {
        const modalContent = document.querySelector('.MuiDialogContent-root');

        modalContent &&
          modalContent.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
      }, 0),
    [],
  );

  // When provided with a (new) currentLine item (i.e. update mode)
  // set the updateSameProduct to `true` if a current line item is provided
  useEffect(() => {
    setUpdateSameProduct(!!currentLineItem);
  }, [currentLineItem]);

  useEffect(() => {
    if (step) {
      scrollToLastSelection();
    }
  }, [scrollToLastSelection, step]);

  if (dialog) {
    return (
      <Dialog
        PaperProps={{ sx: { height: '100%' } }}
        fullScreen={mediumScreen}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <CardTitle color="primary" title={currentLineItem ? 'Product aanpassen' : 'Product toevoegen'} />
        </DialogTitle>
        <DialogContent>
          <ProductFilter initialFilterChoices={initialFilterChoices} onChange={onChange} onFinished={onFinished} />
          {lineItem?.productId && step && (
            <ProductOptions
              isNewLocation={isNewLocation}
              laasToPriceFactor={data?.quotation.laasToPriceFactor}
              lineItem={lineItem}
              otherFields={otherFields}
              paymentType={data?.quotation.paymentType}
              setLineItem={setLineItem}
              step={step}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <DefaultButton disabled={loading} id="cancel" sx={{ flexGrow: 1 }} onClick={onClose}>
            Annuleren
          </DefaultButton>
          <LoadingButton
            disabled={!lineItem?.productId}
            loading={loading}
            sx={{ flexGrow: 1 }}
            variant="contained"
            onClick={submit}
          >
            {currentLineItem ? 'Opslaan' : 'Toevoegen'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }

  // Non-dialog version
  return (
    <>
      <Typography color="primary.main" gutterBottom sx={{ margin: '1.5rem 0 5rem 1rem' }} variant="h3">
        Product toevoegen
      </Typography>
      <IconButton
        aria-label="close-product-filter"
        sx={{ display: 'flex', alignSelf: 'flex-end', mt: 4, mr: 3, p: 0, position: 'absolute' }}
        onClick={() => onClose?.()}
      >
        <CloseIcon />
      </IconButton>
      <ProductFilter initialFilterChoices={initialFilterChoices} onChange={onChange} onFinished={onFinished} />
      {lineItem?.productId && step && (
        <ProductOptions
          isNewLocation={isNewLocation}
          laasToPriceFactor={data?.quotation.laasToPriceFactor}
          lineItem={lineItem}
          otherFields={otherFields}
          paymentType={data?.quotation.paymentType}
          setLineItem={setLineItem}
          step={step}
        />
      )}
      <LoadingButton
        disabled={!lineItem?.productId}
        fullWidth
        loading={loading}
        size="large"
        sx={{ mt: 4, mb: 2 }}
        variant="contained"
        onClick={submit}
      >
        {currentLineItem ? 'Opslaan' : 'Toevoegen'}
      </LoadingButton>
    </>
  );
}
