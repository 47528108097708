import { SvgIcon, SvgIconProps } from '@mui/material';

function EiaCheckIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 184.49 165.86" {...props}>
      <path
        d="M39.08 165.86c-1.53-.14-3.53-.23-5.5-.51-7.46-1.07-13.26-4-16.59-11.56C8.72 135 1.08 116.15.08 95.27c-.31-6.16 2.19-10.14 7.32-13a76.37 76.37 0 0 1 20.46-7.66c6.17-1.37 9.29.28 11.82 6.21 2.22 5.19 3.93 10.6 6.2 15.76 2.67 6.08 4.55 6.26 8.31.77 9-13.17 18.35-26.15 28.47-38.52 15.12-18.46 31.16-36 50.9-49.74a48.53 48.53 0 0 1 31-9.05c4.82.25 9.66.14 14.47.51 5.37.43 7.06 3.63 4 8.11a148.26 148.26 0 0 1-10.09 12.36c-30.39 35.33-61 70.46-88.81 107.92-4.85 6.54-10.24 12.94-13.71 20.2-6.49 13.48-17.73 16.5-31.34 16.72z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default EiaCheckIcon;
