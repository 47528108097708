import { Maybe } from '../graphql/generated/schema';

export type Exclude<T, E> = T extends E ? never : T;

interface NumberRange {
  min: number;
  max: number;
}

interface RangeStringOptions {
  transformFn?: (n: number) => string;
  unknownDescription?: string;
}

interface ClampOptions {
  min?: number;
  max?: number;
}

export const range = <T, K extends string & keyof T>(
  array: T[],
  key: K & T[K] extends number ? K : never | ((t: T) => number | Maybe<number> | undefined),
): NumberRange | undefined => {
  let min = +Infinity;
  let max = -Infinity;

  const length = array.length;
  if (length === 0) return undefined;

  for (let i = 0; i < length; i++) {
    const value = typeof key === 'function' ? key(array[i]) : Number(array[i][key as keyof T]);
    if (value === null || value === undefined) continue;
    if (value < min) min = value;
    if (value > max) max = value;
  }

  min = min === Infinity ? max : min;
  max = max === Infinity ? min : max;

  return { min, max };
};

const defaultTransformFn = (n: number): string => n.toString();

export const rangeString = <T, K extends string & keyof T>(
  array: T[] | undefined,
  key: K & T[K] extends number ? K : never | ((t: T) => number | Maybe<number> | undefined),
  options?: RangeStringOptions,
): string => {
  const unknownDescription = options?.unknownDescription ?? '[unknown]';
  const transformFn = options?.transformFn ?? defaultTransformFn;

  if (array === undefined) return unknownDescription;
  const rangeObj = range(array, key);
  if (!rangeObj) return unknownDescription;
  if (rangeObj.min === rangeObj.max) return transformFn(rangeObj.min);
  return `${transformFn(rangeObj.min)} - ${transformFn(rangeObj.max)}`;
};

/**
 * Keep a number between a min and max value (including min and max)
 * @param n The number to clamp
 * @param clampOptions Min: The smallest possible number (inclusive), Max: The biggest possible number (inclusive)
 */
export const clamp = (n: number, { min, max }: ClampOptions): number => {
  if (min !== undefined && n < min) {
    return min;
  } else if (max !== undefined && n > max) {
    return max;
  } else if (isNaN(n) || n === null) {
    throw new Error('Cannot clamp NaN');
  } else {
    return n;
  }
};

export const dutchNumberFormat = new Intl.NumberFormat('nl-NL');

export const roundNumber = (n: number, precision = 2): number =>
  Math.round(n * Math.pow(10, precision)) / Math.pow(10, precision);

export const round = (n: number, precision = 2): string => dutchNumberFormat.format(roundNumber(n, precision));

/** Format energy usage (in kWh) to a string with Dutch formatting */
export const energyUsage = (n: number, precision = 2): string => `${round(n, precision)} kWh`;
