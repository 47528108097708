import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { List, ListItem, ListItemIcon, ListItemText, SxProps, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router';

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { UserSettings } from 'src/config/UserSettings';
import { Routes } from 'src/pages/Routes';

const listItemSx: (isDrawerMinimized: boolean) => SxProps = (isDrawerMinimized) => ({
  '& *': {
    fontSize: '14px',
    color: '#87919D',
    minWidth: 'auto',
    marginLeft: isDrawerMinimized ? '1px' : 0,
  },
  ':hover': {
    cursor: 'pointer',
    '& .MuiListItemIcon-root': {
      '& *': {
        color: 'primary.main',
      },
    },
  },
});

const NavLinks = (): JSX.Element => {
  const history = useHistory();
  const [{ isDrawerMinimized }] = useContext(UserSettings);

  return (
    <List sx={{ margin: '4px' }}>
      <ConditionalWrapper
        condition={isDrawerMinimized}
        wrapper={(children) => (
          <Tooltip placement="right" title="Check App">
            {children}
          </Tooltip>
        )}
      >
        <ListItem sx={listItemSx(isDrawerMinimized)} onClick={() => history.push(Routes.CheckIn)}>
          {!isDrawerMinimized && <ListItemText primary="Check App" />}
          <ListItemIcon>
            <OpenInNewIcon fontSize="small" />
          </ListItemIcon>
        </ListItem>
      </ConditionalWrapper>
    </List>
  );
};

export default NavLinks;
