import { InMemoryCache, makeVar } from '@apollo/client';

import { TypedTypePolicies } from '../graphql/generated/apolloHelpers';

export const isPageLoadingVar = makeVar<boolean>(false);

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      taxBracket: {
        merge: false,
      },
    },
  },
  User: {
    fields: {
      lastEditedContacts: { merge: false },
    },
  },
  Quotation: {
    fields: {
      rooms: { merge: false },
      changelog: { merge: false },
    },
  },
  QuotationRoom: {
    fields: {
      lineItems: { merge: false },
    },
  },
  Product: {
    fields: {
      variants: { merge: false },
    },
  },
  ProductVariant: {
    fields: {
      optionValues: { merge: false },
    },
  },
  TaxBracket: {
    keyFields: ['min_usage_kwh'],
  },
  AppSettings: {
    fields: {
      defaultTaxBracket: {
        merge: false,
      },
    },
  },
};

export const cache = new InMemoryCache({
  typePolicies,
});
