export enum FilterChangeType {
  Init = 'init',
  OldCategoriesLoaded = 'oldCategoriesLoaded',
  OldProductsLoaded = 'oldProductsLoaded',
  NewProductsLoaded = 'newProductsLoaded',
  ProductVariantsLoaded = 'productVariantsLoaded',
  NewOptionSelected = 'newOptionSelected',
  SameOptionReselected = 'sameOptionReselected',
  ExpandOne = 'expandOne',
  CollapseOne = 'collapseOne',
  ExpandAll = 'expandAll',
  CollapseAll = 'collapseAll',
  OptionChanged = 'optionChanged',
  FinalProductSelected = 'finalProductSelected',
}
