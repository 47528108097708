import { Box, SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import { GetQuotationQuery } from 'src/graphql/generated/operations';
import { currency } from 'src/utils/currency.helper';

interface QuotationSummaryProps {
  quotation: GetQuotationQuery['quotation'];
  isEditable?: boolean;
}

const typoJustifyBetween: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '.5rem 4rem',
  width: '100%',
};

const QuotationSummary: FC<QuotationSummaryProps> = ({ quotation }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', mt: '-1rem' }}>
      <Box width="100%">
        <Box sx={typoJustifyBetween}>
          <Typography variant="subtitle2">Subtotaal</Typography>
          <Typography variant="subtitle2">{currency(quotation.totalCostWithoutDiscount)}</Typography>
        </Box>
        {quotation.calculatedDiscountFixed ? (
          <Box sx={typoJustifyBetween}>
            <Typography variant="subtitle2">
              {quotation.calculatedDiscountFixed >= 0 ? 'Korting' : 'Meerprijs'}
            </Typography>
            <Typography variant="subtitle2">
              {quotation.calculatedDiscountFixed >= 0
                ? `- ${currency(quotation.calculatedDiscountFixed)}`
                : currency(quotation.calculatedDiscountFixed * -1)}
            </Typography>
          </Box>
        ) : null}
        <Box sx={typoJustifyBetween}>
          <Typography variant="body1">Totaal (excl. BTW)</Typography>
          <Typography variant="body1">{currency(quotation.totalCost)}</Typography>
        </Box>
        <Box sx={typoJustifyBetween}>
          <Typography variant="subtitle2">21% BTW</Typography>
          <Typography variant="subtitle2">{currency(quotation.totalCostIncl - quotation.totalCost)}</Typography>
        </Box>
        <Box sx={typoJustifyBetween}>
          <Typography variant="subtitle2">Totaal (incl. BTW)</Typography>
          <Typography variant="subtitle2">{currency(quotation.totalCostIncl)}</Typography>
        </Box>
        {(quotation.totalServiceCost > 0 || quotation.totalSmartFee > 0) && (
          <Box width="100%">
            <hr style={{ margin: '.5rem 3.5rem' }} />
          </Box>
        )}
        {quotation.totalServiceCost > 0 && (
          <Box width="100%">
            <Box sx={typoJustifyBetween}>
              <Typography variant="subtitle2">
                {quotation.serviceCostInterval === 1 ? 'Maandelijkse' : `Per ${quotation.serviceCostInterval} maanden`}{' '}
                service & support (SS) excl. BTW
              </Typography>
              <Typography variant="subtitle2">{currency(quotation.totalServiceCost)}</Typography>
            </Box>
          </Box>
        )}

        {quotation.totalSmartFee > 0 && (
          <Box width="100%">
            <Box sx={typoJustifyBetween}>
              <Typography variant="subtitle2">
                {quotation.smartFeeInterval === 1 ? 'Maandelijkse' : `Per ${quotation.smartFeeInterval} maanden`} smart
                connectivity service (SS+)
              </Typography>
              <Typography variant="subtitle2">{currency(quotation.totalSmartFee)}</Typography>
            </Box>
          </Box>
        )}
        {((quotation.totalServiceCost > 0 && quotation.serviceCostInterval > 1) ||
          (quotation.totalSmartFee > 0 && quotation.smartFeeInterval > 1)) && (
          <Box width="100%">
            <Box sx={typoJustifyBetween}>
              Let op, betalingsinterval(len) aanwezig met een waarde groter dan 1 (maand)
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QuotationSummary;
