/**
 * Filter predicate function to filter out `null` or `undefined` values with correct typing.
 */
export const notMaybe = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

/**
 * Create a type guard function to validate an object's type, optionally check if the required property has a specific value
 * will simply check if the property is not `undefined` if the value property is omitted.
 */
export const createTypeGuard =
  <ResultingType>(key: keyof ResultingType, value?: unknown) =>
  (arg: unknown | ResultingType): arg is ResultingType => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const property = (arg as any)[key];
    return property !== undefined && (value === undefined || value === property);
  };
