import { gql, useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import DaySlider from 'components/SavingsOverview/DaySlider/DaySlider';
import DaysOfWeekBar from 'components/SavingsOverview/DaysOfWeekBar/DaysOfWeekBar';
import { SuccessMessages } from 'src/constants/messages';
import {
  useCreateQuotationRoomMutation,
  useGetQuotationQuery,
  useUpdateQuotationRoomMutation,
} from 'src/graphql/generated/hooks';
import { QuotationRoomFieldsFragment } from 'src/graphql/generated/operations';
import { InputCreateQuotationRoom, InputUpdateQuotationRoom } from 'src/graphql/generated/schema';
import { useAuth } from 'src/hooks/useAuth.hook';
import useBreakpointDown from 'src/hooks/useBreakpointDown.hook';
import CardTitle from 'src/pages/BackOffice/components/CardTitle/CardTitle';
import DefaultButton from 'src/pages/BackOffice/components/DefaultButton/DefaultButton';
import GenerateInput from 'src/pages/BackOffice/components/GenerateInput/GenerateInput';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  quotationId: string;
  room?: QuotationRoomFieldsFragment;
}

interface FormInput {
  name: string;
  roomHeight: number | undefined;
  verticallyAccessible: boolean;
  image: FileList | string | null;
  powerUsageHours: number;
  powerUsageDays: boolean[];
  sensorOnTimePercentage: number;
}

const RoomDialog = ({ open, setOpen, quotationId, room }: Props): JSX.Element => {
  const mountedRef = useRef(false);

  const mediumScreen = useBreakpointDown();

  const [auth] = useAuth();
  const client = useApolloClient();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<FormInput>({ mode: 'onChange' });

  const sensorOnTimePercentage = room?.sensorOnTimePercentage ? Math.round(room.sensorOnTimePercentage * 100) : 100;

  const [sliderValue, setSliderValue] = useState<number>(sensorOnTimePercentage);

  const { data } = useGetQuotationQuery({
    variables: { input: { quotationId } },
    fetchPolicy: 'cache-and-network',
  });

  const formImage = watch('image');

  const [daysStateBoolean, setDaysStateBoolean] = useState<Array<boolean>>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [dayHours, setDayHours] = useState<number>(0);
  const [togglePowerUsage, setTogglePowerUsage] = useState<boolean>(false);

  const [createQuotationRoom, { loading }] = useCreateQuotationRoomMutation({
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: `Quotation:${data.createQuotationRoom.quotation.id}`,
        fields: {
          rooms(existingRoomRefs = []) {
            return [...existingRoomRefs, data.createQuotationRoom];
          },
        },
      });
    },
  });
  const [updateQuotationRoom, { loading: loadingUpdate }] = useUpdateQuotationRoomMutation();

  const [isUploading, setIsUploading] = useState(false);
  const disabled = loading || loadingUpdate || isUploading; // Explicitly not memoized

  const resetForm = useCallback(() => {
    reset({
      name: '',
      roomHeight: undefined,
      verticallyAccessible: false,
      image: null,
    });

    setTogglePowerUsage(false);

    room?.powerUsageDays
      ? setDaysStateBoolean(room?.powerUsageDays)
      : setDaysStateBoolean(data?.quotation.powerUsageDays as boolean[]);

    room?.powerUsageHours ? setDayHours(room?.powerUsageHours) : setDayHours(0);
  }, [data?.quotation.powerUsageDays, reset, room?.powerUsageDays, room?.powerUsageHours]);

  const updateCache = useCallback(
    (roomId: string, imageUrl: string | null) => {
      client.writeFragment({
        id: `QuotationRoom:${roomId}`,
        fragment: gql`
          fragment RoomImage on QuotationRoom {
            imageUrl
          }
        `,
        data: { imageUrl },
      });
    },
    [client],
  );

  const handleClose = useCallback(() => {
    resetForm();
    setOpen(false);
  }, [setOpen, resetForm]);

  const handleSave = useCallback(
    async ({ name, roomHeight, verticallyAccessible, image }: FormInput) => {
      let roomId = room?.id;
      if (!room?.id) {
        // Create a new quotationRoom
        const input: InputCreateQuotationRoom = {
          quotationId,
          name,
          roomHeight: roomHeight ? +roomHeight : 0,
          verticallyAccessible,
          sensorOnTimePercentage: sliderValue / 100,
          powerUsageDays: togglePowerUsage ? daysStateBoolean : null,
          powerUsageHours: togglePowerUsage ? dayHours : null,
        };
        const createdRoom = await createQuotationRoom({ variables: { input } });
        if (!mountedRef.current || !createdRoom.data) return;
        roomId = createdRoom.data.createQuotationRoom.id;
      } else {
        // Update an existing quotationRoom
        const input: InputUpdateQuotationRoom = {
          name,
          roomHeight: roomHeight ? +roomHeight : 0,
          quotationRoomId: room.id,
          verticallyAccessible,
          sensorOnTimePercentage: sliderValue / 100,
          // Explicitly clear (set to `null`) when toggled off
          powerUsageDays: togglePowerUsage ? daysStateBoolean : null,
          powerUsageHours: togglePowerUsage ? dayHours : null,
        };

        await updateQuotationRoom({ variables: { input } });
      }

      // Upload image
      if (!mountedRef.current || !auth?.headers || !roomId) return;
      let res: Response;
      const route = `/api/uploads/quotation-room/${roomId}/image`;
      if (image && image.length > 0) {
        setIsUploading(true);
        const body = new FormData();
        body.append('image', image[0]);
        res = await fetch(route, { method: 'POST', body, headers: auth.headers });
      } else {
        res = await fetch(route, { method: 'DELETE', headers: auth.headers });
      }
      if (!mountedRef.current) return;
      setIsUploading(false);

      // Update the local cache to match the new filename (note: does not trigger a re-render)
      if (!res.ok) return;
      const data = await res.json();
      if (!mountedRef.current) return;
      updateCache(roomId, data.imageUrl ?? null);

      toast.success(SuccessMessages.QuotationUpdated);
      handleClose();
    },
    [
      auth?.headers,
      createQuotationRoom,
      dayHours,
      daysStateBoolean,
      handleClose,
      quotationId,
      room?.id,
      sliderValue,
      togglePowerUsage,
      updateCache,
      updateQuotationRoom,
    ],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const imagePreviewUrl = useCallback(() => {
    if (!formImage) return;
    if (formImage.length > 1) {
      return formImage.toString(); // Existing room image
    } else if (formImage.length === 1) {
      if (typeof formImage[0] === 'string') {
        throw new Error('Selected file must be of type File, not string');
      }
      return URL.createObjectURL(formImage[0]); // Input file (Object)
    } else return;
  }, [formImage]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(Array.isArray(newValue) ? newValue[0] : newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(event.target.value));
  };

  useEffect(() => {
    if (!(room && open)) return;
    setIsUploading(false);
    setSliderValue(sensorOnTimePercentage);
    reset({
      name: room.name,
      roomHeight: room.roomHeight,
      verticallyAccessible: room.verticallyAccessible ?? false,
      image: room.imageUrl,
    });
    room?.powerUsageDays
      ? setDaysStateBoolean(room?.powerUsageDays)
      : setDaysStateBoolean(data?.quotation.powerUsageDays as boolean[]);

    room?.powerUsageHours ? setDayHours(room?.powerUsageHours) : setDayHours(data?.quotation.powerUsageHours as number);
  }, [room, reset, open, data?.quotation.powerUsageDays, data?.quotation.powerUsageHours, sensorOnTimePercentage]);

  // effect just for tracking mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (room?.powerUsageHours) {
      setDayHours(room?.powerUsageHours);
      setTogglePowerUsage(true);
    }
  }, [room?.powerUsageHours]);

  useEffect(() => {
    if (room?.powerUsageDays) {
      setDaysStateBoolean(room?.powerUsageDays);
      setTogglePowerUsage(true);
    }
  }, [room?.powerUsageDays]);

  return (
    <Dialog fullScreen={mediumScreen} fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <form style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} onSubmit={handleSubmit(handleSave)}>
        <DialogTitle>
          <CardTitle color="primary" title="Basisgegevens van de ruimte" />
        </DialogTitle>
        <DialogContent>
          <Box>
            <GenerateInput
              control={control}
              defaultValue={room?.name ?? ''}
              disabled={disabled}
              errorField={errors.name}
              fieldName="name"
              helperText="Vul een naam voor de ruimte in"
              label="Naam"
              variant="outlined"
            />
            <GenerateInput
              InputProps={{ endAdornment: <InputAdornment position="end">meter</InputAdornment> }}
              control={control}
              defaultValue={room?.roomHeight ?? 0}
              disabled={disabled}
              errorField={errors.roomHeight}
              fieldName="roomHeight"
              helperText="Vul de hoogte van de ruimte (in meters) in"
              label="Hoogte"
              type="number"
              variant="outlined"
            />

            <Box alignItems="flex-start" display="flex" flexDirection="column" gap={3} justifyContent="center" mt={2}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    defaultValue={room?.verticallyAccessible ?? false}
                    name="verticallyAccessible"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        color="primary"
                        disabled={disabled}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label="Verticaal bereikbaar"
              />
            </Box>

            <Box mt={2}>
              <Typography>Verwachte aan-tijd</Typography>
              <Box alignItems="center" display="flex" gap={4}>
                <Slider sx={{ flex: 5 }} value={sliderValue} onChange={handleSliderChange} />
                <GenerateInput
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: { min: 0, max: 100 },
                  }}
                  control={control}
                  defaultValue={0}
                  disabled={disabled}
                  errorField={errors.sensorOnTimePercentage}
                  fieldName="sensorOnTimePercentage"
                  helperText="Vul de Verwachte aan-tijd in"
                  label=""
                  sx={{
                    flex: 2,
                    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      { display: 'none' },
                  }}
                  type="number"
                  value={sliderValue}
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Box>
            </Box>
            <FormControlLabel
              control={<Switch checked={togglePowerUsage} />}
              label="Energieverbruik aanpassen voor deze ruimte"
              labelPlacement="end"
              sx={{ mt: 2 }}
              onChange={() => setTogglePowerUsage(!togglePowerUsage)}
            />
            <Collapse in={togglePowerUsage}>
              <Box mt={3}>
                <DaySlider dayHours={dayHours} setDayHours={setDayHours} title="Gemiddeld aantal branduren per dag" />
                <DaysOfWeekBar
                  daysStateBoolean={daysStateBoolean}
                  setDaysStateBoolean={setDaysStateBoolean}
                  title="Dagen in de week"
                />
              </Box>
            </Collapse>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {formImage && formImage.length >= 1 && (
              <Box sx={{ position: 'relative', mr: 2 }}>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  disabled={disabled}
                  size="large"
                  sx={{ position: 'absolute', top: -24, right: -24, zIndex: 1 }}
                  onClick={() => setValue('image', null)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
                <Avatar
                  alt="Afbeelding van de ruimte"
                  src={imagePreviewUrl()}
                  sx={{ width: '145px', height: '91px', position: 'relative' }}
                  variant="rounded"
                />
              </Box>
            )}
            <Button component="label" disabled={disabled} sx={{ width: '100%', height: '91px', bgcolor: '#f4f4f4' }}>
              <Box sx={{ display: 'none' }}>
                <input {...register('image')} accept="image/*" disabled={disabled} type="file" />
              </Box>
              <CardTitle
                color="text.primary"
                icon={<ImageIcon sx={{ color: 'text.primary' }} />}
                title="Selecteer een afbeelding"
              />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <DefaultButton disabled={disabled} id="cancel" sx={{ flexGrow: 1 }} onClick={handleClose}>
            Annuleren
          </DefaultButton>
          <LoadingButton
            color="primary"
            loading={loading || loadingUpdate || isUploading}
            sx={{ flexGrow: 1 }}
            type="submit"
            variant="contained"
          >
            Opslaan
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RoomDialog;
