import { TextField, TextFieldProps } from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  FieldPathValue,
  FieldValues,
  Merge,
  Path,
  UnpackNestedValue,
} from 'react-hook-form';

interface Props<TName extends Path<T>, T extends FieldValues> {
  control: Control<T>;
  fieldName: TName;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorField: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  defaultValue: UnpackNestedValue<FieldPathValue<T, TName>>; //UnpackNestedValue<FieldPathValue<TFieldValues, TName>>
  helperText: string;
  disabled: boolean;
  type?: string;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
}

export default function GenerateInput<TName extends Path<T>, T extends FieldValues = FieldValues>({
  control,
  fieldName,
  label,
  errorField,
  defaultValue,
  helperText,
  disabled,
  variant,
  type = 'text',
  ...props
}: Props<TName, T> & TextFieldProps): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={fieldName}
      render={({ field }) => (
        <TextField
          disabled={disabled}
          error={errorField !== undefined}
          fullWidth
          helperText={errorField && helperText}
          label={label}
          type={type}
          variant={variant}
          {...field}
          sx={{ mt: 3 }}
          {...props}
        />
      )}
      rules={{ required: true }}
    />
  );
}
