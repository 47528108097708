import { Box, Button, Divider, Paper, PaperProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface CustomPaperProps {
  props?: PaperProps;
  onClick: () => unknown;
  text: string;
  startIcon: React.ReactNode;
}

// Customized options field on Autocomplete with Create New Contact button
const CustomPaper: FC<PropsWithChildren<CustomPaperProps>> = ({ children, props, onClick, text, startIcon }) => {
  return (
    <Paper {...props}>
      {children}
      <Divider variant="middle" />
      <Button
        startIcon={startIcon}
        sx={{ width: '100%' }}
        onClick={onClick}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        <Box sx={{ padding: '1rem', fontWeight: '800', fontSize: '15px' }}>{text}</Box>
      </Button>
    </Paper>
  );
};

export default CustomPaper;
