interface Props {
  /** Should the children be wrapped */
  condition: boolean;
  /** Component to wrap children in */
  wrapper: (children: JSX.Element) => JSX.Element;
  /** Children component(s) that should conditionally be wrapped */
  children: JSX.Element;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: Props): JSX.Element =>
  condition ? wrapper(children) : children;
