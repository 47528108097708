import { Grid, Link, Typography } from '@mui/material';

import { CONSENT_URL } from 'src/constants/settings';

import FinalAgreementBtn from '../StatusChange/FinalAgreementBtn';

interface Props {
  quotationId: string;
  isSigning: boolean;
}

const QuotationConfirmation = ({ isSigning }: Props): JSX.Element => {
  return (
    <Grid container spacing={0}>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={1} />
      <Grid item xs={10}>
        <Typography sx={{ mb: 3 }} variant="body2">
          Door hier uw handtekening te plaatsen en de offerte te accorderen gaat u akkoord met deze offerte en de{' '}
          <Link href={CONSENT_URL} rel="noopener" target="_blank">
            algemene voorwaarden
          </Link>
        </Typography>

        <FinalAgreementBtn isSigning={isSigning} variant="contained" />
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={1} />
    </Grid>
  );
};

export default QuotationConfirmation;
