import { Box, Button, Paper, Radio, styled, SxProps, Theme, Typography } from '@mui/material';
import { useMemo } from 'react';

import { FilterOption } from 'src/hooks/useFilterProgress';

import EiaCheck from '../EiaCheck/EiaCheck';

interface Props {
  option: FilterOption;
  isHydrating: boolean;
  isStepExpanded?: boolean;
  isSelected?: boolean;
}

export const FilterOptionThumbnail = styled('img')({
  width: '4rem',
  height: '4rem',
  aspectRatio: '1',
  display: 'block',
});

export default function ProductFilterOption({
  option: {
    title,
    onSelect,
    thumbnailUrl,
    value,
    component: Component,
    remainingProductsAfterSelection,
    showThumbnail = true,
    thumbnailComponent,
    isEligibleForEia,
  },
  isStepExpanded = false,
  isSelected = false,
  isHydrating,
}: Props): JSX.Element {
  const ButtonStyles: SxProps<Theme> = useMemo(
    () => ({
      width: '100%',
      my: !isStepExpanded && !isSelected ? 0 : 2,
      p: 2,
      py: !isStepExpanded && !isSelected ? 0 : 1,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      opacity: !isStepExpanded && !isSelected ? 0 : 1,
      maxHeight: !isStepExpanded && !isSelected ? '0px' : '10rem',
      transition: isHydrating ? 'none' : 'all 200ms', // No animations/ transitions when hydrating
      overflow: 'hidden',
    }),
    [isSelected, isStepExpanded, isHydrating],
  );

  const ThumbnailComponent = useMemo(() => {
    if (!showThumbnail) return;
    if (thumbnailComponent) return thumbnailComponent;
    if (!title) return;
    if (thumbnailUrl) return <FilterOptionThumbnail alt={title} src={thumbnailUrl} />;
    if (remainingProductsAfterSelection && remainingProductsAfterSelection[0]?.thumbnail?.urlThumbnail)
      return <FilterOptionThumbnail alt={title} src={remainingProductsAfterSelection[0].thumbnail?.urlThumbnail} />;
  }, [remainingProductsAfterSelection, showThumbnail, thumbnailComponent, thumbnailUrl, title]);

  return (
    <Paper elevation={2}>
      <Button color="inherit" disabled={isHydrating} sx={ButtonStyles} onClick={() => onSelect?.(value)}>
        <Box sx={{ width: '4rem', height: '4rem' }}>{ThumbnailComponent && ThumbnailComponent}</Box>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          {Component ? (
            <Component remainingProductsAfterSelection={remainingProductsAfterSelection} title={title} />
          ) : (
            <Typography variant="h4">{title}</Typography>
          )}
        </Box>
        <Radio checked={isSelected} inputProps={{ 'aria-label': title }} value={title} />
        {isEligibleForEia && <EiaCheck isEligibleForEia={isEligibleForEia} />}
      </Button>
    </Paper>
  );
}
