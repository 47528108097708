export enum Routes {
  Home = '/',
  BackOffice = '/back-office',
  BackOfficeAdminSettings = '/back-office/admin-settings',
  BackOfficeContactList = '/back-office/contacts',
  BackOfficeContactShow = '/back-office/contacts/:contactId',
  BackOfficeContactEdit = '/back-office/contacts/:contactId/edit',
  BackOfficeContactCreate = '/back-office/contacts/create',
  BackOfficeDashBoard = '/back-office/dashboard',
  BackOfficeDashBoardAdmin = '/back-office/dashboard/:userId',
  BackOfficeProfile = '/back-office/profile',
  BackOfficeUserList = '/back-office/users',
  BackOfficeUserShow = '/back-office/users/:userId',
  BackOfficeUserEdit = '/back-office/users/:userId/edit',
  BackOfficeUserCreate = '/back-office/users/create',
  BackOfficeQuotationList = '/back-office/quotations',
  BackOfficeQuotationShow = '/back-office/quotations/:quotationId',
  BackOfficeProductList = '/back-office/products',
  BackOfficeProductShow = '/back-office/products/:productSleutel',
  BackOfficeProductEdit = '/back-office/products/:productSleutel/edit',
  BackOfficeProductCreate = '/back-office/products/create',
  CheckOut = '/check-out',
  CheckIn = '/check-in',
  CheckInQuotation = '/check-in/:quotationId',
  CheckInQuotationShow = '/check-in/:quotationId/confirm',
  Register = '/register/:inviteToken',
  SignIn = '/sign-in',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password/:resetPasswordToken',
  Error = '/error',
  NotFound = '*',
}
